import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuIcon from '@material-ui/icons/Menu';
//IMG
import logo from '../../../assets/img/logoColor.svg';
import {Badge, Hidden, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
//Actions
import {logout} from "../../../actions/AuthActions";
import {useHistory} from "react-router";
import c from "../../../constants/Constants";
import Echo from "laravel-echo";
import NotificationsIcon from '@material-ui/icons/Notifications';
import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {
    fetchNotifications,
    receiveNotification,
    saveUpdateNotification,
    updateNotification
} from "../../../actions/NotificationActions";
import moment from "moment";
import clone from "lodash/clone";
import Status from "../../Device/Status";
import {receiveDevice} from "../../../actions/DeviceActions";
import {receiveInvitation} from "../../../actions/InvitationActions";

window.Pusher = require('pusher-js');

const useStyles = makeStyles(theme => ({
    grow: {
        position: 'fixed',
        width: '100%',
        flexGrow: 1,
        zIndex: theme.zIndex.drawer + 1,
        [theme.breakpoints.down('sm')]: {
            position: "relative"
        },
    },
    menuButton: {
        display: "flex",
        alignContent: "center",
        alignItems: "center",
    },
    sectionDesktop: {
        marginLeft: 'auto',
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            gap: "8px",
        },
    },
    sectionMobile: {
        display: 'flex',
        marginLeft: 'auto',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    hidden: {
        display: 'none'
    },
    appBar: {
        backgroundColor: theme.palette.type === "dark" ? "rgba(0,0,0,0.74)" : "rgba(255,255,255,0.58)",
        backdropFilter: "blur(6px)",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    // appBarShift: {
    //     marginLeft: drawerWidth,
    //     width: `calc(100% - ${drawerWidth}px)`,
    //     transition: theme.transitions.create(['width', 'margin'], {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.enteringScreen,
    //     }),
    // },
    // title: {
    //     flexGrow: 1,
    // },
}));

export default function NavSup(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [idMenu, setIdMenu] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const auth = useSelector(state => state.auth);
    const notifications = useSelector(state => state.notifications);
    const userLoq = auth.usuarioLogueado;


    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    useEffect(() => {
        dispatch(fetchNotifications({
            idUser: 1,
            order: "created_at",
            direction: "desc",
            complete_at: null,
            types: ["App\\Notifications\\InvitationRequiresConfirmationNotification", "App\\Notifications\\GeneralNotification"]
        }));
        //SOCKET
        //Conexion al socket
        window.Echo = new Echo({
                broadcaster: 'pusher',
                key: 'spraytec',
                wsHost: c.SOCKET_URL,
                wsPort: 6001,
                // wssPort: 6001,
                forceTLS: false,
                disableStats: true,
                auth: {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                },
                authEndpoint: c.BASE_URL + "/broadcasting/auth",
                // authorizer: (channel, options) => {
                //     return {
                //         authorize: (socketId, callback) => {
                //             fetch('http://localhost:8010/api/broadcasting/auth', {
                //                 method: "POST",
                //                 headers: {
                //                     'Access-Control-Allow-Origin': '*',
                //                     "Content-Type": "application/json;charset=UTF-8",
                //                     "Authorization": "Bearer " + localStorage.token,
                //                 },
                //                 mode: 'no-cors',
                //             })
                //                 .then(response => {
                //                     callback(false, response);
                //
                //                 })
                //                 .catch(error => {
                //                     callback(true, error);
                //                 });
                //         }
                //     };
                // }

            }
        );

        // //Conectarse al canal
        window.Echo.private('App.Models.User.' + userLoq.idUser).listenToAll((event, notification) => {
            console.log(notification, notification.notification);
            console.log("LLEGO socket");
            dispatch(receiveNotification(notification.notification));
            if (notification && notification.invitation)
                dispatch(receiveInvitation(notification.invitation));
        });
        window.Echo.channel('heartbeat').listenToAll((event, notification) => {
            console.log(notification, notification.device);
            console.log("LLEGO hearbeat");
            dispatch(receiveDevice(notification.device));
        });
    }, [typeof io]);

    function handleProfileMenuOpen(event) {
        setAnchorEl(event.currentTarget);
        setIdMenu(event.currentTarget.id);
    }

    function handleMobileMenuClose() {
        setMobileMoreAnchorEl(null);
    }

    function handleMenuClose() {
        setAnchorEl(null);
        handleMobileMenuClose();
        setIdMenu(null);
    }

    function handleMobileMenuOpen(event) {
        setMobileMoreAnchorEl(event.currentTarget);
    }

    function onClickNotification(notification) {
        let data = notification.data;
        history.push(data.relativeUrl);
        let cambio = clone(notification);
        cambio.read_at = moment().format("YYYY-MM-DD HH:mm");
        dispatch(updateNotification(cambio));
        dispatch(saveUpdateNotification(cambio));
        handleMenuClose();
    }

    const menuId = 'account';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId + "menu"}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={idMenu === menuId}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => {
                history.push("/users/" + auth.usuarioLogueado.idUser);
                handleMenuClose()
            }}>Mi cuenta</MenuItem>
            <MenuItem onClick={() => dispatch(logout())}>Cerrar sesión</MenuItem>
        </Menu>
    );

    const menuIdNotifications = 'notifications';
    let qNotif = 0;
    let Notifications = [];
    notifications.allIds.map((idNotification) => {
        let notification = notifications.byId.notifications[idNotification];
        let data = notification.data;
        let invitation = data.invitation;
        let user = invitation.user;
        let dateFrom = moment(invitation.dateFrom);
        let timeFrom = moment(invitation.timeFrom, "HH:mm:ss");
        let timeTo = moment(invitation.timeTo, "HH:mm:ss");
        if (!notification.complete_at && !notification.read_at)
            qNotif++;
        if (!notification.complete_at)
            Notifications.push(<ListItem key={"not" + notification.id} alignItems="flex-start" button
                                         onClick={() => onClickNotification(notification)}
                                         selected={!notification.read_at}>
                <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg"/>
                </ListItemAvatar>
                <ListItemText
                    primary={data.message}
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                                {user ? user.name + " " + user.lastname : ""}
                            </Typography>
                            {" — " + dateFrom.format("DD-MM-YYYY") + " " + timeFrom.format("HH:mm") + "-" + timeTo.format("HH:mm")}
                        </React.Fragment>
                    }
                />
            </ListItem>);
    })
    const renderMenuNotifications = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuIdNotifications + "menu"}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={idMenu === menuIdNotifications}
            onClose={handleMenuClose}
        >
            <List className={classes.root}>
                {
                    Notifications
                }
                {
                    !Notifications || Notifications.length === 0 &&
                    <ListItem key={"not" + 0} alignItems="flex-start">
                        <ListItemText
                            primary={"No hay notificaciones pendientes"}
                        />
                    </ListItem>
                }
                <Divider variant="inset" component="li"/>
            </List>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={() => {
                history.push("/users/" + auth.usuarioLogueado.idUser);
                handleMenuClose()
            }}>Mi cuenta</MenuItem>
            <MenuItem onClick={() => dispatch(logout())}>Cerrar sesión</MenuItem>
        </Menu>
    );

    return (
        <div className={classes.grow}>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: props.open,
                })}
            >
                <Toolbar>
                    <Hidden smUp>
                        <Link to="/" className={classes.menuButton} onClick={() => props.handleDrawerOpen()}>
                            <IconButton
                                edge="start"
                                className={classes.menuButton}
                                color="primary"
                                aria-label="open drawer"
                            >
                                <MenuIcon/>
                            </IconButton>
                        </Link>
                    </Hidden>
                    <Link to="/" className={classes.menuButton}>
                        <img style={{width: "120px", marginRight: '10px'}} src={logo} alt=""/>
                    </Link>
                    <div className={classes.sectionDesktop}>
                        <Status/>
                        <IconButton
                            id={menuIdNotifications}
                            edge="end"
                            aria-label="notifications of current user"
                            aria-controls={menuIdNotifications}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="primary"
                        >
                            <Badge color="secondary" badgeContent={qNotif}>
                                <NotificationsIcon/>
                            </Badge>
                        </IconButton>
                        <IconButton
                            id={menuId}
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="primary"
                        >
                            <AccountCircle/>
                        </IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="primary"
                        >
                            <MoreIcon/>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            {renderMenuNotifications}
        </div>
    );
}
