import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
//Components
import {Box, Button, CircularProgress, Typography, useTheme,} from "@material-ui/core";
import Navigation from "../../library/Navigation";
import style from "../../assets/css/Forms.module.css";
import selfieStyle from "../../assets/css/Selfie.module.css";
import clsx from "clsx";
import {ReactComponent as SelfieIcon} from "../../assets/img/selfiIcon.svg";
import PhotoManager from "./PhotoManager";
import clone from "lodash/clone";
import {createFacePhoto, updateFacePhoto} from "../../actions/FacePhotoActions";
import c from "../../constants/Constants";
import FormHelperText from "@material-ui/core/FormHelperText";

export default function Selfie(props) {
    let {goToStep, prevStep, files, setFiles} = props;
    const [filename, setFileName] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [openPhotoManager, setOpenPhotoManager] = React.useState(false);
    const theme = useTheme();
    let img = React.useRef();
    let msj = React.useRef();

    const dispatch = useDispatch();
    let {idInvitation} = useParams();

    //Store
    const invitations = useSelector(state => state.invitations);
    const users = useSelector(state => state.users);
    const facePhotos = useSelector(state => state.facePhotos);
    const invitation = idInvitation ? invitations.update.activo : invitations.create.nuevo;
    let user = invitation && invitation.idUser && users.byId.users[invitation.idUser] ? users.byId.users[invitation.idUser] : {};
    if (users.update.activos.indexOf(invitation.idUser) !== -1) {
        user = users.update.activo[invitation.idUser];
    }
    if (users.create.nuevos.indexOf(invitation.idUser) !== -1) {
        user = users.create.nuevo[invitation.idUser];
    }
    let facePhoto = user && user.face_photo && user.face_photo.length > 0 ? facePhotos.byId.facePhotos[user.face_photo[0]] : null;

    //Effects
    useEffect(() => {
        msj.current.style.display = "none";
        //Cargar foto
        for (let i = 0; i < files.length; i++) {
            let fileSubido = files[i];
            let filename = "facephoto";
            if (fileSubido.name)
                filename = fileSubido.name.replace(/[^a-zA-Z0-9]/g, '_');
            let reader = new FileReader();
            reader.onloadend = (e) => {
                setLoading(false);
                console.log(img)
                img.current.src = reader.result;
            };

            if (fileSubido) {
                reader.readAsDataURL(fileSubido);
            }

            let id = facePhoto ? facePhoto.id : Date.now() + Math.random() + "-";
            let cambio = facePhoto ? clone(facePhoto) : {};
            cambio.filename = filename;
            changeFacePhoto(cambio, id);
        }
        return function cleanup() {
        }

    }, [files]);

    const changeFacePhoto = (cambio, idFacePhoto) => {
        //Verificar si ya esta siendo actualizado
        let facePhoto = idFacePhoto && facePhotos.update.activos.indexOf(idFacePhoto) === -1 && facePhotos.create.nuevos.indexOf(idFacePhoto) === -1 ? facePhotos.byId.facePhotos[idFacePhoto] : null;
        if (facePhoto) {
            let actualizar = facePhoto ? clone(facePhoto) : null;
            if (actualizar) {
                let cambioAssetLanguage = {};
                cambioAssetLanguage[actualizar.id] = actualizar;
                if (idFacePhoto.toString().indexOf("-") === -1)
                    dispatch(updateFacePhoto(cambioAssetLanguage));
                else
                    dispatch(createFacePhoto(cambioAssetLanguage));
            }
        }
        cambio.id = idFacePhoto;
        cambio.idUser = user.id;
        let cambioAssetLanguage = {};
        cambioAssetLanguage[idFacePhoto] = cambio;
        if (idFacePhoto.toString().indexOf("-") === -1)
            dispatch(updateFacePhoto(cambioAssetLanguage));
        else
            dispatch(createFacePhoto(cambioAssetLanguage));
    };

    const onSubmit = (e) => {
        if (e)
            e.preventDefault()
        if (user && ((user.face_photo && user.face_photo.length === 0) || !user.face_photo)) {
            msj.current.style.display = "inline";
            msj.current.scrollIntoView({
                behavior: "smooth", block: "center"
            });
        } else
            goToStep("confirmacion");
    }

    return (
        <form onSubmit={onSubmit} className={style.containerForm}>
            <Typography variant={"h1"} component={"h1"} color={"primary"}>
                Carga tu foto de tu rostro
            </Typography>
            <Typography variant={"subtitle2"} component={"p"} className={style.msg}>
                Carga tu foto de tu rostro para asegurar el ingreso a nuestras
                oficinas a través de tus datos biométricos
            </Typography>
            <FormHelperText ref={msj} style={{display: "none", color: "red"}}>
                Debe cargar una foto u omitir el paso.
            </FormHelperText>
            <Box component={"fieldset"} className={selfieStyle.containerFileInput}
                 onClick={() => setOpenPhotoManager((prev) => !prev) //inputfile.current.click()
                 }>
                <Box component={"div"} className={clsx([selfieStyle.fileInput, selfieStyle.file])}>
                    <Button className={selfieStyle.buttonUpload}
                            style={{
                                borderColor: theme.palette.textField ? theme.palette.textField["color"] : theme.palette.primary["main"],
                                backgroundColor: theme.palette.textField ? theme.palette.textField["background"] : theme.palette.primary["main"]
                            }}
                    >
                        <label htmlFor={"file"}
                               className={selfieStyle.labelButton}
                        >
                            <Box component={"div"} position={"relative"} display={"flex"}
                                 alignItems={"center"}
                                 justifyContent={"center"} margin={"0 6px"}>
                                {
                                    !!loading &&
                                    <CircularProgress className={selfieStyle.progress} color={"secondary"}/>
                                }
                                <Box component={"div"}>
                                    <SelfieIcon style={{fill: theme.palette.primary["main"]}}/>
                                    <Typography variant={"h6"} component={"h6"} color={"primary"}>
                                        TOMAR FOTO
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography variant={"caption"} color={"primary"}>
                                {filename}
                            </Typography>
                            <img src={facePhoto ? c.API_HOST + facePhoto.path : ""} ref={img} alt=""/>
                        </label>
                    </Button>

                </Box>
                <legend>
                    <Typography variant={"caption"}>
                        <i>.pdf</i>
                    </Typography>
                </legend>
            </Box>

            <Navigation next={"confirmacion"} goToStep={goToStep} prevStep={prevStep} skip={true}/>
            <PhotoManager setOpen={setOpenPhotoManager} open={openPhotoManager} setLoading={setLoading}
                          changeFacePhoto={changeFacePhoto} setFiles={setFiles} files={files}/>
        </form>
    );
}

