import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
//Components
import style from "../../assets/css/Forms.module.css";
import {Typography, useTheme} from "@material-ui/core";
import Navigation from "../../library/Navigation";
import {ReactComponent as HappyPeople} from "../../assets/img/happyPeople.svg";

export default function Message() {
    const dispatch = useDispatch();
    const theme = useTheme();

    let {idInvitation} = useParams();

    const invitations = useSelector(state => state.invitations);
    const invitation = idInvitation ? invitations.update.activo : invitations.create.nuevo;
    const users = useSelector(state => state.users);
    let user = invitation && invitation.idUser && users.byId.users[invitation.idUser] ? users.byId.users[invitation.idUser] : {};
    if (users.update.activos.indexOf(invitation.idUser) !== -1) {
        user = users.update.activo[invitation.idUser];
    }
    if (users.create.nuevos.indexOf(invitation.idUser) !== -1) {
        user = users.create.nuevo[invitation.idUser];
    }

    //Effects
    useEffect(() => {
        return function cleanup() {
        }

    }, []);


    return (
        <div className={style.containerForm}>
            <Typography variant={"h1"} component={"h1"} color={"primary"} align={"center"}>
                ¡Registro exitoso!
            </Typography>
            <HappyPeople className={style.svg}
                         style={{
                             fill: theme.palette.secondary["main"],
                             maxWidth: "160px",
                             margin: "30px auto",
                             stroke: theme.palette.primary["main"]
                         }}
            />
            <Typography variant={"subtitle2"} component={"p"} className={style.msg}>
                Te enviamos al email <b>{user ? user.email : ""}</b> las instrucciones para ingresar a nuestras oficinas
            </Typography>
        </div>
    );
}

