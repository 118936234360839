//api
import cardsApi from "../api/cardsApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeCards";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//CARDS
export const REQUEST_CARDS = 'REQUEST_CARDS';
export const RECEIVE_CARDS = 'RECEIVE_CARDS';
export const INVALIDATE_CARDS = 'INVALIDATE_CARDS';
export const ERROR_CARDS = "ERROR_CARDS";
export const RESET_CARDS = "RESET_CARDS";


export function invalidateCards() {
    return {
        type: INVALIDATE_CARDS
    }
}

function requestCards() {
    return {
        type: REQUEST_CARDS,
    }
}

function receiveCards(json) {
    return {
        type: RECEIVE_CARDS,
    cards: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorCards(error) {
    return {
        type: ERROR_CARDS,
        error: error,
    }
}

export function resetCards() {
    return {
        type: RESET_CARDS
    }
}

export function fetchCards(filtros) {
    return dispatch => {
        dispatch(requestCards());
        return cardsApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveCards(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorCards(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorCards(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchCards(state) {
    const cards = state.cards.byId;
    if (!cards) {
        return true
    } else if (cards.isFetching) {
        return false
    } else {
        return cards.didInvalidate;
    }
}

export function fetchCardsIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchCards(getState())) {
            return dispatch(fetchCards(filtros))
        }
    }
}


//MODEL
export const REQUEST_CARD = 'REQUEST_CARD';
export const RECEIVE_CARD = 'RECEIVE_CARD';
export const INVALIDATE_CARD = 'INVALIDATE_CARD';
export const ERROR_CARD = "ERROR_CARD";
export const RESET_CARD = "RESET_CARD";


export function invalidateCard() {
    return {
        type: INVALIDATE_CARD
    }
}

function requestCard() {
    return {
        type: REQUEST_CARD,
    }
}

export function receiveCard(json) {
    return {
        type: RECEIVE_CARD,
    card: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorCard(error) {
    return {
        type: ERROR_CARD,
        error: error,
    }
}

export function fetchCard(idCard) {
    return dispatch => {
        dispatch(requestCard());
        return cardsApi.getOne(idCard)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveCard(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorCard(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorCard(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_CARD = 'RECEIVE_FILE_CARD';

function receiveFileCard(file) {
    return {
        type: RECEIVE_FILE_CARD,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileCard(idCard, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return cardsApi.getFile(idCard, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileCard(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorCard(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorCard(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_CARD = 'UPDATE_CARD';
export const REQUEST_UPDATE_CARD = "REQUEST_UPDATE_CARD";
export const SUCCESS_UPDATE_CARD = "SUCCESS_UPDATE_CARD";
export const ERROR_UPDATE_CARD = "ERROR_UPDATE_CARD";
export const RESET_UPDATE_CARD = "RESET_UPDATE_CARD";
export const DELETE_UPDATE_CARD = "DELETE_UPDATE_CARD";

function requestUpdateCard() {
    return {
        type: REQUEST_UPDATE_CARD,
    }
}

function receiveUpdateCard(card) {
    return {
        type: SUCCESS_UPDATE_CARD,
        receivedAt: Date.now(),
        card: normalizeDato(card)
    }
}

function errorUpdateCard(error) {
    return {
        type: ERROR_UPDATE_CARD,
        error: error,
    }
}

export function resetUpdateCard() {
    return {
        type: RESET_UPDATE_CARD,
    }
}

export function updateCard(card) {
    return {
        type: UPDATE_CARD,
        card
    }
}

export function saveUpdateCard() {
    return (dispatch, getState) => {
        dispatch(requestUpdateCard());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let card = denormalizeDato(getState().cards.update.activo, store);

        return cardsApi.saveUpdate(card)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateCard(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateCard(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                try{
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateCards(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateCards(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateCard(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateCard(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateCard(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                                    } catch{
                                            dispatch(errorUpdateCard(errorMessages.GENERAL_ERROR));
                                        };
                            }
                        });
    }
}

export function deleteUpdateCard(card) {
    return {
        type: DELETE_UPDATE_CARD,
        card
    }
}

//UPDATE CARDS
export const REQUEST_UPDATE_CARDS = "REQUEST_UPDATE_CARDS";
export const SUCCESS_UPDATE_CARDS = "SUCCESS_UPDATE_CARDS";
export const ERROR_UPDATE_CARDS = "ERROR_UPDATE_CARDS";
export const RESET_UPDATE_CARDS = "RESET_UPDATE_CARDS";

function requestUpdateCards() {
    return {
        type: REQUEST_UPDATE_CARDS,
}
}

function receiveUpdateCards(cards) {
    return {
        type: SUCCESS_UPDATE_CARDS,
    receivedAt: Date.now(),
        cards: normalizeDatos(cards)
}
}

function errorUpdateCards(error) {
    return {
        type: ERROR_UPDATE_CARDS,
    error: error,
}
}

export function resetUpdateCards() {
    return {
        type: RESET_UPDATE_CARDS,
}
}

export function saveUpdateCards() {
    return (dispatch, getState) => {
        dispatch(requestUpdateCards());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let cards = getState().cards.update.activos.map((idCard) => {
            return denormalizeDato(getState().cards.update.activo[idCard], store);
        });

        return cardsApi.saveUpdateCards(cards)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateCards(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateCards(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateCards(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateCards(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateCards(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateCards(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA CARD
export const CREATE_CARD = 'CREATE_CARD';
export const REQUEST_CREATE_CARD = "REQUEST_CREATE_CARD";
export const SUCCESS_CREATE_CARD = "SUCCESS_CREATE_CARD";
export const ERROR_CREATE_CARD = "ERROR_CREATE_CARD";
export const RESET_CREATE_CARD = "RESET_CREATE_CARD";
export const DELETE_CREATE_CARD = "DELETE_CREATE_CARD";


//ALTA CARD
function requestCreateCard() {
    return {
        type: REQUEST_CREATE_CARD,
    }
}

function receiveCreateCard(card) {
    return {
        type: SUCCESS_CREATE_CARD,
        receivedAt: Date.now(),
        card: normalizeDato(card)
    }
}

export function errorCreateCard(error) {
    return {
        type: ERROR_CREATE_CARD,
        error: error,
    }
}

export function resetCreateCard() {
    return {
        type: RESET_CREATE_CARD
    }
}

export function createCard(card) {
    return {
        type: CREATE_CARD,
        card
    }
}

export function deleteCreateCard(card) {
    return {
        type: DELETE_CREATE_CARD,
        card
    }
}

export function saveCreateCard() {
    return (dispatch, getState) => {
        dispatch(requestCreateCard());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let card = denormalizeDato(getState().cards.create.nuevo, store);

        return cardsApi.saveCreate(card)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateCard(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateCard(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                try{
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateCards(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateCards(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateCard(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateCard(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateCard(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                                    }catch {
                                        dispatch(errorCreateCard(errorMessages.GENERAL_ERROR));
                                    };
                            }
                        });
    }
}

//CREATE CARDS
export const REQUEST_CREATE_CARDS = "REQUEST_CREATE_CARDS";
export const SUCCESS_CREATE_CARDS = "SUCCESS_CREATE_CARDS";
export const ERROR_CREATE_CARDS = "ERROR_CREATE_CARDS";
export const RESET_CREATE_CARDS = "RESET_CREATE_CARDS";

function requestCreateCards() {
    return {
        type: REQUEST_CREATE_CARDS,
}
}

function receiveCreateCards(cards) {
    return {
        type: SUCCESS_CREATE_CARDS,
    receivedAt: Date.now(),
        cards: normalizeDatos(cards)
}
}

function errorCreateCards(error) {
    return {
        type: ERROR_CREATE_CARDS,
    error: error,
}
}

export function resetCreateCards() {
    return {
        type: RESET_CREATE_CARDS,
}
}

export function saveCreateCards() {
    return (dispatch, getState) => {
        dispatch(requestCreateCards());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let cards = getState().cards.create.nuevos.map((idCard) => {
            return denormalizeDato(getState().cards.create.nuevo[idCard], store);
        });

        return cardsApi.saveCreateCards(cards)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateCards(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateCards(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateCards(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateCards(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateCards(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateCards(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE CARD
export const DELETE_CARD = 'DELETE_CARD';
export const REQUEST_DELETE_CARD = "REQUEST_DELETE_CARD";
export const SUCCESS_DELETE_CARD = "SUCCESS_DELETE_CARD";
export const ERROR_DELETE_CARD = "ERROR_DELETE_CARD";
export const RESET_DELETE_CARD = "RESET_DELETE_CARD";

function requestDeleteCard() {
    return {
        type: REQUEST_DELETE_CARD,
    }
}

function receiveDeleteCard(card) {
    return {
        type: SUCCESS_DELETE_CARD,
        receivedAt: Date.now(),
        card: normalizeDato(card)
    }
}

function errorDeleteCard(error) {
    return {
        type: ERROR_DELETE_CARD,
        error: error,
    }
}

export function resetDeleteCard(error) {
    return {
        type: RESET_DELETE_CARD,
        error: error,
    }
}

export function deleteCard(card) {
    return {
        type: DELETE_CARD,
        card
    }
}

export function saveDeleteCard(card) {
    return dispatch => {
        dispatch(requestDeleteCard());
        return cardsApi.saveDelete(card)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteCard());
                            dispatch(receiveDeleteCard(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteCard(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteCard(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteCard(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteCard(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteCard(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT CARD
export const REQUEST_PRINT_CARD = "REQUEST_PRINT_CARD";
export const SUCCESS_PRINT_CARD = "SUCCESS_PRINT_CARD";
export const ERROR_PRINT_CARD = "ERROR_PRINT_CARD";
export const RESET_PRINT_CARD = "RESET_PRINT_CARD";

function requestPrintCard() {
    return {
        type: REQUEST_PRINT_CARD,
    }
}

function receivePrintCard(turnos) {
    return {
        type: SUCCESS_PRINT_CARD,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintCard(error) {
    return {
        type: ERROR_PRINT_CARD,
        error: error,
    }
}

export function resetPrintCard() {
    return {
        type: RESET_PRINT_CARD,
    }
}

export function printCard(idCard) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintCard());
        return cardsApi.printCard(idCard)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintCard(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintCard(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintCard(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintCard(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintCard(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}