import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import moment from "moment";
import max from "lodash/max"
//Components
import {
    Breadcrumbs,
    Container,
    InputAdornment,
    Menu,
    MenuItem,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tabs,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core';
import {DatePicker} from '@material-ui/pickers'
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {useDispatch, useSelector} from "react-redux";
import invitationReset from "../../reset/invitationReset";
import {
    fetchInvitations,
    invalidateInvitations,
    resetInvitations,
    saveUpdateInvitation,
    updateInvitation
} from "../../actions/InvitationActions";
import {Skeleton, ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import CustomPaper from "../../library/CustomPaper/CustomPaper";
import PaperHeader from "../../library/CustomPaper/PaperHeader";
import CropFreeIcon from "@material-ui/icons/Search";
import Grow from "../../library/Grow";
import PaperFooter from "../../library/CustomPaper/PaperFooter";
import Pagination from "@material-ui/lab/Pagination";
import {Link} from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import BtnAdd from "../../library/Buttons/BtnAdd";
import ContainerBtn from "../../library/Buttons/ContainerBtn";
import {fetchRolesIfNeeded} from "../../actions/RoleActions";
import InvitationStatusChip from "./InvitationStatusChip";
import {createInvitationStatus, saveCreateInvitationStatus} from "../../actions/InvitationStatusActions";
//Icons
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import RefreshIcon from '@material-ui/icons/Refresh';
import CachedIcon from '@material-ui/icons/Cached';
import * as InvitationStatusC from "../../constants/InvitationStatus";
import clone from "lodash/clone"
import Button from "@material-ui/core/Button";
//Img
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import userReset from "../../reset/userReset";

const useStyles = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(2, 0)
    },
    root: {
        display: 'flex',
        justifyContent: "space-between"
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
        backgroundSize: "contain"
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    tabsContainer: {
        "& .MuiTab-root": {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
        },
        "& .Mui-selected": {
            fontWeight: 900,
            color: theme.palette.primary["main"],
            backgroundColor: "white",
            boxShadow: "3px 0 5px rgba(0,0,0,0.1)",
        }
    },
    btnOpenCalendar: {
        ['@media (max-width:500px)']: {
            width: "100%"
        }
    }
}));


function InvitationsList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const anchorRef = useRef();
    const theme = useTheme();

    //Store
    const invitations = useSelector(state => state.invitations);
    const users = useSelector(state => state.users);
    const invitationsStatus = useSelector(state => state.invitationsStatus);

    //State
    const [search, setSearch] = useState("");
    const [openMenu, setOpenMenu] = useState(null);
    const [openStatus, setOpenStatus] = useState(false);
    const [openCalendar, setOpenCalendar] = useState(false);
    const [refOpenMenu, setRefOpenMenu] = useState(null);
    const [refresh, setRefresh] = useState(false);


    const params = new URLSearchParams(props.location.search);
    const order = params.get("order") ? params.get("order") : "dateFrom";
    const direction = params.get("direction") ? params.get("direction") : "desc";
    const page = params.get("page") ? params.get("page") : 1;
    const searchPhase = params.get("sp") ? params.get("sp") : "";
    const id = params.get("id") ? params.get("id") : "";
    const invitationStatus = params.get("invitationStatus") ? params.get("invitationStatus") : "Todos";
    const dateFrom = params.get("dateFrom") ? params.get("dateFrom") : moment().format("YYYY-MM-DD");
    const dateTo = params.get("dateTo") ? params.get("dateTo") : moment().format("YYYY-MM-DD");
    const period = params.get("period") ? params.get("period") : "Día";


    const disabled = invitations.byId.isFetching || invitations.delete.isDeleting;

    //Hooks
    const timer = useRef();
    const mounted = useRef();


    //Effects
    useEffect(() => {
        window.scrollTo(0, 0);

        if (searchPhase != search) {
            setSearch(searchPhase);
        }
        dispatch(fetchRolesIfNeeded({}));

        return function cleanup() {
            invitationReset.resetAll(dispatch);
            userReset.resetAll(dispatch);
        }
    }, []);

    useEffect(() => {
        let filtros = {
            searchPhase: search ? search : "",
            order: order,
            direction: direction,
            page: page,
            id: id,
            with: ["user", "invitationStatus"],
            invitationStatus: invitationStatus,
            dateFrom: dateFrom,
            dateTo: dateTo
        };

        dispatch(resetInvitations());
        dispatch(invalidateInvitations());
        dispatch(fetchInvitations(filtros));
    }, [props.location.search, refresh]);

    const changeOrder = (or) => {
        params.set("order", or ? or : "");
        let direction = params.get("direction");
        params.set("direction", direction === "asc" ? "desc" : "asc");
        props.history.push("/invitations?" + params.toString());
    };
    const WAIT_INTERVAL = 500;

    const changeSearch = (e) => {

        clearTimeout(timer.current);
        let valor = e.target.value;
        setSearch(e.target.value);

        timer.current = setTimeout(() => triggerChange(valor), WAIT_INTERVAL);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            clearTimeout(timer.current);
            triggerChange();
        }
    };

    const triggerChange = (searchPhase) => {
        if ((!searchPhase && !search) || (searchPhase === "")) {
            params.delete("sp");
            props.history.push("/invitations?" + params.toString());
        } else {
            params.delete("page");
            params.set("sp", searchPhase ? searchPhase : search);
            props.history.push("/invitations?" + params.toString());
        }
    };

    const handleChangePage = (e, page) => {
        let pagina = page;
        let paramsPagina = new URLSearchParams(props.location.search);
        paramsPagina.set("page", pagina ? pagina : "1");
        props.history.push("/invitations?" + paramsPagina.toString());
    };

    const handleSynchInvitation = (idInvitation) => {
        let invitation = clone(invitations.byId.invitations[idInvitation]);
        invitation.reSynch = true;
        invitation.inProgress = true;
        dispatch(updateInvitation(invitation));
        dispatch(saveUpdateInvitation([])).then(() => {
            dispatch(fetchInvitations({id: idInvitation, with: ["user", "invitationStatus"]}));
        });
        handleCloseMenu();
    }

    const handleChangeStatus = (idInvitation, desStatus) => {
        let cambio = {desInvitationStatus: desStatus, idInvitation};
        dispatch(createInvitationStatus(cambio));
        dispatch(saveCreateInvitationStatus()).then(() => {
            dispatch(fetchInvitations({id: idInvitation, with: ["user", "invitationStatus"]}));
        });
    }

    const handleCloseMenu = () => {
        setOpenMenu(null);
        setRefOpenMenu(null)
    }

    const handleChangeInvitationStatus = (e, type) => {
        let status = InvitationStatusC.evaluateInvitationID(type);
        let paramsType = new URLSearchParams(props.location.search);
        paramsType.set("invitationStatus", status ? status : "");
        paramsType.delete("page");
        props.history.push("/invitations?" + paramsType.toString());
        setOpenStatus(false);
    };

    const handleChangePeriod = (e, type) => {
        let paramsType = new URLSearchParams(props.location.search);
        let calcDateFrom = moment();
        let calcDateTo = moment();
        switch (type) {
            case "Semana":
                calcDateFrom.startOf('week');
                calcDateTo.endOf('week');
                break;
            case "Mes":
                calcDateFrom.startOf('month');
                calcDateTo.endOf('month');
                break;
        }
        paramsType.delete("page");
        paramsType.set("period", type ? type : "");
        paramsType.set("dateTo", calcDateTo.format("YYYY-MM-DD"));
        paramsType.set("dateFrom", calcDateFrom.format("YYYY-MM-DD"));
        props.history.push("/invitations?" + paramsType.toString());
    };

    const handleChangeDateToday = () => {
        let paramsType = new URLSearchParams(props.location.search);
        let calcDateFrom = moment();
        paramsType.delete("page");
        paramsType.set("dateTo", calcDateFrom.format("YYYY-MM-DD"));
        paramsType.set("dateFrom", calcDateFrom.format("YYYY-MM-DD"));
        paramsType.set("period", "Día");
        props.history.push("/invitations?" + paramsType.toString());
    };

    const handleChangeDate = (diff) => {
        let paramsType = new URLSearchParams(props.location.search);
        let calcDateFrom = moment(dateFrom, "YYYY-MM-DD")
        let calcDateTo = moment(dateTo, "YYYY-MM-DD")
        switch (period) {
            case "Día":
                calcDateFrom.add(diff, "days");
                calcDateTo = calcDateFrom;
                break;
            case "Semana":
                calcDateFrom.add(diff, "weeks");
                calcDateTo = moment(calcDateFrom);
                calcDateFrom.startOf('week');
                calcDateTo.endOf('week');
                break;
            case "Mes":
                calcDateFrom.add(diff, "months");
                calcDateTo = moment(calcDateFrom);
                calcDateFrom.startOf('month');
                calcDateTo.endOf('month');
                break;
        }

        paramsType.set("dateFrom", calcDateFrom.format("YYYY-MM-DD"));
        paramsType.set("dateTo", calcDateTo.format("YYYY-MM-DD"));
        paramsType.delete("page");
        props.history.push("/invitations?" + paramsType.toString());
        setOpenStatus(false);
    };

    const handleChangeDateCalendar = (e) => {
        if (disabled === false) {
            let paramsType = new URLSearchParams(props.location.search);
            if (e && e !== "") {
                paramsType.delete("page");
                paramsType.set("dateFrom", e.format("YYYY-MM-DD"));
                paramsType.set("dateTo", e.format("YYYY-MM-DD"));
                paramsType.set("period", "Día");
                props.history.push("/invitations?" + paramsType.toString());
            }
        }
        setOpenCalendar(false);
    };


    let Invitations = [];

    if (invitations.byId.isFetching && invitations.allIds.length === 0) {
        Invitations = [...Array(5).keys()].map((i) => {
            return (<TableRow key={"inv" + i}>
                {[...Array(10).keys()].map((i) =>
                    <TableCell key={"s" + i}><Skeleton variant="text"/></TableCell>
                )}
            </TableRow>);
        });
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpenStatus(false);
    };

    if (!invitations.byId.isFetching || invitations.allIds.length > 0)
        Invitations = invitations.allIds.map(idInvitation => {
            let invitation = invitations.byId.invitations[idInvitation];
            let user = invitation && invitation.idUser ? users.byId.users[invitation.idUser] : null;
            let dateFrom = moment(invitation.dateFrom);
            let dateTo = moment(invitation.dateTo);
            let timeFrom = moment(invitation.timeFrom, "HH:mm:ss");
            let timeTo = moment(invitation.timeTo, "HH:mm:ss");
            let status = invitationsStatus.byId.invitationsStatus[max(invitation.invitation_status)];
            return (
                <TableRow key={invitation.id}>
                    <TableCell>{invitation ? invitation.id : ""}</TableCell>
                    <TableCell>{invitation && dateFrom.isValid() ? dateFrom.format("DD-MM-YYYY") : ""}</TableCell>
                    <TableCell>{invitation && dateTo.isValid() ? dateTo.format("DD-MM-YYYY") : ""}</TableCell>
                    <TableCell>{(invitation && timeFrom.isValid() ? timeFrom.format("HH:mm") : "") + "-" + (invitation && timeTo.isValid() ? timeTo.format("HH:mm") : "")}</TableCell>
                    <TableCell>{user ? user.lastname + " " + user.name : ""}</TableCell>
                    {/*<TableCell>{user ? user.email : ""}</TableCell>*/}
                    <TableCell>{user ? user.enterprise : ""}</TableCell>
                    <TableCell>
                        <InvitationStatusChip desInvitationStatus={status ? status.desInvitationStatus : null}
                                              id={idInvitation} handleChangeStatus={handleChangeStatus}/>
                    </TableCell>
                    <TableCell>
                        {
                            invitation && invitation.isSynchro === 1 && !invitation.errorSynchro && status && status.desInvitationStatus === InvitationStatusC.CONFIRMADA &&
                            <Tooltip title="El dispositivo esta listo para recibir el invitado">
                                <IconButton aria-label="editar" size={"small"} disabled={disabled}>
                                    <CheckCircleOutlineIcon color="secondary"/>
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            invitation && invitation.inProgress === 1 && status && status.desInvitationStatus === InvitationStatusC.CONFIRMADA &&
                            <Tooltip title="Sincronizando con dispositivo">
                                <IconButton aria-label="editar" size={"small"} disabled={disabled}>
                                    <CachedIcon color="secondary"/>
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            invitation && invitation.isSynchro === 0 && !invitation.inProgress && !!invitation.errorSynchro && status && status.desInvitationStatus === InvitationStatusC.CONFIRMADA &&
                            <>
                                <Tooltip
                                    title="Hubo un problema con el disposivo externo controle la conexión y vuelva a intentarlo.">
                                    <IconButton aria-label="editar" size={"small"} disabled={disabled}
                                                onClick={(event) => {
                                                    setRefOpenMenu(event.currentTarget);
                                                    setOpenMenu(idInvitation);
                                                }}>
                                        <ErrorOutlineIcon color="secondary" style={{fill: "#db0d0d"}}/>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={refOpenMenu}
                                    keepMounted
                                    open={openMenu === idInvitation}
                                    onClose={handleCloseMenu}
                                >
                                    <MenuItem onClick={() => handleSynchInvitation(idInvitation)}>Volver a
                                        sincronizar</MenuItem>
                                </Menu>
                            </>
                        }
                    </TableCell>
                    <TableCell>
                        <Link to={"/invitations/" + invitation.id}
                        >
                            <Tooltip title="Editar">
                                <IconButton aria-label="editar" size={"small"} disabled={disabled}>
                                    <EditIcon color="secondary"/>
                                </IconButton>
                            </Tooltip>
                        </Link>
                    </TableCell>
                    <TableCell>
                        <Link to={"/invitations/" + invitation.id + "/share"}
                        >
                            <Tooltip title="Compartir">
                                <IconButton aria-label="compartir" size={"small"} disabled={disabled}>
                                    <ShareOutlinedIcon color="secondary"/>
                                </IconButton>
                            </Tooltip>
                        </Link>
                    </TableCell>
                </TableRow>
            )
        });

    if (Invitations.length === 0)
        Invitations.push(
            <TableRow key={0}>
                <TableCell align="center" colSpan={12}>No se encontraron invitaciones</TableCell>
            </TableRow>)


    const totalPaginas = Math.ceil(invitations.totalInvitations ? invitations.totalInvitations / 50 : 0);

    return (
        <Container maxWidth={"lg"}>
            <Breadcrumbs aria-label="breadcrumb" separator={"‣"}>
                <Link color="inherit" to="/">
                    Home
                </Link>
            </Breadcrumbs>
            <Typography component={"h1"} variant={"h1"}>
                Invitaciones
            </Typography>
            <CustomPaper>
                <Box component={"div"}
                     style={{
                         backgroundColor: "rgb(244, 246, 248)",
                         borderTopRightRadius: "16px",
                         borderTopLeftRadius: "16px",
                         borderBottom: "1px solid rgba(0,0,0,0.04)"
                     }}>
                    <Tabs
                        value={InvitationStatusC.evaluateInvitationDes(invitationStatus)}
                        onChange={handleChangeInvitationStatus}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        className={classes.tabsContainer}
                    >
                        {
                            [
                                "Todos",
                                InvitationStatusC.CONFIRMADA,
                                InvitationStatusC.PENDIENTE,
                                InvitationStatusC.CANCELADA,
                                InvitationStatusC.EXPIRADA
                            ].map((option, index) => (
                                <Tab
                                    key={option}
                                    disabled={option === invitationStatus}
                                    label={option}
                                />
                            ))
                        }
                    </Tabs>
                </Box>
                <PaperHeader>
                    <Grid container style={{flexWrap: "nowrap"}}>
                        <TextField
                            id="search"
                            placeholder="Buscar ..."
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <CropFreeIcon/>
                                        </IconButton>
                                    </InputAdornment>,
                                // disabled: disabled
                            }}
                            value={search ? search : ""}
                            onChange={(e) => changeSearch(e)}
                            onKeyDown={(e) => handleKeyDown(e)}
                        />
                        <Grow/>
                        <Button
                            variant={"outlined"}
                            style={{
                                borderRadius: "8px"
                            }}
                            onClick={() => setRefresh((prev) => !prev)}>
                            <RefreshIcon/>
                        </Button>
                        <Box display={"none"}>
                            <DatePicker
                                autoOk
                                clearable
                                value={moment(dateFrom, "YYYY-MM-DD")}
                                onChange={handleChangeDateCalendar}
                                open={openCalendar}
                            />
                        </Box>
                    </Grid>
                    <Box display={"flex"}
                         width={"100%"}
                         alignItems={"center"}
                         marginTop={"30px"}
                         flexWrap={"wrap"}
                        // gridRowGap={"10px"}
                        // gridColumnGap={"12px"}
                         justifyContent={"center"}
                    >
                        <Box component={"div"} display={"flex"} gridGap={"4px"} alignItems={"center"}>
                            <IconButton
                                onClick={() => handleChangeDate(-1)}
                                size={"small"}
                            >
                                <KeyboardArrowLeftIcon style={{width: "30px", height: "30px"}}/>
                            </IconButton>
                            <Button
                                onClick={handleChangeDateToday}
                                size={"small"}
                                variant={"contained"}
                                color={"primary"}
                            >
                                Hoy
                            </Button>
                            {/*<Button onClick={() => setOpenCalendar(true)}><EventIcon/></Button>*/}
                            {/*<Button onClick={() => setRefresh((prev) => !prev)}><RefreshIcon/></Button>*/}
                            <IconButton
                                onClick={() => handleChangeDate(1)}
                                size={"small"}
                            >
                                <KeyboardArrowRightIcon style={{width: "30px", height: "30px"}}/>
                            </IconButton>
                        </Box>
                        <Button
                            onClick={() => setOpenCalendar(true)}
                            className={classes.btnOpenCalendar}
                            style={{
                                textTransform: "inherit",
                                // width: "100%"
                            }}
                        >
                            <Typography component={"h5"} variant={"h5"}>
                                {
                                    period === 'Día' && moment(dateFrom, "YYYY-MM-DD").isSame(moment(), "date") === false &&
                                    moment(dateFrom, "YYYY-MM-DD").format("dddd DD [de] MMMM YYYY")
                                }
                                {
                                    period === 'Día' && moment(dateFrom, "YYYY-MM-DD").isSame(moment(), "date") &&
                                    "Hoy"
                                }
                                {
                                    period === 'Semana' &&
                                    moment(dateFrom, "YYYY-MM-DD").format("DD") + " al " + moment(dateTo, "YYYY-MM-DD").format("DD [de] MMMM YYYY")
                                }
                                {
                                    period === 'Mes' &&
                                    moment(dateFrom, "YYYY-MM-DD").format("MMMM [de] YYYY")
                                }
                            </Typography>
                        </Button>
                        <Grow/>
                        <ToggleButtonGroup
                            value={period}
                            exclusive
                            onChange={handleChangePeriod}
                            aria-label="text alignment"
                            style={{height: "fit-content"}}
                        >
                            <ToggleButton value="Día" aria-label="centered">
                                Día
                            </ToggleButton>
                            <ToggleButton value="Semana" aria-label="right aligned">
                                Semana
                            </ToggleButton>
                            <ToggleButton value="Mes" aria-label="justified">
                                Mes
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </PaperHeader>
                <TableContainer>
                    <Table aria-label="Tabla de contenido">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={order === "id"}
                                        direction={direction}
                                        onClick={() => changeOrder("id")}
                                    >
                                        <b>ID</b>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={order === "dateFrom"}
                                        direction={direction}
                                        onClick={() => changeOrder("dateFrom")}
                                    >
                                        <b>Fecha desde</b>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={order === "dateTo"}
                                        direction={direction}
                                        onClick={() => changeOrder("dateTo")}
                                    >
                                        <b>Fecha hasta</b>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={order === "timeFrom"}
                                        direction={direction}
                                        onClick={() => changeOrder("timeFrom")}
                                    >
                                        <b>Horario</b>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={order === "lastname"}
                                        direction={direction}
                                        onClick={() => changeOrder("lastname")}
                                    >
                                        <b>Apellido y nombre</b>
                                    </TableSortLabel>
                                </TableCell>
                                {/*<TableCell>*/}
                                {/*    <TableSortLabel*/}
                                {/*        active={order === "email"}*/}
                                {/*        direction={direction}*/}
                                {/*        onClick={() => changeOrder("email")}*/}
                                {/*    >*/}
                                {/*        <b>Email</b>*/}
                                {/*    </TableSortLabel>*/}
                                {/*</TableCell>*/}
                                <TableCell>
                                    <TableSortLabel
                                        active={order === "enterprise"}
                                        direction={direction}
                                        onClick={() => changeOrder("enterprise")}
                                    >
                                        <b>Empresa</b>
                                    </TableSortLabel>
                                </TableCell>
                                {/*<TableCell width={"20px"}/>*/}
                                <TableCell width={"10px"}/>
                                <TableCell width={"10px"}/>
                                <TableCell width={"10px"}/>
                                <TableCell width={"10px"}/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Invitations
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaperFooter style={{justifyContent: "center"}}>
                    {
                        !!totalPaginas && totalPaginas > 1 &&
                        <Pagination count={totalPaginas} page={page ? parseInt(page) : 1} color="primary"
                                    onChange={handleChangePage} size={"small"}/>
                    }
                </PaperFooter>
            </CustomPaper>
            <ContainerBtn>
                <BtnAdd onClick={() => props.history.push("/invitations/new")} fetching={disabled}/>
            </ContainerBtn>
        </Container>)
}

InvitationsList.propTypes = {}


export default InvitationsList;