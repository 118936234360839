import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {useDispatch} from "react-redux";
//Components
import CRUDInvitation from "./CRUDInvitation";
import InvitationsList from "./InvitationsList";
import ShareInvitation from "./ShareInvitation";

export default function Invitations() {

    const dispatch = useDispatch();
    //Effects
    useEffect(() => {
        return function cleanup() {
        }

    }, []);

    return (
        <Switch>
            <Route path="/invitations/new" component={CRUDInvitation}/>
            <Route path="/invitations/:idInvitation/share" component={ShareInvitation}/>
            <Route path="/invitations/:idInvitation" component={CRUDInvitation}/>
            <Route path="/invitations" component={InvitationsList}/>
        </Switch>

    );
}

