import {
    INVALIDATE_EVENTS,
    ERROR_EVENTS,
    RECEIVE_EVENTS,
    REQUEST_EVENTS,
    RESET_EVENTS,
    ERROR_EVENT,
    RECEIVE_EVENT,
    REQUEST_EVENT,
    UPDATE_EVENT,
    REQUEST_UPDATE_EVENT,
    SUCCESS_UPDATE_EVENT,
    ERROR_UPDATE_EVENT,
    RESET_UPDATE_EVENT,
    REQUEST_UPDATE_EVENTS,
    SUCCESS_UPDATE_EVENTS,
    ERROR_UPDATE_EVENTS,
    RESET_UPDATE_EVENTS,
    CREATE_EVENT,
    ERROR_CREATE_EVENT,
    REQUEST_CREATE_EVENT,
    RESET_CREATE_EVENT,
    SUCCESS_CREATE_EVENT,
    REQUEST_CREATE_EVENTS,
    SUCCESS_CREATE_EVENTS,
    ERROR_CREATE_EVENTS,
    RESET_CREATE_EVENTS,
    DELETE_EVENT,
    DELETE_CREATE_EVENT,
    DELETE_UPDATE_EVENT,
    REQUEST_DELETE_EVENT,
    SUCCESS_DELETE_EVENT,
    ERROR_DELETE_EVENT,
    RESET_DELETE_EVENT,
    REQUEST_PRINT_EVENT,
    SUCCESS_PRINT_EVENT,
    ERROR_PRINT_EVENT,
    RESET_PRINT_EVENT,
    RECEIVE_FILE_EVENT
} from '../actions/EventActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        events: {},
        files: {},
    }
}

function eventsById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_EVENTS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_EVENTS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_EVENTS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_EVENTS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                events: {}
            });
        case RECEIVE_EVENTS:
            let dato = action.events.entities.events;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                events: merge({}, state.events, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_EVENT:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_EVENT:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_EVENT:
            let datoEvent = action.event.entities.events;
            return Object.assign({}, state, {
                events: merge({}, state.events, datoEvent),
                isFetching: false,
            });
        case RECEIVE_FILE_EVENT:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_EVENT:
            let datoEventEliminado = action.event.entities.events;
            return Object.assign({}, state, {
                events: mergeWith(clone(datoEventEliminado), state.events, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_EVENT:
            let datoEventCreado = action.event.entities.events;
            return Object.assign({}, state, {
                events: mergeWith(clone(datoEventCreado), state.events, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_EVENTS:
            let datosEventCreado = action.events.entities.events;
                return Object.assign({}, state, {
                    events: mergeWith(clone(datosEventCreado), state.events, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_EVENT:
            let datoEventActualizado = action.event.entities.events;
            return Object.assign({}, state, {
                events: mergeWith(clone(datoEventActualizado), state.events, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_EVENTS:
            let datosEventActualizado = action.events.entities.events;
                return Object.assign({}, state, {
                    events: mergeWith(clone(datosEventActualizado), state.events, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                events: {}
            });
        default:
            return state
    }
}


function allEvents(state = [], action) {
    switch (action.type) {
        case RECEIVE_EVENTS:
            return action.events.result && action.events.result.events ? union(state, action.events.result.events) : (action.events.result ? action.events.result : state) ;
        case RECEIVE_EVENT:
                return action.event.result ? union(state, [action.event.result]) : state;
        
        case SUCCESS_CREATE_EVENT:
                   let datoEventSCreate = action.event.entities.events;
                   let idNuevoSCreate = null;
                   if (Object.values(datoEventSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoEventSCreate)[0] && Object.values(datoEventSCreate)[0].id ? Object.values(datoEventSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_EVENTS:
                   let eventsCreate = action.events.entities && action.events.entities.events ? action.events.entities.events : null;
                   return eventsCreate ?
                       union(state, Object.values(eventsCreate).map((events) => {
                           return events.id
                       })) : state;
        case RESET_EVENTS:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalEvents(state = null, action) {
    switch (action.type) {
        case RECEIVE_EVENTS:
            return action.events && action.events.result.total ? action.events.result.total : 0;
        case RESET_EVENTS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_EVENT:
            let dato = action.event.entities.events;
            let event = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: event ? event : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_EVENT:
            let idsUpdate = [];
            Object.values(action.event).map((eventUpdate) => {
                if (eventUpdate && eventUpdate.id)
                    idsUpdate.push(eventUpdate.id);
            });
            return merge({}, state, {
                activo: action.event,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_EVENT:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_EVENT:
            let datoEventActualizado = {};
            if (Object.values(action.event.entities.events).length > 0)
                datoEventActualizado = Object.values(action.event.entities.events)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoEventActualizado
            });
        case ERROR_UPDATE_EVENT:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_EVENTS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_EVENTS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_EVENTS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_EVENT:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           

        case DELETE_EVENT:
            let datoEventDelete = action.event;
            let idsDelete = [];
           Object.values(action.event).map((eventDelete) => {
               if (eventDelete && eventDelete.id)
                   idsDelete.push(eventDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoEventDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_EVENT:
                       let datoEventDeleteUpdate = action.event;
                       let idsDeleteUpdate = [];
                      Object.values(action.event).map((eventDelete) => {
                          if (eventDelete && eventDelete.id)
                              idsDeleteUpdate.push(eventDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoEventDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_EVENT:
                    let datoEventDeleted = {};
                    if (Object.values(action.event.entities.events).length > 0)
                        datoEventDeleted = Object.values(action.event.entities.events)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoEventDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_EVENT:
             let idsCreate = [];
             Object.values(action.event).map((eventCreate) => {
                 if (eventCreate && eventCreate.id)
                     idsCreate.push(eventCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.event,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_EVENT:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_EVENT:
            let datoEventNuevo = {};
            if (Object.values(action.event.entities.events).length > 0)
                datoEventNuevo = Object.values(action.event.entities.events)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoEventNuevo,
                nuevos: []
            });
        case ERROR_CREATE_EVENT:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_EVENTS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_EVENTS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_EVENTS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_EVENT:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             
        case DELETE_EVENT:
           let datoEventDelete = action.event;
           let idsDelete = [];
           Object.values(action.event).map((eventDelete) => {
               if (eventDelete && eventDelete.id)
                   idsDelete.push(eventDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoEventDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_EVENT:
                  let datoEventDeleteCreate = action.event;
                  let idsDeleteCreate = [];
                  Object.values(action.event).map((eventDelete) => {
                      if (eventDelete && eventDelete.id)
                          idsDeleteCreate.push(eventDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoEventDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_EVENT:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.event,
                error: null,
            });
        case REQUEST_DELETE_EVENT:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_EVENT:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_EVENT:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_EVENT:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_EVENT:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_EVENT:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_EVENT:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const events = combineReducers({
    byId: eventsById,
    allIds: allEvents,
    update: update,
    create: create,
    totalEvents: totalEvents,
    delete: deleter,
    print: print
});

export default events;