import {
    INVALIDATE_DEVICES,
    ERROR_DEVICES,
    RECEIVE_DEVICES,
    REQUEST_DEVICES,
    RESET_DEVICES,
    ERROR_DEVICE,
    RECEIVE_DEVICE,
    REQUEST_DEVICE,
    UPDATE_DEVICE,
    REQUEST_UPDATE_DEVICE,
    SUCCESS_UPDATE_DEVICE,
    ERROR_UPDATE_DEVICE,
    RESET_UPDATE_DEVICE,
    REQUEST_UPDATE_DEVICES,
    SUCCESS_UPDATE_DEVICES,
    ERROR_UPDATE_DEVICES,
    RESET_UPDATE_DEVICES,
    CREATE_DEVICE,
    ERROR_CREATE_DEVICE,
    REQUEST_CREATE_DEVICE,
    RESET_CREATE_DEVICE,
    SUCCESS_CREATE_DEVICE,
    REQUEST_CREATE_DEVICES,
    SUCCESS_CREATE_DEVICES,
    ERROR_CREATE_DEVICES,
    RESET_CREATE_DEVICES,
    DELETE_DEVICE,
    DELETE_CREATE_DEVICE,
    DELETE_UPDATE_DEVICE,
    REQUEST_DELETE_DEVICE,
    SUCCESS_DELETE_DEVICE,
    ERROR_DELETE_DEVICE,
    RESET_DELETE_DEVICE,
    REQUEST_PRINT_DEVICE,
    SUCCESS_PRINT_DEVICE,
    ERROR_PRINT_DEVICE,
    RESET_PRINT_DEVICE,
    RECEIVE_FILE_DEVICE
} from '../actions/DeviceActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        devices: {},
        files: {},
    }
}

function devicesById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_DEVICES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_DEVICES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_DEVICES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_DEVICES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                devices: {}
            });
        case RECEIVE_DEVICES:
            let dato = action.devices.entities.devices;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                devices: merge({}, state.devices, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_DEVICE:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_DEVICE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_DEVICE:
            let datoDevice = action.device.entities.devices;
            return Object.assign({}, state, {
                devices: merge({}, state.devices, datoDevice),
                isFetching: false,
            });
        case RECEIVE_FILE_DEVICE:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_DEVICE:
            let datoDeviceEliminado = action.device.entities.devices;
            return Object.assign({}, state, {
                devices: mergeWith(clone(datoDeviceEliminado), state.devices, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_DEVICE:
            let datoDeviceCreado = action.device.entities.devices;
            return Object.assign({}, state, {
                devices: mergeWith(clone(datoDeviceCreado), state.devices, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_DEVICES:
            let datosDeviceCreado = action.devices.entities.devices;
                return Object.assign({}, state, {
                    devices: mergeWith(clone(datosDeviceCreado), state.devices, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_DEVICE:
            let datoDeviceActualizado = action.device.entities.devices;
            return Object.assign({}, state, {
                devices: mergeWith(clone(datoDeviceActualizado), state.devices, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_DEVICES:
            let datosDeviceActualizado = action.devices.entities.devices;
                return Object.assign({}, state, {
                    devices: mergeWith(clone(datosDeviceActualizado), state.devices, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                devices: {}
            });
        default:
            return state
    }
}


function allDevices(state = [], action) {
    switch (action.type) {
        case RECEIVE_DEVICES:
            return action.devices.result && action.devices.result.devices ? union(state, action.devices.result.devices) : (action.devices.result ? action.devices.result : state) ;
        case RECEIVE_DEVICE:
                return action.device.result ? union(state, [action.device.result]) : state;
        
        case SUCCESS_CREATE_DEVICE:
                   let datoDeviceSCreate = action.device.entities.devices;
                   let idNuevoSCreate = null;
                   if (Object.values(datoDeviceSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoDeviceSCreate)[0] && Object.values(datoDeviceSCreate)[0].id ? Object.values(datoDeviceSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_DEVICES:
                   let devicesCreate = action.devices.entities && action.devices.entities.devices ? action.devices.entities.devices : null;
                   return devicesCreate ?
                       union(state, Object.values(devicesCreate).map((devices) => {
                           return devices.id
                       })) : state;
        case RESET_DEVICES:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalDevices(state = null, action) {
    switch (action.type) {
        case RECEIVE_DEVICES:
            return action.devices && action.devices.result.total ? action.devices.result.total : 0;
        case RESET_DEVICES:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_DEVICE:
            let dato = action.device.entities.devices;
            let device = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: device ? device : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_DEVICE:
            let idsUpdate = [];
            Object.values(action.device).map((deviceUpdate) => {
                if (deviceUpdate && deviceUpdate.id)
                    idsUpdate.push(deviceUpdate.id);
            });
            return merge({}, state, {
                activo: action.device,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_DEVICE:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_DEVICE:
            let datoDeviceActualizado = {};
            if (Object.values(action.device.entities.devices).length > 0)
                datoDeviceActualizado = Object.values(action.device.entities.devices)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoDeviceActualizado
            });
        case ERROR_UPDATE_DEVICE:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_DEVICES:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_DEVICES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_DEVICES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_DEVICE:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           

        case DELETE_DEVICE:
            let datoDeviceDelete = action.device;
            let idsDelete = [];
           Object.values(action.device).map((deviceDelete) => {
               if (deviceDelete && deviceDelete.id)
                   idsDelete.push(deviceDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoDeviceDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_DEVICE:
                       let datoDeviceDeleteUpdate = action.device;
                       let idsDeleteUpdate = [];
                      Object.values(action.device).map((deviceDelete) => {
                          if (deviceDelete && deviceDelete.id)
                              idsDeleteUpdate.push(deviceDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoDeviceDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_DEVICE:
                    let datoDeviceDeleted = {};
                    if (Object.values(action.device.entities.devices).length > 0)
                        datoDeviceDeleted = Object.values(action.device.entities.devices)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoDeviceDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_DEVICE:
             let idsCreate = [];
             Object.values(action.device).map((deviceCreate) => {
                 if (deviceCreate && deviceCreate.id)
                     idsCreate.push(deviceCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.device,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_DEVICE:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_DEVICE:
            let datoDeviceNuevo = {};
            if (Object.values(action.device.entities.devices).length > 0)
                datoDeviceNuevo = Object.values(action.device.entities.devices)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoDeviceNuevo,
                nuevos: []
            });
        case ERROR_CREATE_DEVICE:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_DEVICES:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_DEVICES:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_DEVICES:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_DEVICE:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             
        case DELETE_DEVICE:
           let datoDeviceDelete = action.device;
           let idsDelete = [];
           Object.values(action.device).map((deviceDelete) => {
               if (deviceDelete && deviceDelete.id)
                   idsDelete.push(deviceDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoDeviceDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_DEVICE:
                  let datoDeviceDeleteCreate = action.device;
                  let idsDeleteCreate = [];
                  Object.values(action.device).map((deviceDelete) => {
                      if (deviceDelete && deviceDelete.id)
                          idsDeleteCreate.push(deviceDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoDeviceDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_DEVICE:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.device,
                error: null,
            });
        case REQUEST_DELETE_DEVICE:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_DEVICE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_DEVICE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_DEVICE:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_DEVICE:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_DEVICE:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_DEVICE:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const devices = combineReducers({
    byId: devicesById,
    allIds: allDevices,
    update: update,
    create: create,
    totalDevices: totalDevices,
    delete: deleter,
    print: print
});

export default devices;