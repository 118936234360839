import {
    INVALIDATE_NOTIFICATIONS,
    ERROR_NOTIFICATIONS,
    RECEIVE_NOTIFICATIONS,
    REQUEST_NOTIFICATIONS,
    RESET_NOTIFICATIONS,
    ERROR_NOTIFICATION,
    RECEIVE_NOTIFICATION,
    REQUEST_NOTIFICATION,
    UPDATE_NOTIFICATION,
    REQUEST_UPDATE_NOTIFICATION,
    SUCCESS_UPDATE_NOTIFICATION,
    ERROR_UPDATE_NOTIFICATION,
    RESET_UPDATE_NOTIFICATION,
    REQUEST_UPDATE_NOTIFICATIONS,
    SUCCESS_UPDATE_NOTIFICATIONS,
    ERROR_UPDATE_NOTIFICATIONS,
    RESET_UPDATE_NOTIFICATIONS,
    CREATE_NOTIFICATION,
    ERROR_CREATE_NOTIFICATION,
    REQUEST_CREATE_NOTIFICATION,
    RESET_CREATE_NOTIFICATION,
    SUCCESS_CREATE_NOTIFICATION,
    REQUEST_CREATE_NOTIFICATIONS,
    SUCCESS_CREATE_NOTIFICATIONS,
    ERROR_CREATE_NOTIFICATIONS,
    RESET_CREATE_NOTIFICATIONS,
    DELETE_NOTIFICATION,
    DELETE_CREATE_NOTIFICATION,
    DELETE_UPDATE_NOTIFICATION,
    REQUEST_DELETE_NOTIFICATION,
    SUCCESS_DELETE_NOTIFICATION,
    ERROR_DELETE_NOTIFICATION,
    RESET_DELETE_NOTIFICATION,
    REQUEST_PRINT_NOTIFICATION,
    SUCCESS_PRINT_NOTIFICATION,
    ERROR_PRINT_NOTIFICATION,
    RESET_PRINT_NOTIFICATION,
    RECEIVE_FILE_NOTIFICATION
} from '../actions/NotificationActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        notifications: {},
        files: {},
    }
}

function notificationsById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_NOTIFICATIONS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_NOTIFICATIONS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_NOTIFICATIONS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_NOTIFICATIONS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                notifications: {}
            });
        case RECEIVE_NOTIFICATIONS:
            let dato = action.notifications.entities.notifications;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                notifications: merge({}, state.notifications, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_NOTIFICATION:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_NOTIFICATION:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_NOTIFICATION:
            let datoNotification = action.notification.entities.notifications;
            return Object.assign({}, state, {
                notifications: merge({}, state.notifications, datoNotification),
                isFetching: false,
            });
        case RECEIVE_FILE_NOTIFICATION:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_NOTIFICATION:
            let datoNotificationEliminado = action.notification.entities.notifications;
            return Object.assign({}, state, {
                notifications: mergeWith(clone(datoNotificationEliminado), state.notifications, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_NOTIFICATION:
            let datoNotificationCreado = action.notification.entities.notifications;
            return Object.assign({}, state, {
                notifications: mergeWith(clone(datoNotificationCreado), state.notifications, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_NOTIFICATIONS:
            let datosNotificationCreado = action.notifications.entities.notifications;
                return Object.assign({}, state, {
                    notifications: mergeWith(clone(datosNotificationCreado), state.notifications, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_NOTIFICATION:
            let datoNotificationActualizado = action.notification.entities.notifications;
            return Object.assign({}, state, {
                notifications: mergeWith(clone(datoNotificationActualizado), state.notifications, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_NOTIFICATIONS:
            let datosNotificationActualizado = action.notifications.entities.notifications;
                return Object.assign({}, state, {
                    notifications: mergeWith(clone(datosNotificationActualizado), state.notifications, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                notifications: {}
            });
        default:
            return state
    }
}


function allNotifications(state = [], action) {
    switch (action.type) {
        case RECEIVE_NOTIFICATIONS:
            return action.notifications.result && action.notifications.result.notifications ? union(state, action.notifications.result.notifications) : (action.notifications.result ? action.notifications.result : state) ;
        case RECEIVE_NOTIFICATION:
                return action.notification.result ? union(state, [action.notification.result]) : state;
        
        case SUCCESS_CREATE_NOTIFICATION:
                   let datoNotificationSCreate = action.notification.entities.notifications;
                   let idNuevoSCreate = null;
                   if (Object.values(datoNotificationSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoNotificationSCreate)[0] && Object.values(datoNotificationSCreate)[0].id ? Object.values(datoNotificationSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_NOTIFICATIONS:
                   let notificationsCreate = action.notifications.entities && action.notifications.entities.notifications ? action.notifications.entities.notifications : null;
                   return notificationsCreate ?
                       union(state, Object.values(notificationsCreate).map((notifications) => {
                           return notifications.id
                       })) : state;
        case RESET_NOTIFICATIONS:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalNotifications(state = null, action) {
    switch (action.type) {
        case RECEIVE_NOTIFICATIONS:
            return action.notifications && action.notifications.result.total ? action.notifications.result.total : 0;
        case RESET_NOTIFICATIONS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_NOTIFICATION:
            let dato = action.notification.entities.notifications;
            let notification = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: notification ? notification : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_NOTIFICATION:
            let idsUpdate = [];
            Object.values(action.notification).map((notificationUpdate) => {
                if (notificationUpdate && notificationUpdate.id)
                    idsUpdate.push(notificationUpdate.id);
            });
            return merge({}, state, {
                activo: action.notification,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_NOTIFICATION:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_NOTIFICATION:
            let datoNotificationActualizado = {};
            if (Object.values(action.notification.entities.notifications).length > 0)
                datoNotificationActualizado = Object.values(action.notification.entities.notifications)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoNotificationActualizado
            });
        case ERROR_UPDATE_NOTIFICATION:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_NOTIFICATIONS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_NOTIFICATIONS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_NOTIFICATIONS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_NOTIFICATION:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           

        case DELETE_NOTIFICATION:
            let datoNotificationDelete = action.notification;
            let idsDelete = [];
           Object.values(action.notification).map((notificationDelete) => {
               if (notificationDelete && notificationDelete.id)
                   idsDelete.push(notificationDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoNotificationDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_NOTIFICATION:
                       let datoNotificationDeleteUpdate = action.notification;
                       let idsDeleteUpdate = [];
                      Object.values(action.notification).map((notificationDelete) => {
                          if (notificationDelete && notificationDelete.id)
                              idsDeleteUpdate.push(notificationDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoNotificationDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_NOTIFICATION:
                    let datoNotificationDeleted = {};
                    if (Object.values(action.notification.entities.notifications).length > 0)
                        datoNotificationDeleted = Object.values(action.notification.entities.notifications)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoNotificationDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_NOTIFICATION:
             let idsCreate = [];
             Object.values(action.notification).map((notificationCreate) => {
                 if (notificationCreate && notificationCreate.id)
                     idsCreate.push(notificationCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.notification,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_NOTIFICATION:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_NOTIFICATION:
            let datoNotificationNuevo = {};
            if (Object.values(action.notification.entities.notifications).length > 0)
                datoNotificationNuevo = Object.values(action.notification.entities.notifications)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoNotificationNuevo,
                nuevos: []
            });
        case ERROR_CREATE_NOTIFICATION:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_NOTIFICATIONS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_NOTIFICATIONS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_NOTIFICATIONS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_NOTIFICATION:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             
        case DELETE_NOTIFICATION:
           let datoNotificationDelete = action.notification;
           let idsDelete = [];
           Object.values(action.notification).map((notificationDelete) => {
               if (notificationDelete && notificationDelete.id)
                   idsDelete.push(notificationDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoNotificationDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_NOTIFICATION:
                  let datoNotificationDeleteCreate = action.notification;
                  let idsDeleteCreate = [];
                  Object.values(action.notification).map((notificationDelete) => {
                      if (notificationDelete && notificationDelete.id)
                          idsDeleteCreate.push(notificationDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoNotificationDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_NOTIFICATION:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.notification,
                error: null,
            });
        case REQUEST_DELETE_NOTIFICATION:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_NOTIFICATION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_NOTIFICATION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_NOTIFICATION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_NOTIFICATION:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_NOTIFICATION:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_NOTIFICATION:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const notifications = combineReducers({
    byId: notificationsById,
    allIds: allNotifications,
    update: update,
    create: create,
    totalNotifications: totalNotifications,
    delete: deleter,
    print: print
});

export default notifications;