import {
    INVALIDATE_INVITATIONS,
    ERROR_INVITATIONS,
    RECEIVE_INVITATIONS,
    REQUEST_INVITATIONS,
    RESET_INVITATIONS,
    ERROR_INVITATION,
    RECEIVE_INVITATION,
    REQUEST_INVITATION,
    UPDATE_INVITATION,
    REQUEST_UPDATE_INVITATION,
    SUCCESS_UPDATE_INVITATION,
    ERROR_UPDATE_INVITATION,
    RESET_UPDATE_INVITATION,
    REQUEST_UPDATE_INVITATIONS,
    SUCCESS_UPDATE_INVITATIONS,
    ERROR_UPDATE_INVITATIONS,
    RESET_UPDATE_INVITATIONS,
    CREATE_INVITATION,
    ERROR_CREATE_INVITATION,
    REQUEST_CREATE_INVITATION,
    RESET_CREATE_INVITATION,
    SUCCESS_CREATE_INVITATION,
    REQUEST_CREATE_INVITATIONS,
    SUCCESS_CREATE_INVITATIONS,
    ERROR_CREATE_INVITATIONS,
    RESET_CREATE_INVITATIONS,
    DELETE_INVITATION,
    DELETE_CREATE_INVITATION,
    DELETE_UPDATE_INVITATION,
    REQUEST_DELETE_INVITATION,
    SUCCESS_DELETE_INVITATION,
    ERROR_DELETE_INVITATION,
    RESET_DELETE_INVITATION,
    REQUEST_PRINT_INVITATION,
    SUCCESS_PRINT_INVITATION,
    ERROR_PRINT_INVITATION,
    RESET_PRINT_INVITATION,
    RECEIVE_FILE_INVITATION
} from '../actions/InvitationActions';
import {
    CREATE_INVITATIONSCHEDULE,
    UPDATE_INVITATIONSCHEDULE,
    DELETE_INVITATIONSCHEDULE
} from '../actions/InvitationScheduleActions';
import {
    CREATE_INVITATIONSTATUS,
    UPDATE_INVITATIONSTATUS,
    DELETE_INVITATIONSTATUS
} from '../actions/InvitationStatusActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        invitations: {},
        files: {},
    }
}

function invitationsById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_INVITATIONS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_INVITATIONS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_INVITATIONS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_INVITATIONS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                invitations: {}
            });
        case RECEIVE_INVITATIONS:
            let dato = action.invitations.entities.invitations;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                invitations: merge({}, state.invitations, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_INVITATION:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_INVITATION:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_INVITATION:
            let datoInvitation = action.invitation.entities.invitations;
            return Object.assign({}, state, {
                invitations: merge({}, state.invitations, datoInvitation),
                isFetching: false,
            });
        case RECEIVE_FILE_INVITATION:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_INVITATION:
            let datoInvitationEliminado = action.invitation.entities.invitations;
            return Object.assign({}, state, {
                invitations: mergeWith(clone(datoInvitationEliminado), state.invitations, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_INVITATION:
            let datoInvitationCreado = action.invitation.entities.invitations;
            return Object.assign({}, state, {
                invitations: mergeWith(clone(datoInvitationCreado), state.invitations, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_INVITATIONS:
            let datosInvitationCreado = action.invitations.entities.invitations;
                return Object.assign({}, state, {
                    invitations: mergeWith(clone(datosInvitationCreado), state.invitations, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_INVITATION:
            let datoInvitationActualizado = action.invitation.entities.invitations;
            return Object.assign({}, state, {
                invitations: mergeWith(clone(datoInvitationActualizado), state.invitations, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_INVITATIONS:
            let datosInvitationActualizado = action.invitations.entities.invitations;
                return Object.assign({}, state, {
                    invitations: mergeWith(clone(datosInvitationActualizado), state.invitations, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                invitations: {}
            });
        default:
            return state
    }
}


function allInvitations(state = [], action) {
    switch (action.type) {
        case RECEIVE_INVITATIONS:
            return action.invitations.result && action.invitations.result.invitations ? union(state, action.invitations.result.invitations) : (action.invitations.result ? action.invitations.result : state) ;
        case RECEIVE_INVITATION:
                return action.invitation.result ? union(state, [action.invitation.result]) : state;
        
        case SUCCESS_CREATE_INVITATION:
                   let datoInvitationSCreate = action.invitation.entities.invitations;
                   let idNuevoSCreate = null;
                   if (Object.values(datoInvitationSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoInvitationSCreate)[0] && Object.values(datoInvitationSCreate)[0].id ? Object.values(datoInvitationSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_INVITATIONS:
                   let invitationsCreate = action.invitations.entities && action.invitations.entities.invitations ? action.invitations.entities.invitations : null;
                   return invitationsCreate ?
                       union(state, Object.values(invitationsCreate).map((invitations) => {
                           return invitations.id
                       })) : state;
        case RESET_INVITATIONS:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalInvitations(state = null, action) {
    switch (action.type) {
        case RECEIVE_INVITATIONS:
            return action.invitations && action.invitations.result.total ? action.invitations.result.total : 0;
        case RESET_INVITATIONS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_INVITATION:
            let dato = action.invitation.entities.invitations;
            let invitation = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: invitation ? invitation : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_INVITATION:
            let idsUpdate = [];
            Object.values(action.invitation).map((invitationUpdate) => {
                if (invitationUpdate && invitationUpdate.id)
                    idsUpdate.push(invitationUpdate.id);
            });
            return merge({}, state, {
                activo: action.invitation,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_INVITATION:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_INVITATION:
            let datoInvitationActualizado = {};
            if (Object.values(action.invitation.entities.invitations).length > 0)
                datoInvitationActualizado = Object.values(action.invitation.entities.invitations)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoInvitationActualizado
            });
        case ERROR_UPDATE_INVITATION:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_INVITATIONS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_INVITATIONS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_INVITATIONS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_INVITATION:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           case CREATE_INVITATIONSCHEDULE:
    let invitationinvitationScheduleCreateActivo = clone(state.activo);
    let invitationinvitationScheduleCreateActivos = clone(state.activos);
    Object.values(action.invitationSchedule).map((invitationScheduleCreate) => {
        if(invitationScheduleCreate && invitationScheduleCreate.idInvitation && invitationinvitationScheduleCreateActivo[invitationScheduleCreate.idInvitation]){
            if(invitationScheduleCreate.idInvitation.toString().indexOf("-") === -1)
                invitationinvitationScheduleCreateActivo[invitationScheduleCreate.idInvitation].invitation_schedule = union(invitationinvitationScheduleCreateActivo.invitation_schedule, [invitationScheduleCreate.id]);
        } else if (invitationScheduleCreate) {
            invitationinvitationScheduleCreateActivo.invitation_schedule = union(invitationinvitationScheduleCreateActivo.invitation_schedule ? invitationinvitationScheduleCreateActivo.invitation_schedule : [], [invitationScheduleCreate.id]);
        }
        if(invitationScheduleCreate && invitationScheduleCreate.idInvitation && invitationScheduleCreate.idInvitation.toString().indexOf("-") === -1)
            invitationinvitationScheduleCreateActivos = union(invitationinvitationScheduleCreateActivos, [invitationScheduleCreate.idInvitation]);
    });
    return Object.assign({}, state, {
        activo: invitationinvitationScheduleCreateActivo,
        activos: invitationinvitationScheduleCreateActivos
    });
case UPDATE_INVITATIONSCHEDULE:
    let invitationinvitationScheduleUpdateActivo = clone(state.activo);
        let invitationinvitationScheduleUpdateActivos = clone(state.activos);
        Object.values(action.invitationSchedule).map((invitationScheduleUpdate) => {
            if(invitationScheduleUpdate && invitationScheduleUpdate.idInvitation && invitationinvitationScheduleUpdateActivo[invitationScheduleUpdate.idInvitation]){
                if(invitationScheduleUpdate.idInvitation.toString().indexOf("-") === -1)
                    invitationinvitationScheduleUpdateActivo[invitationScheduleUpdate.idInvitation].invitation_schedule = union(invitationinvitationScheduleUpdateActivo.invitation_schedule, [invitationScheduleUpdate.id]);
            } else if (invitationScheduleUpdate) {
                invitationinvitationScheduleUpdateActivo.invitation_schedule = union(invitationinvitationScheduleUpdateActivo.invitation_schedule ? invitationinvitationScheduleUpdateActivo.invitation_schedule : [], [invitationScheduleUpdate.id]);
            }
            if(invitationScheduleUpdate && invitationScheduleUpdate.idInvitation && invitationScheduleUpdate.idInvitation.toString().indexOf("-") === -1)
                invitationinvitationScheduleUpdateActivos = union(invitationinvitationScheduleUpdateActivos, [invitationScheduleUpdate.idInvitation]);
        });
        return Object.assign({}, state, {
            activo: invitationinvitationScheduleUpdateActivo,
            activos: invitationinvitationScheduleUpdateActivos
        });
case DELETE_INVITATIONSCHEDULE:
    let invitationinvitationScheduleDeleteActivo = clone(state.activo);
    let invitationinvitationScheduleDeleteActivos = clone(state.activos);
    Object.values(action.invitationSchedule).map((invitationScheduleDelete) => {
        if(invitationScheduleDelete && invitationScheduleDelete.idInvitation && invitationinvitationScheduleDeleteActivo[invitationScheduleDelete.idInvitation]){
            if(invitationScheduleDelete.idInvitation.toString().indexOf("-") === -1)
                invitationinvitationScheduleDeleteActivo[invitationScheduleDelete.idInvitation].invitation_schedule = difference(invitationinvitationScheduleDeleteActivo.invitation_schedule, [invitationScheduleDelete.id]);
        } else if (invitationScheduleDelete) {
            invitationinvitationScheduleDeleteActivo.invitation_schedule = difference(invitationinvitationScheduleDeleteActivo.invitation_schedule ? invitationinvitationScheduleDeleteActivo.invitation_schedule : [], [invitationScheduleDelete.id]);
        }
        if(invitationScheduleDelete && invitationScheduleDelete.idInvitation && invitationScheduleDelete.idInvitation.toString().indexOf("-") === -1)
            invitationinvitationScheduleDeleteActivos = union(invitationinvitationScheduleDeleteActivos, [invitationScheduleDelete.idInvitation]);
    });
    return Object.assign({}, state, {
        activo: invitationinvitationScheduleDeleteActivo,
        activos: invitationinvitationScheduleDeleteActivos
    });case CREATE_INVITATIONSTATUS:
    let invitationinvitationStatusCreateActivo = clone(state.activo);
    let invitationinvitationStatusCreateActivos = clone(state.activos);
    Object.values(action.invitationStatus).map((invitationStatusCreate) => {
        if(invitationStatusCreate && invitationStatusCreate.idInvitation && invitationinvitationStatusCreateActivo[invitationStatusCreate.idInvitation]){
            if(invitationStatusCreate.idInvitation.toString().indexOf("-") === -1)
                invitationinvitationStatusCreateActivo[invitationStatusCreate.idInvitation].invitation_status = union(invitationinvitationStatusCreateActivo.invitation_status, [invitationStatusCreate.id]);
        } else if (invitationStatusCreate) {
            invitationinvitationStatusCreateActivo.invitation_status = union(invitationinvitationStatusCreateActivo.invitation_status ? invitationinvitationStatusCreateActivo.invitation_status : [], [invitationStatusCreate.id]);
        }
        if(invitationStatusCreate && invitationStatusCreate.idInvitation && invitationStatusCreate.idInvitation.toString().indexOf("-") === -1)
            invitationinvitationStatusCreateActivos = union(invitationinvitationStatusCreateActivos, [invitationStatusCreate.idInvitation]);
    });
    return Object.assign({}, state, {
        activo: invitationinvitationStatusCreateActivo,
        activos: invitationinvitationStatusCreateActivos
    });
case UPDATE_INVITATIONSTATUS:
    let invitationinvitationStatusUpdateActivo = clone(state.activo);
        let invitationinvitationStatusUpdateActivos = clone(state.activos);
        Object.values(action.invitationStatus).map((invitationStatusUpdate) => {
            if(invitationStatusUpdate && invitationStatusUpdate.idInvitation && invitationinvitationStatusUpdateActivo[invitationStatusUpdate.idInvitation]){
                if(invitationStatusUpdate.idInvitation.toString().indexOf("-") === -1)
                    invitationinvitationStatusUpdateActivo[invitationStatusUpdate.idInvitation].invitation_status = union(invitationinvitationStatusUpdateActivo.invitation_status, [invitationStatusUpdate.id]);
            } else if (invitationStatusUpdate) {
                invitationinvitationStatusUpdateActivo.invitation_status = union(invitationinvitationStatusUpdateActivo.invitation_status ? invitationinvitationStatusUpdateActivo.invitation_status : [], [invitationStatusUpdate.id]);
            }
            if(invitationStatusUpdate && invitationStatusUpdate.idInvitation && invitationStatusUpdate.idInvitation.toString().indexOf("-") === -1)
                invitationinvitationStatusUpdateActivos = union(invitationinvitationStatusUpdateActivos, [invitationStatusUpdate.idInvitation]);
        });
        return Object.assign({}, state, {
            activo: invitationinvitationStatusUpdateActivo,
            activos: invitationinvitationStatusUpdateActivos
        });
case DELETE_INVITATIONSTATUS:
    let invitationinvitationStatusDeleteActivo = clone(state.activo);
    let invitationinvitationStatusDeleteActivos = clone(state.activos);
    Object.values(action.invitationStatus).map((invitationStatusDelete) => {
        if(invitationStatusDelete && invitationStatusDelete.idInvitation && invitationinvitationStatusDeleteActivo[invitationStatusDelete.idInvitation]){
            if(invitationStatusDelete.idInvitation.toString().indexOf("-") === -1)
                invitationinvitationStatusDeleteActivo[invitationStatusDelete.idInvitation].invitation_status = difference(invitationinvitationStatusDeleteActivo.invitation_status, [invitationStatusDelete.id]);
        } else if (invitationStatusDelete) {
            invitationinvitationStatusDeleteActivo.invitation_status = difference(invitationinvitationStatusDeleteActivo.invitation_status ? invitationinvitationStatusDeleteActivo.invitation_status : [], [invitationStatusDelete.id]);
        }
        if(invitationStatusDelete && invitationStatusDelete.idInvitation && invitationStatusDelete.idInvitation.toString().indexOf("-") === -1)
            invitationinvitationStatusDeleteActivos = union(invitationinvitationStatusDeleteActivos, [invitationStatusDelete.idInvitation]);
    });
    return Object.assign({}, state, {
        activo: invitationinvitationStatusDeleteActivo,
        activos: invitationinvitationStatusDeleteActivos
    });

           

        case DELETE_INVITATION:
            let datoInvitationDelete = action.invitation;
            let idsDelete = [];
           Object.values(action.invitation).map((invitationDelete) => {
               if (invitationDelete && invitationDelete.id)
                   idsDelete.push(invitationDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoInvitationDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_INVITATION:
                       let datoInvitationDeleteUpdate = action.invitation;
                       let idsDeleteUpdate = [];
                      Object.values(action.invitation).map((invitationDelete) => {
                          if (invitationDelete && invitationDelete.id)
                              idsDeleteUpdate.push(invitationDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoInvitationDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_INVITATION:
                    let datoInvitationDeleted = {};
                    if (Object.values(action.invitation.entities.invitations).length > 0)
                        datoInvitationDeleted = Object.values(action.invitation.entities.invitations)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoInvitationDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_INVITATION:
             let idsCreate = [];
             Object.values(action.invitation).map((invitationCreate) => {
                 if (invitationCreate && invitationCreate.id)
                     idsCreate.push(invitationCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.invitation,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_INVITATION:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_INVITATION:
            let datoInvitationNuevo = {};
            if (Object.values(action.invitation.entities.invitations).length > 0)
                datoInvitationNuevo = Object.values(action.invitation.entities.invitations)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoInvitationNuevo,
                nuevos: []
            });
        case ERROR_CREATE_INVITATION:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_INVITATIONS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_INVITATIONS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_INVITATIONS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_INVITATION:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             //CREATE INVITATIONSCHEDULE
case CREATE_INVITATIONSCHEDULE:
    let invitationinvitationScheduleCreateActivo = clone(state.nuevo);
    Object.values(action.invitationSchedule).map((invitationScheduleCreate) => {
        if(invitationScheduleCreate && invitationScheduleCreate.idInvitation && invitationinvitationScheduleCreateActivo[invitationScheduleCreate.idInvitation]){
            if(invitationScheduleCreate.idInvitation.toString().indexOf("-") !== -1)
                invitationinvitationScheduleCreateActivo[invitationScheduleCreate.idInvitation].invitation_schedule = union(invitationinvitationScheduleCreateActivo.invitation_schedule, [invitationScheduleCreate.id]);
        } else if (invitationScheduleCreate) {
            invitationinvitationScheduleCreateActivo.invitation_schedule = union(invitationinvitationScheduleCreateActivo.invitation_schedule ? invitationinvitationScheduleCreateActivo.invitation_schedule : [], [invitationScheduleCreate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: invitationinvitationScheduleCreateActivo,
        //nuevos: invitationScheduleCreate && invitationScheduleCreate.idInvitation ? union(state.nuevos, [invitationScheduleCreate.idInvitation]) : state.nuevos,
    });
case UPDATE_INVITATIONSCHEDULE:
    let invitationinvitationScheduleUpdateActivo = clone(state.nuevo);
    Object.values(action.invitationSchedule).map((invitationScheduleUpdate) => {
        if(invitationScheduleUpdate && invitationScheduleUpdate.idInvitation && invitationinvitationScheduleUpdateActivo[invitationScheduleUpdate.idInvitation]){
            if(invitationScheduleUpdate.idInvitation.toString().indexOf("-") !== -1)
                invitationinvitationScheduleUpdateActivo[invitationScheduleUpdate.idInvitation].invitation_schedule = union(invitationinvitationScheduleUpdateActivo.invitation_schedule, [invitationScheduleUpdate.id]);
        } else if(invitationScheduleUpdate){
            invitationinvitationScheduleUpdateActivo.invitation_schedule = union(invitationinvitationScheduleUpdateActivo.invitation_schedule ? invitationinvitationScheduleUpdateActivo.invitation_schedule : [], [invitationScheduleUpdate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: invitationinvitationScheduleUpdateActivo,
        //nuevos: invitationScheduleUpdate && invitationScheduleUpdate.idInvitation ? union(state.nuevos, [invitationScheduleUpdate.idInvitation]) : state.nuevos,
    });
case DELETE_INVITATIONSCHEDULE:
    let invitationinvitationScheduleDeleteActivo = clone(state.nuevo);
    Object.values(action.invitationSchedule).map((invitationScheduleDelete) => {
        if(invitationScheduleDelete && invitationScheduleDelete.idInvitation && invitationScheduleDelete.idInvitation && invitationinvitationScheduleDeleteActivo[invitationScheduleDelete.idInvitation]){
            if(invitationScheduleDelete.idInvitation.toString().indexOf("-") !== -1)
                invitationinvitationScheduleDeleteActivo[invitationScheduleDelete.idInvitation].invitation_schedule = difference(invitationinvitationScheduleDeleteActivo.invitation_schedule, [invitationScheduleDelete.id]);
        } else if (invitationScheduleDelete) {
            invitationinvitationScheduleDeleteActivo.invitation_schedule = difference(invitationinvitationScheduleDeleteActivo.invitation_schedule ? invitationinvitationScheduleDeleteActivo.invitation_schedule : [], [invitationScheduleDelete.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: invitationinvitationScheduleDeleteActivo,
        //nuevos: invitationScheduleDelete && invitationScheduleDelete.idInvitation ? union(state.nuevos, [invitationScheduleDelete.idInvitation]) : state.nuevos,
    });//CREATE INVITATIONSTATUS
case CREATE_INVITATIONSTATUS:
    let invitationinvitationStatusCreateActivo = clone(state.nuevo);
    Object.values(action.invitationStatus).map((invitationStatusCreate) => {
        if(invitationStatusCreate && invitationStatusCreate.idInvitation && invitationinvitationStatusCreateActivo[invitationStatusCreate.idInvitation]){
            if(invitationStatusCreate.idInvitation.toString().indexOf("-") !== -1)
                invitationinvitationStatusCreateActivo[invitationStatusCreate.idInvitation].invitation_status = union(invitationinvitationStatusCreateActivo.invitation_status, [invitationStatusCreate.id]);
        } else if (invitationStatusCreate) {
            invitationinvitationStatusCreateActivo.invitation_status = union(invitationinvitationStatusCreateActivo.invitation_status ? invitationinvitationStatusCreateActivo.invitation_status : [], [invitationStatusCreate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: invitationinvitationStatusCreateActivo,
        //nuevos: invitationStatusCreate && invitationStatusCreate.idInvitation ? union(state.nuevos, [invitationStatusCreate.idInvitation]) : state.nuevos,
    });
case UPDATE_INVITATIONSTATUS:
    let invitationinvitationStatusUpdateActivo = clone(state.nuevo);
    Object.values(action.invitationStatus).map((invitationStatusUpdate) => {
        if(invitationStatusUpdate && invitationStatusUpdate.idInvitation && invitationinvitationStatusUpdateActivo[invitationStatusUpdate.idInvitation]){
            if(invitationStatusUpdate.idInvitation.toString().indexOf("-") !== -1)
                invitationinvitationStatusUpdateActivo[invitationStatusUpdate.idInvitation].invitation_status = union(invitationinvitationStatusUpdateActivo.invitation_status, [invitationStatusUpdate.id]);
        } else if(invitationStatusUpdate){
            invitationinvitationStatusUpdateActivo.invitation_status = union(invitationinvitationStatusUpdateActivo.invitation_status ? invitationinvitationStatusUpdateActivo.invitation_status : [], [invitationStatusUpdate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: invitationinvitationStatusUpdateActivo,
        //nuevos: invitationStatusUpdate && invitationStatusUpdate.idInvitation ? union(state.nuevos, [invitationStatusUpdate.idInvitation]) : state.nuevos,
    });
case DELETE_INVITATIONSTATUS:
    let invitationinvitationStatusDeleteActivo = clone(state.nuevo);
    Object.values(action.invitationStatus).map((invitationStatusDelete) => {
        if(invitationStatusDelete && invitationStatusDelete.idInvitation && invitationStatusDelete.idInvitation && invitationinvitationStatusDeleteActivo[invitationStatusDelete.idInvitation]){
            if(invitationStatusDelete.idInvitation.toString().indexOf("-") !== -1)
                invitationinvitationStatusDeleteActivo[invitationStatusDelete.idInvitation].invitation_status = difference(invitationinvitationStatusDeleteActivo.invitation_status, [invitationStatusDelete.id]);
        } else if (invitationStatusDelete) {
            invitationinvitationStatusDeleteActivo.invitation_status = difference(invitationinvitationStatusDeleteActivo.invitation_status ? invitationinvitationStatusDeleteActivo.invitation_status : [], [invitationStatusDelete.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: invitationinvitationStatusDeleteActivo,
        //nuevos: invitationStatusDelete && invitationStatusDelete.idInvitation ? union(state.nuevos, [invitationStatusDelete.idInvitation]) : state.nuevos,
    });

             
        case DELETE_INVITATION:
           let datoInvitationDelete = action.invitation;
           let idsDelete = [];
           Object.values(action.invitation).map((invitationDelete) => {
               if (invitationDelete && invitationDelete.id)
                   idsDelete.push(invitationDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoInvitationDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_INVITATION:
                  let datoInvitationDeleteCreate = action.invitation;
                  let idsDeleteCreate = [];
                  Object.values(action.invitation).map((invitationDelete) => {
                      if (invitationDelete && invitationDelete.id)
                          idsDeleteCreate.push(invitationDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoInvitationDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_INVITATION:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.invitation,
                error: null,
            });
        case REQUEST_DELETE_INVITATION:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_INVITATION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_INVITATION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_INVITATION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_INVITATION:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_INVITATION:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_INVITATION:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const invitations = combineReducers({
    byId: invitationsById,
    allIds: allInvitations,
    update: update,
    create: create,
    totalInvitations: totalInvitations,
    delete: deleter,
    print: print
});

export default invitations;