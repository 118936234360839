import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
//Components
import { Typography, IconButton, InputAdornment, CircularProgress } from "@material-ui/core";
import style from "../../assets/css/Forms.module.css";
import CustomTextField from "../../library/CustomTextField";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Navigation from "../../library/Navigation";
import SearchIcon from '@material-ui/icons/Search';
import { createUser, fetchUsers, resetCreateUser, resetUpdateUser, updateUser } from "../../actions/UserActions";
import { createInvitation, updateInvitation } from "../../actions/InvitationActions";
import { useValidador } from "../../utils/validador";

export default function Dni(props) {

    const dispatch = useDispatch();
    let {idInvitation} = useParams();
    const msjUsers = useRef();
    const msjUserFound = useRef();
    const mounted = useRef();
    const [hasSearched, setHasSearched] = useState(false);
    const [dni, setDni] = useState("");

    const {goToStep} = props;

    //Hooks
    let reglas = {};
    reglas = {
        dni: ["required"],

    };
    const [validador, validar] = useValidador(reglas);

    //store
    const invitations = useSelector(state => state.invitations);
    const users = useSelector(state => state.users);
    const invitation = idInvitation ? invitations.update.activo : invitations.create.nuevo;
    const idUser = invitation.idUser;
    let user = invitation && invitation.idUser && users.byId.users[invitation.idUser] ? users.byId.users[invitation.idUser] : {};
    if (users.update.activos.indexOf(invitation.idUser) !== -1) {
        user = users.update.activo[invitation.idUser];
    }
    if (users.create.nuevos.indexOf(invitation.idUser) !== -1) {
        user = users.create.nuevo[invitation.idUser];
    }


    //Effects
    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return function cleanup() {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            let rta = users.allIds.some((idUser) => {
                let searchUser = users.byId.users[idUser];
                if (searchUser && user && searchUser.dni === dni) {
                    //Update user
                    let cambio = {};
                    cambio[searchUser.id] = searchUser;
                    cambio.dni = dni;
                    dispatch(updateUser(cambio));
                    dispatch(resetCreateUser());
                    //Update invitation
                    let cambioInvitation = {};
                    cambioInvitation.idUser = idUser;
                    cambioInvitation.user = null;
                    changeInvitation(cambioInvitation);
                    return true;
                }
                return false;
            });
            if (rta === false) {
                msjUsers.current.style.display = "inline";
                msjUserFound.current.style.display = "none";
                //Create user
                let cambioUser = {};
                cambioUser[-1] = {dni: dni, id: -1};
                dispatch(createUser(cambioUser));
                //Update invitation
                let cambioInvitation = {};
                cambioInvitation.idUser = -1;
                cambioInvitation.user = -1;
                changeInvitation(cambioInvitation);
                dispatch(resetUpdateUser());
            } else {
                msjUserFound.current.style.display = "inline";
                msjUsers.current.style.display = "none";
            }
            setHasSearched(true);
        }
    }, [users.allIds]);

    const changeInvitation = (cambio) => {
        if (idInvitation)
            dispatch(updateInvitation(cambio));
        else
            dispatch(createInvitation(cambio));
    };

    const searchUser = () => {
        if (dni)
            dispatch(fetchUsers({dni: dni}));
    }

    const handleKeyDown = (e) => {
        console.log("enteeer", e.keyCode);
        let inputDni = document.getElementById("dni");
        if (e.keyCode === 13 && inputDni) {
            inputDni.blur();
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (validador.isValid())
            goToStep(idUser === -1 ? "datosPersonales" : "fechaHora");
    }

    return (
        <form onSubmit={onSubmit} className={style.containerForm}>
            {/*<div className={style.containerForm}>*/}
            <Typography variant={"h1"} component={"h1"} color={"primary"}>
                Ingrese su DNI o Pasaporte
            </Typography>
            <CustomTextField
                id="dni"
                label="DNI o Pasaporte"
                variant="outlined"
                fullWidth
                InputLabelProps={{shrink: true}}
                InputProps={{
                    startAdornment:
                        <AssignmentIndIcon color={"primary"}/>,
                    endAdornment:
                        <React.Fragment>
                            {users.byId.isFetching ?
                                <CircularProgress color="primary"
                                                  size={20}/> :
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Buscar por DNI"
                                    >
                                        <SearchIcon/>
                                    </IconButton>
                                </InputAdornment>}
                        </React.Fragment>,
                    autoComplete: "off"

                }}
                value={dni ? dni : ""}
                onChange={(e) => {
                    setDni(e.target.value);
                    setHasSearched(false);
                }}
                onBlur={searchUser}
                error={!validador.isValidById("dni")}
                helperText={validador.getHelperTextById("dni")}
            />
            <Typography variant={"subtitle2"} component={"p"} className={style.msg} ref={msjUserFound}
                        style={{display: "none"}}>
                {`Hola ${user ? user.name : ""} ya tenemos tus datos!`}
            </Typography>
            <Typography variant={"subtitle2"} component={"p"} className={style.msg} ref={msjUsers}
                        style={{display: "none"}}>
                A continuación le pediremos que complete sus datos para posibilitar el acceso a nuestras oficinas.
            </Typography>
            <Navigation next={idUser === -1 ? "datosPersonales" : "fechaHora"}
                        disabled={!user || !user.dni || !hasSearched || users.byId.isFetching} goToStep={goToStep}/>
            {/*</div>*/}
        </form>
    );
}

