import {normalize, schema, denormalize} from 'normalizr';
import user from "./normalizeUsers";
import invitationStatus from "./normalizeInvitationStatus";

const invitation = new schema.Entity('invitations', {user: user, invitation_status: [invitationStatus]}, {idAttribute: "id"});

export function normalizeDatos(myData) {

    const mySchema = {invitations: [invitation]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = invitation;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = invitation;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default invitation;
