import {normalize, schema, denormalize} from 'normalizr';

const card = new schema.Entity('cards', {}, {idAttribute: "id"});

export function normalizeDatos(myData) {

    const mySchema = {cards: [card]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = card;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = card;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default card;
