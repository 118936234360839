import {
    INVALIDATE_INVITATIONSSTATUS,
    ERROR_INVITATIONSSTATUS,
    RECEIVE_INVITATIONSSTATUS,
    REQUEST_INVITATIONSSTATUS,
    RESET_INVITATIONSSTATUS,
    ERROR_INVITATIONSTATUS,
    RECEIVE_INVITATIONSTATUS,
    REQUEST_INVITATIONSTATUS,
    UPDATE_INVITATIONSTATUS,
    REQUEST_UPDATE_INVITATIONSTATUS,
    SUCCESS_UPDATE_INVITATIONSTATUS,
    ERROR_UPDATE_INVITATIONSTATUS,
    RESET_UPDATE_INVITATIONSTATUS,
    REQUEST_UPDATE_INVITATIONSSTATUS,
    SUCCESS_UPDATE_INVITATIONSSTATUS,
    ERROR_UPDATE_INVITATIONSSTATUS,
    RESET_UPDATE_INVITATIONSSTATUS,
    CREATE_INVITATIONSTATUS,
    ERROR_CREATE_INVITATIONSTATUS,
    REQUEST_CREATE_INVITATIONSTATUS,
    RESET_CREATE_INVITATIONSTATUS,
    SUCCESS_CREATE_INVITATIONSTATUS,
    REQUEST_CREATE_INVITATIONSSTATUS,
    SUCCESS_CREATE_INVITATIONSSTATUS,
    ERROR_CREATE_INVITATIONSSTATUS,
    RESET_CREATE_INVITATIONSSTATUS,
    DELETE_INVITATIONSTATUS,
    DELETE_CREATE_INVITATIONSTATUS,
    DELETE_UPDATE_INVITATIONSTATUS,
    REQUEST_DELETE_INVITATIONSTATUS,
    SUCCESS_DELETE_INVITATIONSTATUS,
    ERROR_DELETE_INVITATIONSTATUS,
    RESET_DELETE_INVITATIONSTATUS,
    REQUEST_PRINT_INVITATIONSTATUS,
    SUCCESS_PRINT_INVITATIONSTATUS,
    ERROR_PRINT_INVITATIONSTATUS,
    RESET_PRINT_INVITATIONSTATUS,
    RECEIVE_FILE_INVITATIONSTATUS
} from '../actions/InvitationStatusActions';

import {
    RECEIVE_INVITATION,
    RECEIVE_INVITATIONS,
    SUCCESS_DELETE_INVITATION,
    SUCCESS_CREATE_INVITATION,
    SUCCESS_UPDATE_INVITATION,
    SUCCESS_UPDATE_INVITATIONS

} from '../actions/InvitationActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        invitationsStatus: {},
        files: {},
    }
}

function invitationsStatusById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_INVITATIONSSTATUS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_INVITATIONSSTATUS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_INVITATIONSSTATUS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_INVITATIONSSTATUS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                invitationsStatus: {}
            });
        case RECEIVE_INVITATIONSSTATUS:
            let dato = action.invitationsStatus.entities.invitationsStatus;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                invitationsStatus: merge({}, state.invitationsStatus, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_INVITATIONSTATUS:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_INVITATIONSTATUS:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_INVITATIONSTATUS:
            let datoInvitationStatus = action.invitationStatus.entities.invitationsStatus;
            return Object.assign({}, state, {
                invitationsStatus: merge({}, state.invitationsStatus, datoInvitationStatus),
                isFetching: false,
            });
        case RECEIVE_FILE_INVITATIONSTATUS:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });
        
        case SUCCESS_DELETE_INVITATIONSTATUS:
            let datoInvitationStatusEliminado = action.invitationStatus.entities.invitationsStatus;
            return Object.assign({}, state, {
                invitationsStatus: mergeWith(clone(datoInvitationStatusEliminado), state.invitationsStatus, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_INVITATIONSTATUS:
            let datoInvitationStatusCreado = action.invitationStatus.entities.invitationsStatus;
            return Object.assign({}, state, {
                invitationsStatus: mergeWith(clone(datoInvitationStatusCreado), state.invitationsStatus, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_INVITATIONSSTATUS:
            let datosInvitationStatusCreado = action.invitationsStatus.entities.invitationsStatus;
                return Object.assign({}, state, {
                    invitationsStatus: mergeWith(clone(datosInvitationStatusCreado), state.invitationsStatus, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_INVITATIONSTATUS:
            let datoInvitationStatusActualizado = action.invitationStatus.entities.invitationsStatus;
            return Object.assign({}, state, {
                invitationsStatus: mergeWith(clone(datoInvitationStatusActualizado), state.invitationsStatus, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_INVITATIONSSTATUS:
            let datosInvitationStatusActualizado = action.invitationsStatus.entities.invitationsStatus;
                return Object.assign({}, state, {
                    invitationsStatus: mergeWith(clone(datosInvitationStatusActualizado), state.invitationsStatus, (objValue, srcValue) => {return objValue;})
                });

            //INVITATION
case RECEIVE_INVITATION:
    let invitation = action.invitation.entities && action.invitation.entities.invitationsStatus ? action.invitation.entities.invitationsStatus : {};
    return Object.assign({}, state, {
        invitationsStatus: merge({}, state.invitationsStatus, invitation),
    });
case RECEIVE_INVITATIONS:
    let invitations = action.invitations.entities && action.invitations.entities.invitationsStatus ? action.invitations.entities.invitationsStatus : {};
    return Object.assign({}, state, {
        invitationsStatus: merge({}, state.invitationsStatus, invitations),
    });
case SUCCESS_DELETE_INVITATION:
    let datoinvitationEliminado = action.invitation.entities && action.invitation.entities.invitationsStatus ? action.invitation.entities.invitationsStatus : {};
    return Object.assign({}, state, {
        invitationsStatus: mergeWith(clone(datoinvitationEliminado), pickBy(state.invitationsStatus, function(invitationStatus) {return invitationStatus.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_INVITATION:
    let datoinvitationCreado = action.invitation.entities && action.invitation.entities.invitationsStatus ? action.invitation.entities.invitationsStatus : {};
    return Object.assign({}, state, {
        invitationsStatus: mergeWith(clone(datoinvitationCreado), pickBy(state.invitationsStatus, function(invitationStatus) {return invitationStatus.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_INVITATION:
    let datoinvitationActualizado = action.invitation.entities && action.invitation.entities.invitationsStatus ? action.invitation.entities.invitationsStatus : {};
    return Object.assign({}, state, {
        invitationsStatus: mergeWith(clone(datoinvitationActualizado), pickBy(state.invitationsStatus, function(invitationStatus) {return invitationStatus.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_INVITATIONS:
    let datosinvitationActualizado = action.invitations.entities && action.invitations.entities.invitationsStatus ? action.invitations.entities.invitationsStatus : {} ;
        return Object.assign({}, state, {
            invitationsStatus: mergeWith(clone(datosinvitationActualizado), state.invitationsStatus, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                invitationsStatus: {}
            });
        default:
            return state
    }
}


function allInvitationsStatus(state = [], action) {
    switch (action.type) {
        case RECEIVE_INVITATIONSSTATUS:
            return action.invitationsStatus.result && action.invitationsStatus.result.invitationsStatus ? union(state, action.invitationsStatus.result.invitationsStatus) : (action.invitationsStatus.result ? action.invitationsStatus.result : state) ;
        case RECEIVE_INVITATIONSTATUS:
                return action.invitationStatus.result ? union(state, [action.invitationStatus.result]) : state;
        
        case SUCCESS_CREATE_INVITATIONSTATUS:
                   let datoInvitationStatusSCreate = action.invitationStatus.entities.invitationsStatus;
                   let idNuevoSCreate = null;
                   if (Object.values(datoInvitationStatusSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoInvitationStatusSCreate)[0] && Object.values(datoInvitationStatusSCreate)[0].id ? Object.values(datoInvitationStatusSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_INVITATIONSSTATUS:
                   let invitationsStatusCreate = action.invitationsStatus.entities && action.invitationsStatus.entities.invitationsStatus ? action.invitationsStatus.entities.invitationsStatus : null;
                   return invitationsStatusCreate ?
                       union(state, Object.values(invitationsStatusCreate).map((invitationsStatus) => {
                           return invitationsStatus.id
                       })) : state;
        case RESET_INVITATIONSSTATUS:
            return [];

            case RECEIVE_INVITATION:
    let invitation = action.invitation.entities && action.invitation.entities.invitationsStatus ? action.invitation.entities.invitationsStatus : null ;
    return invitation ?
        union(state, Object.values(invitation).map((invitation) => {
            return invitation.id
        })) : state;
case RECEIVE_INVITATIONS:
    let invitations = action.invitations.entities && action.invitations.entities.invitationsStatus ? action.invitations.entities.invitationsStatus : null;
    return invitations ?
        union(state, Object.values(invitations).map((invitations) => {
            return invitations.id
        })) : state;

case SUCCESS_DELETE_INVITATION:
    let invitationDelete = action.invitation.entities && action.invitation.entities.invitationsStatus ? action.invitation.entities.invitationsStatus : null ;
        return invitationDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(invitationDelete).map((invitation) => {
                return invitation.id
            })) : state;
case SUCCESS_CREATE_INVITATION:
   let invitationCreate = action.invitation.entities && action.invitation.entities.invitationsStatus ? action.invitation.entities.invitationsStatus : null ;
       return invitationCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(invitationCreate).map((invitation) => {
               return invitation.id
           })) : state;
case SUCCESS_UPDATE_INVITATION:
    let invitationUpdate = action.invitation.entities && action.invitation.entities.invitationsStatus ? action.invitation.entities.invitationsStatus : null ;
        return invitationUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(invitationUpdate).map((invitation) => {
                return invitation.id
            })) : state;
 case SUCCESS_UPDATE_INVITATIONS:
     let invitationsUpdate = action.invitations.entities && action.invitations.entities.invitationsStatus ? action.invitations.entities.invitationsStatus : null;
        return invitationsUpdate ?
            union(state, Object.values(invitationsUpdate).map((invitations) => {
                return invitations.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalInvitationsStatus(state = null, action) {
    switch (action.type) {
        case RECEIVE_INVITATIONSSTATUS:
            return action.invitationsStatus && action.invitationsStatus.result.total ? action.invitationsStatus.result.total : 0;
        case RESET_INVITATIONSSTATUS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_INVITATIONSTATUS:
            let dato = action.invitationStatus.entities.invitationsStatus;
            let invitationStatus = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: invitationStatus ? invitationStatus : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_INVITATIONSTATUS:
            let idsUpdate = [];
            Object.values(action.invitationStatus).map((invitationStatusUpdate) => {
                if (invitationStatusUpdate && invitationStatusUpdate.id)
                    idsUpdate.push(invitationStatusUpdate.id);
            });
            return merge({}, state, {
                activo: action.invitationStatus,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_INVITATIONSTATUS:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_INVITATIONSTATUS:
            let datoInvitationStatusActualizado = {};
            if (Object.values(action.invitationStatus.entities.invitationsStatus).length > 0)
                datoInvitationStatusActualizado = Object.values(action.invitationStatus.entities.invitationsStatus)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoInvitationStatusActualizado
            });
        case ERROR_UPDATE_INVITATIONSTATUS:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_INVITATIONSSTATUS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_INVITATIONSSTATUS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_INVITATIONSSTATUS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_INVITATIONSTATUS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           //INVITATION
//TODO ver si esta bien
 case SUCCESS_CREATE_INVITATION:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_INVITATION:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_DELETE_INVITATION:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });
 case SUCCESS_UPDATE_INVITATIONS:
        return Object.assign({}, state, {
            activo: state.activo,
            activos: state.activos
        });

        case DELETE_INVITATIONSTATUS:
            let datoInvitationStatusDelete = action.invitationStatus;
            let idsDelete = [];
           Object.values(action.invitationStatus).map((invitationStatusDelete) => {
               if (invitationStatusDelete && invitationStatusDelete.id)
                   idsDelete.push(invitationStatusDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoInvitationStatusDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_INVITATIONSTATUS:
                       let datoInvitationStatusDeleteUpdate = action.invitationStatus;
                       let idsDeleteUpdate = [];
                      Object.values(action.invitationStatus).map((invitationStatusDelete) => {
                          if (invitationStatusDelete && invitationStatusDelete.id)
                              idsDeleteUpdate.push(invitationStatusDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoInvitationStatusDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_INVITATIONSTATUS:
                    let datoInvitationStatusDeleted = {};
                    if (Object.values(action.invitationStatus.entities.invitationsStatus).length > 0)
                        datoInvitationStatusDeleted = Object.values(action.invitationStatus.entities.invitationsStatus)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoInvitationStatusDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_INVITATIONSTATUS:
             let idsCreate = [];
             Object.values(action.invitationStatus).map((invitationStatusCreate) => {
                 if (invitationStatusCreate && invitationStatusCreate.id)
                     idsCreate.push(invitationStatusCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.invitationStatus,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_INVITATIONSTATUS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_INVITATIONSTATUS:
            let datoInvitationStatusNuevo = {};
            if (Object.values(action.invitationStatus.entities.invitationsStatus).length > 0)
                datoInvitationStatusNuevo = Object.values(action.invitationStatus.entities.invitationsStatus)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoInvitationStatusNuevo,
                nuevos: []
            });
        case ERROR_CREATE_INVITATIONSTATUS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_INVITATIONSSTATUS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_INVITATIONSSTATUS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_INVITATIONSSTATUS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_INVITATIONSTATUS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             //INVITATION
 case SUCCESS_CREATE_INVITATION:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_INVITATION:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_INVITATION:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_INVITATIONS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_INVITATIONSTATUS:
           let datoInvitationStatusDelete = action.invitationStatus;
           let idsDelete = [];
           Object.values(action.invitationStatus).map((invitationStatusDelete) => {
               if (invitationStatusDelete && invitationStatusDelete.id)
                   idsDelete.push(invitationStatusDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoInvitationStatusDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_INVITATIONSTATUS:
                  let datoInvitationStatusDeleteCreate = action.invitationStatus;
                  let idsDeleteCreate = [];
                  Object.values(action.invitationStatus).map((invitationStatusDelete) => {
                      if (invitationStatusDelete && invitationStatusDelete.id)
                          idsDeleteCreate.push(invitationStatusDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoInvitationStatusDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_INVITATIONSTATUS:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.invitationStatus,
                error: null,
            });
        case REQUEST_DELETE_INVITATIONSTATUS:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_INVITATIONSTATUS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_INVITATIONSTATUS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_INVITATIONSTATUS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //INVITATION
 case SUCCESS_CREATE_INVITATION:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_INVITATION:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_INVITATION:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_INVITATIONS:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_INVITATIONSTATUS:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_INVITATIONSTATUS:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_INVITATIONSTATUS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const invitationsStatus = combineReducers({
    byId: invitationsStatusById,
    allIds: allInvitationsStatus,
    update: update,
    create: create,
    totalInvitationsStatus: totalInvitationsStatus,
    delete: deleter,
    print: print
});

export default invitationsStatus;