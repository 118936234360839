//api
import eventsApi from "../api/eventsApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeEvents";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//EVENTS
export const REQUEST_EVENTS = 'REQUEST_EVENTS';
export const RECEIVE_EVENTS = 'RECEIVE_EVENTS';
export const INVALIDATE_EVENTS = 'INVALIDATE_EVENTS';
export const ERROR_EVENTS = "ERROR_EVENTS";
export const RESET_EVENTS = "RESET_EVENTS";


export function invalidateEvents() {
    return {
        type: INVALIDATE_EVENTS
    }
}

function requestEvents() {
    return {
        type: REQUEST_EVENTS,
    }
}

function receiveEvents(json) {
    return {
        type: RECEIVE_EVENTS,
    events: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorEvents(error) {
    return {
        type: ERROR_EVENTS,
        error: error,
    }
}

export function resetEvents() {
    return {
        type: RESET_EVENTS
    }
}

export function fetchEvents(filtros) {
    return dispatch => {
        dispatch(requestEvents());
        return eventsApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveEvents(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorEvents(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorEvents(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchEvents(state) {
    const events = state.events.byId;
    if (!events) {
        return true
    } else if (events.isFetching) {
        return false
    } else {
        return events.didInvalidate;
    }
}

export function fetchEventsIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchEvents(getState())) {
            return dispatch(fetchEvents(filtros))
        }
    }
}


//MODEL
export const REQUEST_EVENT = 'REQUEST_EVENT';
export const RECEIVE_EVENT = 'RECEIVE_EVENT';
export const INVALIDATE_EVENT = 'INVALIDATE_EVENT';
export const ERROR_EVENT = "ERROR_EVENT";
export const RESET_EVENT = "RESET_EVENT";


export function invalidateEvent() {
    return {
        type: INVALIDATE_EVENT
    }
}

function requestEvent() {
    return {
        type: REQUEST_EVENT,
    }
}

export function receiveEvent(json) {
    return {
        type: RECEIVE_EVENT,
    event: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorEvent(error) {
    return {
        type: ERROR_EVENT,
        error: error,
    }
}

export function fetchEvent(idEvent) {
    return dispatch => {
        dispatch(requestEvent());
        return eventsApi.getOne(idEvent)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveEvent(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorEvent(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorEvent(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_EVENT = 'RECEIVE_FILE_EVENT';

function receiveFileEvent(file) {
    return {
        type: RECEIVE_FILE_EVENT,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileEvent(idEvent, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return eventsApi.getFile(idEvent, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileEvent(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorEvent(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorEvent(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const REQUEST_UPDATE_EVENT = "REQUEST_UPDATE_EVENT";
export const SUCCESS_UPDATE_EVENT = "SUCCESS_UPDATE_EVENT";
export const ERROR_UPDATE_EVENT = "ERROR_UPDATE_EVENT";
export const RESET_UPDATE_EVENT = "RESET_UPDATE_EVENT";
export const DELETE_UPDATE_EVENT = "DELETE_UPDATE_EVENT";

function requestUpdateEvent() {
    return {
        type: REQUEST_UPDATE_EVENT,
    }
}

function receiveUpdateEvent(event) {
    return {
        type: SUCCESS_UPDATE_EVENT,
        receivedAt: Date.now(),
        event: normalizeDato(event)
    }
}

function errorUpdateEvent(error) {
    return {
        type: ERROR_UPDATE_EVENT,
        error: error,
    }
}

export function resetUpdateEvent() {
    return {
        type: RESET_UPDATE_EVENT,
    }
}

export function updateEvent(event) {
    return {
        type: UPDATE_EVENT,
        event
    }
}

export function saveUpdateEvent() {
    return (dispatch, getState) => {
        dispatch(requestUpdateEvent());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let event = denormalizeDato(getState().events.update.activo, store);

        return eventsApi.saveUpdate(event)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateEvent(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateEvent(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                try{
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateEvents(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateEvents(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateEvent(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateEvent(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateEvent(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                                    } catch{
                                            dispatch(errorUpdateEvent(errorMessages.GENERAL_ERROR));
                                        };
                            }
                        });
    }
}

export function deleteUpdateEvent(event) {
    return {
        type: DELETE_UPDATE_EVENT,
        event
    }
}

//UPDATE EVENTS
export const REQUEST_UPDATE_EVENTS = "REQUEST_UPDATE_EVENTS";
export const SUCCESS_UPDATE_EVENTS = "SUCCESS_UPDATE_EVENTS";
export const ERROR_UPDATE_EVENTS = "ERROR_UPDATE_EVENTS";
export const RESET_UPDATE_EVENTS = "RESET_UPDATE_EVENTS";

function requestUpdateEvents() {
    return {
        type: REQUEST_UPDATE_EVENTS,
}
}

function receiveUpdateEvents(events) {
    return {
        type: SUCCESS_UPDATE_EVENTS,
    receivedAt: Date.now(),
        events: normalizeDatos(events)
}
}

function errorUpdateEvents(error) {
    return {
        type: ERROR_UPDATE_EVENTS,
    error: error,
}
}

export function resetUpdateEvents() {
    return {
        type: RESET_UPDATE_EVENTS,
}
}

export function saveUpdateEvents() {
    return (dispatch, getState) => {
        dispatch(requestUpdateEvents());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let events = getState().events.update.activos.map((idEvent) => {
            return denormalizeDato(getState().events.update.activo[idEvent], store);
        });

        return eventsApi.saveUpdateEvents(events)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateEvents(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateEvents(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateEvents(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateEvents(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateEvents(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateEvents(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA EVENT
export const CREATE_EVENT = 'CREATE_EVENT';
export const REQUEST_CREATE_EVENT = "REQUEST_CREATE_EVENT";
export const SUCCESS_CREATE_EVENT = "SUCCESS_CREATE_EVENT";
export const ERROR_CREATE_EVENT = "ERROR_CREATE_EVENT";
export const RESET_CREATE_EVENT = "RESET_CREATE_EVENT";
export const DELETE_CREATE_EVENT = "DELETE_CREATE_EVENT";


//ALTA EVENT
function requestCreateEvent() {
    return {
        type: REQUEST_CREATE_EVENT,
    }
}

function receiveCreateEvent(event) {
    return {
        type: SUCCESS_CREATE_EVENT,
        receivedAt: Date.now(),
        event: normalizeDato(event)
    }
}

export function errorCreateEvent(error) {
    return {
        type: ERROR_CREATE_EVENT,
        error: error,
    }
}

export function resetCreateEvent() {
    return {
        type: RESET_CREATE_EVENT
    }
}

export function createEvent(event) {
    return {
        type: CREATE_EVENT,
        event
    }
}

export function deleteCreateEvent(event) {
    return {
        type: DELETE_CREATE_EVENT,
        event
    }
}

export function saveCreateEvent() {
    return (dispatch, getState) => {
        dispatch(requestCreateEvent());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let event = denormalizeDato(getState().events.create.nuevo, store);

        return eventsApi.saveCreate(event)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateEvent(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateEvent(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                try{
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateEvents(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateEvents(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateEvent(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateEvent(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateEvent(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                                    }catch {
                                        dispatch(errorCreateEvent(errorMessages.GENERAL_ERROR));
                                    };
                            }
                        });
    }
}

//CREATE EVENTS
export const REQUEST_CREATE_EVENTS = "REQUEST_CREATE_EVENTS";
export const SUCCESS_CREATE_EVENTS = "SUCCESS_CREATE_EVENTS";
export const ERROR_CREATE_EVENTS = "ERROR_CREATE_EVENTS";
export const RESET_CREATE_EVENTS = "RESET_CREATE_EVENTS";

function requestCreateEvents() {
    return {
        type: REQUEST_CREATE_EVENTS,
}
}

function receiveCreateEvents(events) {
    return {
        type: SUCCESS_CREATE_EVENTS,
    receivedAt: Date.now(),
        events: normalizeDatos(events)
}
}

function errorCreateEvents(error) {
    return {
        type: ERROR_CREATE_EVENTS,
    error: error,
}
}

export function resetCreateEvents() {
    return {
        type: RESET_CREATE_EVENTS,
}
}

export function saveCreateEvents() {
    return (dispatch, getState) => {
        dispatch(requestCreateEvents());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let events = getState().events.create.nuevos.map((idEvent) => {
            return denormalizeDato(getState().events.create.nuevo[idEvent], store);
        });

        return eventsApi.saveCreateEvents(events)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateEvents(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateEvents(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateEvents(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateEvents(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateEvents(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateEvents(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE EVENT
export const DELETE_EVENT = 'DELETE_EVENT';
export const REQUEST_DELETE_EVENT = "REQUEST_DELETE_EVENT";
export const SUCCESS_DELETE_EVENT = "SUCCESS_DELETE_EVENT";
export const ERROR_DELETE_EVENT = "ERROR_DELETE_EVENT";
export const RESET_DELETE_EVENT = "RESET_DELETE_EVENT";

function requestDeleteEvent() {
    return {
        type: REQUEST_DELETE_EVENT,
    }
}

function receiveDeleteEvent(event) {
    return {
        type: SUCCESS_DELETE_EVENT,
        receivedAt: Date.now(),
        event: normalizeDato(event)
    }
}

function errorDeleteEvent(error) {
    return {
        type: ERROR_DELETE_EVENT,
        error: error,
    }
}

export function resetDeleteEvent(error) {
    return {
        type: RESET_DELETE_EVENT,
        error: error,
    }
}

export function deleteEvent(event) {
    return {
        type: DELETE_EVENT,
        event
    }
}

export function saveDeleteEvent(event) {
    return dispatch => {
        dispatch(requestDeleteEvent());
        return eventsApi.saveDelete(event)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteEvent());
                            dispatch(receiveDeleteEvent(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteEvent(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteEvent(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteEvent(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteEvent(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteEvent(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT EVENT
export const REQUEST_PRINT_EVENT = "REQUEST_PRINT_EVENT";
export const SUCCESS_PRINT_EVENT = "SUCCESS_PRINT_EVENT";
export const ERROR_PRINT_EVENT = "ERROR_PRINT_EVENT";
export const RESET_PRINT_EVENT = "RESET_PRINT_EVENT";

function requestPrintEvent() {
    return {
        type: REQUEST_PRINT_EVENT,
    }
}

function receivePrintEvent(turnos) {
    return {
        type: SUCCESS_PRINT_EVENT,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintEvent(error) {
    return {
        type: ERROR_PRINT_EVENT,
        error: error,
    }
}

export function resetPrintEvent() {
    return {
        type: RESET_PRINT_EVENT,
    }
}

export function printEvent(idEvent) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintEvent());
        return eventsApi.printEvent(idEvent)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintEvent(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintEvent(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintEvent(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintEvent(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintEvent(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}