import React, {useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import style from "../../assets/css/Forms.module.css";
import {Button, CircularProgress, Typography, useTheme} from "@material-ui/core";
import hexToRGBA from "../../library/hexToRGBA";
import {useHistory} from "react-router";
import moment from "moment";
import {saveCreateInvitation, saveUpdateInvitation} from "../../actions/InvitationActions";
import FormHelperText from "@material-ui/core/FormHelperText";

//Components

export default function Confirmation(props) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const history = useHistory();
    const {goToStep, prevStep, files} = props;
    const mounted = useRef();

    let {idInvitation} = useParams();

    const invitations = useSelector(state => state.invitations);
    const users = useSelector(state => state.users);
    const invitation = idInvitation ? invitations.update.activo : invitations.create.nuevo;
    let user = invitation && invitation.idUser && users.byId.users[invitation.idUser] ? users.byId.users[invitation.idUser] : {};
    if (users.update.activos.indexOf(invitation.idUser) !== -1) {
        user = users.update.activo[invitation.idUser];
    }
    if (users.create.nuevos.indexOf(invitation.idUser) !== -1) {
        user = users.create.nuevo[invitation.idUser];
    }
    //Effects
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (!invitations.create.isCreating && !invitations.create.error && !invitations.update.isUpdating && !invitations.update.error) {
                goToStep("registroExitoso");
            }
        }
    }, [invitations.create.isCreating, invitations.update.isUpdating]);

    const onSubmit = (e) => {
        e.preventDefault();
        if (idInvitation)
            dispatch(saveUpdateInvitation(files));
        else
            dispatch(saveCreateInvitation(files));
    };

    let error = idInvitation ? invitations.update.error : invitations.create.error;

    let Errores = "";
    if (error != null && Object.keys(error).length > 0 && typeof error == "object") {
        Errores = Object.keys(error).map((nombre, index) => {
            let mensajeCompleto = "";
            if (error[nombre])
                error[nombre].forEach((mensaje) => {
                    mensajeCompleto += mensaje;
                });
            return <p key={nombre} style={{margin: "0px"}}>{mensajeCompleto}</p>;
        });
    } else
        Errores = <p key={0} style={{margin: "0px"}}>{error}</p>;

    return (
        <form className={style.containerForm} onSubmit={onSubmit}>
            <Typography variant={"h1"} component={"h1"} color={"primary"}>
                Confirma tus datos
            </Typography>
            <ul className={style.listConfirmation}>
                <li>
                    <Typography component={"a"} variant={"subtitle1"}>
                        Nombre:&nbsp;
                    </Typography>
                    <Typography component={"b"} variant={"subtitle1"}>
                        {user ? user.name : ""}
                    </Typography>
                </li>
                <li>
                    <Typography component={"a"} variant={"subtitle1"}>
                        Apellido:&nbsp;
                    </Typography>
                    <Typography component={"b"} variant={"subtitle1"}>
                        {user ? user.lastname : ""}
                    </Typography>
                </li>
                <li>
                    <Typography component={"a"} variant={"subtitle1"}>
                        Telefono:&nbsp;
                    </Typography>
                    <Typography component={"b"} variant={"subtitle1"}>
                        {user ? user.mobilePhone : ""}
                    </Typography>
                </li>
                <li>
                    <Typography component={"a"} variant={"subtitle1"}>
                        Email:&nbsp;
                    </Typography>
                    <Typography component={"b"} variant={"subtitle1"}>
                        {user ? user.email : ""}
                    </Typography>
                </li>
                <li>
                    <Typography component={"a"} variant={"subtitle1"}>
                        Fecha:&nbsp;
                    </Typography>
                    <Typography component={"b"} variant={"subtitle1"}>
                        {invitation && invitation.dateFrom ? moment(invitation.dateFrom).format("dddd DD [de] MMMM [de] YYYY") : ""}
                    </Typography>
                </li>
                {
                    !moment(invitation.dateFrom).isSame(moment(invitation.dateTo)) &&
                    <li>
                        <Typography component={"a"} variant={"subtitle1"}>
                            Fecha hasta:&nbsp;
                        </Typography>
                        <Typography component={"b"} variant={"subtitle1"}>
                            {invitation && invitation.dateTo ? moment(invitation.dateTo).format("dddd DD [de] MMMM [de] YYYY") : ""}
                        </Typography>
                    </li>
                }
                <li>
                    <Typography component={"a"} variant={"subtitle1"}>
                        Horario:&nbsp;
                    </Typography>
                    <Typography component={"b"} variant={"subtitle1"}>
                        {`${invitation && invitation.timeFrom ? moment(invitation.timeFrom, "HH:mm:ss").format("HH:mm") : ""} a ${invitation && invitation.timeTo ? moment(invitation.timeTo, "HH:mm:ss").format("HH:mm") : ""}`}
                    </Typography>
                </li>
            </ul>
            <FormHelperText style={{color: "red"}}>
                {Errores}
            </FormHelperText>
            <div className={style.containerBtnConfirmation}>
                <Button size={"small"} onClick={() => goToStep("dni")}
                        disabled={invitations.update.isUpdating || invitations.create.isCreating}>
                    Modificar
                </Button>
                <div className={style.wrapper}>
                    <Button variant={"contained"}
                            color={"primary"}
                            className={style.btnConfirmation}
                            size={"large"}
                            type={"submit"}
                            disabled={invitations.update.isUpdating || invitations.create.isCreating}
                            style={{boxShadow: `${hexToRGBA(theme.palette.primary["main"], .5)} 0px 5px 16px 0px`}}
                    >
                        Confirmar
                    </Button>
                    {(invitations.update.isUpdating || invitations.create.isCreating) &&
                    <CircularProgress size={24} className={style.buttonProgress}/>}
                </div>
            </div>
        </form>
    );
}

