import React, {useEffect, useRef, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {Link, useParams} from 'react-router-dom';
//Components
import {
    Box,
    Breadcrumbs, Card, CardContent, CardHeader,
    Chip,
    CircularProgress,
    Container,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core';
import CustomPaper from "../../library/CustomPaper/CustomPaper";
import PaperHeader from "../../library/CustomPaper/PaperHeader";
import ContainerBtn from "../../library/Buttons/ContainerBtn";
import BtnSave from "../../library/Buttons/BtnSave";
import BtnCancel from "../../library/Buttons/BtnCancel";
import Grid from "@material-ui/core/Grid";
import PaperBody from "../../library/CustomPaper/PaperBody";
//Img
import DoneIcon from '@material-ui/icons/Done';
//Hooks
import {useValidador} from "../../utils/validador";
import {useDispatch, useSelector} from "react-redux";
//Actions
import {
    createUser,
    fetchUsers,
    invalidateUsers,
    resetCreateUser,
    resetUsers,
    updateUser
} from "../../actions/UserActions";
//reset
import userReset from "../../reset/userReset";
import {useHistory} from "react-router";
import {fetchRolesIfNeeded} from "../../actions/RoleActions";
import FacePhoto from "../Users/FacePhoto";
import {Autocomplete} from "@material-ui/lab";
import Avatar from "@material-ui/core/Avatar";
import {
    createInvitation,
    fetchInvitation, resetCreateInvitation,
    saveCreateInvitation,
    saveUpdateInvitation,
    updateInvitation
} from "../../actions/InvitationActions";
import style from "../../assets/css/Invitation/CRUDInvitation.module.css";
import moment from "moment";
import 'moment/locale/es';
import Button from "@material-ui/core/Button";
import {KeyboardDatePicker, MuiPickersUtilsProvider, KeyboardTimePicker} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import * as PropTypes from "prop-types";
import clone from "lodash/clone";
import invitationReset from "../../reset/invitationReset";
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import clsx from "clsx";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FormHelperText from "@material-ui/core/FormHelperText";
import {createFacePhoto, updateFacePhoto} from "../../actions/FacePhotoActions";

import AddIcon from '@material-ui/icons/Add';
import c from "../../constants/Constants";

const useStyles = makeStyles(theme => ({
    cardContainer: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        padding: theme.spacing(2),
        overflowX: "auto",
        [theme.breakpoints.down('xs')]: {
            justifyContent: "flex-start",
            flexWrap: "nowrap",
        },
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const CustomItemsContainer = withStyles((theme) => ({
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        overflowX: "auto",
        flexWrap: "nowrap",
        padding: "20px",
        [theme.breakpoints.down('xs')]: {
            padding: "4px",
            paddingLeft: "18px",
        },
        gap: "10px",
        maskImage: "linear-gradient(to right, transparent, white 10px, white 96%, transparent)",
        "&::-webkit-scrollbar": {
            height: "6px",
            [theme.breakpoints.down('xs')]: {
                display: "none"
            },
        },

        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 1px rgba(0, 0, 0, 0.4)",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            borderTop: "2px solid #fff",
            borderBottom: "2px solid #fff",

        },

        "&::-webkit-scrollbar-thumb": {
            boxShadow: "inset 0 0 " + theme.palette.primary["main"],
            backgroundColor: theme.palette.primary["main"]

        }
    },
}))(RadioGroup);

const ItemBox = withStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#fff",
        boxShadow: "1px 1px 8px rgba(0,0,0,0.1)",
        borderRadius: "8px",
        minWidth: "140px",
        padding: "23px",
        color: theme.palette.primary["main"],
        position: "relative",
        textAlign: "center",
        "& h4": {
            fontWeight: 900
        },
        "& h5": {
            fontWeight: 900
        }
    },
}))(FormControlLabel);

const RadioCustom = withStyles((theme) => ({
    root: {
        position: "absolute",
        right: 0,
        top: 0
    },
}))(Radio);
moment.locale("es");

const schedule = [{timeFrom: "08:00:00", timeTo: "12:00:00", name: "De mañana"},
    {timeFrom: "11:00:00", timeTo: "14:00:00", name: "Al mediodía"},
    {timeFrom: "14:00:00", timeTo: "17:00:00", name: "De tarde"}, {timeFrom: "08:00:00", timeTo: "17:00:00", name: "Durante el día"}];


export default function CRUDInvitation(props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    let {idInvitation, idSection} = useParams();
    const mounted = useRef();
    const history = useHistory();
    const msgDate = useRef();
    const msgUser = useRef();

    //Store
    const invitations = useSelector(state => state.invitations);
    const users = useSelector(state => state.users);
    const facePhotos = useSelector(state => state.facePhotos);
    const auth = useSelector(state => state.auth);
    const invitation = idInvitation ? invitations.update.activo : invitations.create.nuevo;
    let user = invitation && invitation.idUser && users.byId.users[invitation.idUser] ? users.byId.users[invitation.idUser] : {};
    if (users.update.activos.indexOf(invitation.idUser) !== -1) {
        user = users.update.activo[invitation.idUser];
    }
    if (users.create.nuevos.indexOf(invitation.idUser) !== -1) {
        user = users.create.nuevo[invitation.idUser];
    }


    //State
    const [files, setFiles] = useState([]);
    const [otherDate, setOtherDate] = useState(false);
    const [otherTime, setOtherTime] = useState(false);
    const [idSolicitante, setIdSolicitante] = useState(null);
    const [desSolicitante, setSolicitante] = useState("");
    const guardandoLoading = idInvitation ? invitations.update.isUpdating : invitations.create.isCreating;
    const disabled = (idInvitation ? invitations.update.isUpdating : invitations.create.isCreating) || invitations.byId.isFetching;
    const error = idInvitation ? invitations.update.error : invitations.create.error;

    //Hooks
    let reglas = {};
    reglas = {
        name: ["sometimes|required"],
        lastname: ["sometimes|required"],
        email: ["sometimes|required"],
        dni: ["sometimes|required"],
    };
    const [validador, validar] = useValidador(reglas);
    let customTime = null;


    //Effects
    useEffect(() => {
        window.scrollTo(0, 0);
        if (idInvitation) {
            dispatch(fetchInvitation(idInvitation));
            setOtherDate(true);
        }

        dispatch(fetchRolesIfNeeded({}));

        return function cleanup() {
            userReset.resetAll(dispatch);
        }
    }, [idInvitation]);


    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (!invitations.create.isCreating && !invitations.create.error) {
                history.push("/invitations/" + invitation.id + "/share");
                dispatch(resetCreateInvitation());
                dispatch(resetCreateUser());
                setIdSolicitante(null);
                setSolicitante("");
                window.scrollTo(0, 0);
            }
        }
    }, [invitations.create.isCreating]);

    useEffect(() => {
        if (user.id && !desSolicitante) {
            setSolicitante(user.name + " " + user.lastname);
            setIdSolicitante(user.id)
            let cambio = {}
            cambio[user.id] = user;
            dispatch(updateUser(cambio));
        }
    }, [user.id]);

    //User
    const handleChangeInvitation = (e) => {
        let cambio = {};
        cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
        changeInvitation(cambio);
    };

    const handleChangeDate = (fecha, id) => {
        msgDate.current.style.display = "none";
        let cambio = {};
        if (fecha && fecha.isValid()) {
            cambio[id] = fecha.format("YYYY-MM-DD");
            if (id === "dateFrom")
                cambio["dateTo"] = fecha.format("YYYY-MM-DD");
            changeInvitation(cambio);
        }
    };

    const handleChangeTimeSchedule = (schedule) => {
        msgDate.current.style.display = "none";
        let cambio = {};
        cambio["timeFrom"] = schedule.timeFrom;
        cambio["timeTo"] = schedule.timeTo;
        changeInvitation(cambio);
    }

    const handleChangeTime = (fecha, id) => {
        msgDate.current.style.display = "none";
        let cambio = {};
        if (fecha && fecha.isValid()) {
            cambio[id] = fecha.format("HH:mm:ss");
            changeInvitation(cambio);
        }
    };

    const handleChangePermanentAccess = (e) => {
        msgDate.current.style.display = "none";
        let cambio = {};
        if (e.target.checked === true) {
            cambio["timeFrom"] = "00:00:00";
            cambio["timeTo"] = "23:59:59";
            cambio["dateFrom"] = moment().format("YYYY-MM-DD");
            cambio["dateTo"] = "2037-12-31";
            cambio["permanentAccess"] = 1;
        } else if (!e.target.checked) {
            cambio["timeFrom"] = null;
            cambio["timeTo"] = null;
            cambio["dateFrom"] = null;
            cambio["dateTo"] = null;
            cambio["permanentAccess"] = 0;
        }
        changeInvitation(cambio);
    };


    const changeInvitation = (cambio) => {
        if (idInvitation)
            dispatch(updateInvitation(cambio));
        else
            dispatch(createInvitation(cambio));
    };

    const changeFacePhoto = (cambio, idFacePhoto) => {
        //Verificar si ya esta siendo actualizado
        let facePhoto = idFacePhoto && facePhotos.update.activos.indexOf(idFacePhoto) === -1 && facePhotos.create.nuevos.indexOf(idFacePhoto) === -1 ? facePhotos.byId.facePhotos[idFacePhoto] : null;
        if (facePhoto) {
            let actualizar = facePhoto ? clone(facePhoto) : null;
            if (actualizar) {
                let cambioAssetLanguage = {};
                cambioAssetLanguage[actualizar.id] = actualizar;
                if (idFacePhoto.toString().indexOf("-") === -1)
                    dispatch(updateFacePhoto(cambioAssetLanguage));
                else
                    dispatch(createFacePhoto(cambioAssetLanguage));
            }
        }
        cambio.id = idFacePhoto;
        cambio.idUser = user.id;
        let cambioAssetLanguage = {};
        cambioAssetLanguage[idFacePhoto] = cambio;
        if (idFacePhoto.toString().indexOf("-") === -1)
            dispatch(updateFacePhoto(cambioAssetLanguage));
        else
            dispatch(createFacePhoto(cambioAssetLanguage));
    };

    const handleChangeStatusInvitation = () => {
        let cambio = {};
        cambio.status = !user.status;
        changeInvitation(cambio);
    }

    //User
    const handleChangeUser = (e) => {
        let cambio = {};
        cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
        changeUser(cambio);
    };


    const changeUser = (cambio) => {
        cambio.id = idSolicitante;
        let cambioUsuario = {};
        cambioUsuario[idSolicitante] = cambio;
        if (idSolicitante !== -1)
            dispatch(updateUser(cambioUsuario));
        else
            dispatch(createUser(cambioUsuario));
    };

    const handleChangeStatusUser = () => {
        let cambio = {};
        cambio.status = !user.status;
        changeUser(cambio);
    }


    //User
    const timer = useRef();

    function handleChangeSolicitanteInput(e, value, reason) {
        msgUser.current.style.display = "none";
        if (value && reason === "input" && value.length > 2) {
            clearTimeout(timer.current);
            timer.current = setTimeout(() => triggerChangeSolicitante(value), 300);
        }
        setSolicitante(value);
    }

    const triggerChangeSolicitante = (searchPhase) => {
        dispatch(resetUsers);
        dispatch(invalidateUsers);
        dispatch(fetchUsers({searchPhase: searchPhase, with: ["facePhoto"]}));
    };

    const handleSelectUser = (e, value, reason) => {
        msgUser.current.style.display = "none";
        let idSolicitanteSelect = null;
        let solicitanteSelect = "";
        if (value && value.id) {
            idSolicitanteSelect = value.id;
            solicitanteSelect = value.nombre + " " + value.apellido;
            if (users.update.activos.indexOf(idSolicitanteSelect) === -1) {
                let ubicacionUpdate = clone(users.byId.users[idSolicitanteSelect]);
                let cambio = {}
                cambio[idSolicitanteSelect] = ubicacionUpdate;
                dispatch(updateUser(cambio));
            }
        } else if (value && value.name && value.name.indexOf("Agregar") !== -1) {
            idSolicitanteSelect = -1;
            solicitanteSelect = "Nuevo invitado";
        } else if (reason === "clear") {
            idSolicitanteSelect = 0;
            solicitanteSelect = "";
        }
        let cambio = {};
        cambio.idUser = idSolicitanteSelect;
        cambio.user = idSolicitanteSelect;
        changeInvitation(cambio);
        setIdSolicitante(idSolicitanteSelect);
        setSolicitante(solicitanteSelect);
    };

    const handleButtonNewUser = () => {
        setIdSolicitante(-1);
        setSolicitante("Nueva persona");
        let cambio = {};
        cambio.idUser = -1;
        cambio.user = -1;
        changeInvitation(cambio);
    }

    const handleButtonAvoidCompleteUser = () => {
        msgUser.current.style.display = "none";
        setIdSolicitante(null);
        setSolicitante("");
        let cambio = {};
        cambio.idUser = null;
        cambio.user = null;
        changeInvitation(cambio);
    }

    const handleButtonCompleteUser = () => {
        setIdSolicitante(0);
        setSolicitante("");
        let cambio = {};
        cambio.idUser = null;
        cambio.user = null;
        changeInvitation(cambio);
    }

    //Submit
    const onSubmit = (e) => {
        e.preventDefault();
        if (idSolicitante === 0 && !user.dni) {
            msgUser.current.style.display = "inline";
        } else if (!invitation.dateFrom || !invitation.timeFrom || !invitation.timeTo) {
            msgDate.current.style.display = "inline";
        } else if (validador.isValid()) {
            if (idInvitation)
                dispatch(saveUpdateInvitation(files));
            else
                dispatch(saveCreateInvitation(files));
        }
    };


    return (
        <Container maxWidth={"lg"}>
            <Breadcrumbs aria-label="breadcrumb" separator={"‣"}>
                <Link color="inherit" to="/">
                    Home
                </Link>
                <Link color="inherit" to="/users">
                    Invitaciones
                </Link>
            </Breadcrumbs>
            <Typography component={"h1"} variant={"h1"}>
                {!idInvitation ? "Nueva invitación" : "Modificar invitación"}

            </Typography>
            <form onSubmit={onSubmit}>
                <Grid container spacing={2}>
                    <Grid item md={12} sm={12} xs={12}>
                        <CustomPaper>
                            <PaperHeader>
                                <Typography variant={"overline"} component={"span"}>
                                    ¿En qué fecha se invita la persona?
                                </Typography>
                            </PaperHeader>
                            <PaperBody spacing={2}>
                                <FormHelperText ref={msgDate} style={{display: "none", color: "red"}}>
                                    Debe completar la fecha y la hora de la invitación
                                </FormHelperText>
                                {
                                    invitation && !invitation.permanentAccess &&
                                    <div className={style.containerForm}>
                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography variant={"body1"} component={"h1"} color={"primary"}>
                                                Seleccione fecha

                                            </Typography>
                                            <Button variant="outlined" onClick={() => setOtherDate(!otherDate)}>Otra
                                                fecha</Button>
                                        </Box>
                                        {
                                            otherDate === false &&
                                            <CustomItemsContainer>
                                                {
                                                    [...Array(15).keys()].map((i) => {
                                                        let date = moment().add(i, "days");
                                                        return (
                                                            <ItemBox key={"day" + i} value="female"
                                                                     onClick={() => handleChangeDate(date, "dateFrom")}
                                                                     control={<RadioCustom
                                                                         checked={invitation && invitation.dateFrom ? moment(invitation.dateFrom).isSame(date, "day") : false}/>}
                                                                     label={
                                                                         <div>
                                                                             <Typography variant={"subtitle2"}
                                                                                         component={"p"}>
                                                                                 {date.format("dddd")}
                                                                             </Typography>
                                                                             <Typography variant={"h4"}
                                                                                         component={"h4"}>
                                                                                 {date.format("D")}
                                                                             </Typography>
                                                                             <Typography variant={"subtitle2"}
                                                                                         component={"p"}>
                                                                                 {date.format("MMMM")}
                                                                             </Typography>
                                                                         </div>
                                                                     }
                                                            />);
                                                    })
                                                }
                                            </CustomItemsContainer>
                                        }
                                        {
                                            otherDate === true &&
                                            <Box display={"flex"} justifyContent={"flex-start"}>
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <KeyboardDatePicker
                                                        style={{marginRight: "20px"}}
                                                        label="Fecha desde"
                                                        format="DD/MM/yyyy"
                                                        value={invitation ? invitation.dateFrom : null}
                                                        onChange={(e) => handleChangeDate(e, "dateFrom")}
                                                        animateYearScrolling
                                                    />
                                                    <KeyboardDatePicker
                                                        label="Fecha hasta"
                                                        format="DD/MM/yyyy"
                                                        minDate={invitation ? invitation.dateFrom : null}
                                                        value={invitation && invitation.dateTo ? invitation.dateTo : null}
                                                        onChange={(e) => handleChangeDate(e, "dateTo")}
                                                        animateYearScrolling
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Box>
                                        }

                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography variant={"body1"} component={"h1"} color={"primary"}>
                                                Seleccione hora

                                            </Typography>
                                            <Button variant="outlined" onClick={() => setOtherTime(!otherTime)}>Otro
                                                horario</Button>
                                        </Box>
                                        {
                                            otherTime === false &&
                                            <CustomItemsContainer>
                                                {
                                                    schedule.map((item, i) => {
                                                        let timeFrom = moment(item.timeFrom, "HH:mm:ss");
                                                        let timeTo = moment(item.timeTo, "HH:mm:ss");
                                                        if (item && invitation && invitation.timeFrom === item.timeFrom && invitation.timeTo === item.timeTo)
                                                            customTime = true;
                                                        return (
                                                            <ItemBox key={"time" + i} value="female"
                                                                     onClick={() => handleChangeTimeSchedule(item)}
                                                                     control={<RadioCustom
                                                                         checked={item && invitation && invitation.timeFrom === item.timeFrom && invitation.timeTo === item.timeTo}/>}
                                                                     label={
                                                                         <div>
                                                                             <Typography variant={"caption"}
                                                                                         component={"h5"}>
                                                                                 {item ? item.name : ""}
                                                                             </Typography>
                                                                             <Typography variant={"h5"}
                                                                                         component={"h5"}>
                                                                                 {timeFrom ? timeFrom.format("HH:mm") : ""}
                                                                             </Typography>
                                                                             <Typography variant={"subtitle2"}
                                                                                         component={"p"}>
                                                                                 A
                                                                             </Typography>
                                                                             <Typography variant={"h5"}
                                                                                         component={"h5"}>
                                                                                 {timeTo ? timeTo.format("HH:mm") : ""}
                                                                             </Typography>
                                                                         </div>
                                                                     }
                                                            />);
                                                    })
                                                }
                                                {
                                                    !customTime && !!idInvitation && !invitations.byId.isFetching &&
                                                    <ItemBox value="female"
                                                             onClick={() => setOtherTime(true)}
                                                             control={<RadioCustom
                                                                 checked={true}/>}
                                                             label={
                                                                 <div>
                                                                     <Typography variant={"caption"}
                                                                                 component={"h5"}>
                                                                         {"Otro"}
                                                                     </Typography>
                                                                     <Typography variant={"h5"} component={"h5"}>
                                                                         {invitation.timeFrom ? moment(invitation.timeFrom, "HH:mm:ss").format("HH:mm") : ""}
                                                                     </Typography>
                                                                     <Typography variant={"subtitle2"}
                                                                                 component={"p"}>
                                                                         A
                                                                     </Typography>
                                                                     <Typography variant={"h5"} component={"h5"}>
                                                                         {invitation.timeTo ? moment(invitation.timeTo, "HH:mm:ss").format("HH:mm") : ""}
                                                                     </Typography>
                                                                 </div>
                                                             }
                                                    />
                                                }
                                            </CustomItemsContainer>
                                        }
                                        {
                                            otherTime === true &&
                                            <Box display={"flex"} justifyContent={"flex-start"}>
                                                <KeyboardTimePicker
                                                    style={{marginRight: "20px"}}
                                                    label="Hora desde"
                                                    ampm={false}
                                                    format="HH:mm"
                                                    mask="__:__"
                                                    value={invitation && invitation.timeFrom ? moment(invitation.timeFrom, "HH:mm").format("YYYY-MM-DD HH:mm") : null}
                                                    onChange={date => handleChangeTime(date, "timeFrom")}
                                                />
                                                <KeyboardTimePicker
                                                    label="Hora hasta"
                                                    ampm={false}
                                                    format="HH:mm"
                                                    mask="__:__"
                                                    value={invitation && invitation.timeTo ? moment(invitation.timeTo, "HH:mm").format("YYYY-MM-DD HH:mm") : null}
                                                    onChange={date => handleChangeTime(date, "timeTo")}
                                                />
                                            </Box>
                                        }

                                    </div>
                                }
                                <FormControlLabel
                                    label="Acceso permanente e irrestringido"
                                    control={
                                        <Switch
                                            id={"permanentAccess"}
                                            checked={invitation.permanentAccess === 1}
                                            onChange={handleChangePermanentAccess}
                                            color="primary"

                                        />}
                                />
                            </PaperBody>
                        </CustomPaper>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <CustomPaper>
                            <PaperHeader>
                                <Typography variant={"overline"} component={"span"}>
                                    Usuario
                                </Typography>
                            </PaperHeader>
                            <PaperBody spacing={2}>
                                <FormHelperText ref={msgUser} style={{display: "none", color: "red"}}>
                                    Debe completar los datos del usuario o elegir la opción "Completar por el invitado"
                                </FormHelperText>
                                {
                                    invitation && idSolicitante !== null &&
                                    <Grid item md={12} sm={12} xs={12}>
                                        <Autocomplete
                                            classes={{
                                                option: classes.option,
                                            }}
                                            onChange={(event, newValue, reason) => {
                                                handleSelectUser(null, newValue, reason);
                                            }}
                                            filterOptions={(options, params) => {
                                                const filtered = options;
                                                // Suggest the creation of a new value

                                                if (params && params.inputValue !== "") {
                                                    filtered.push({
                                                        inputValue: params.inputValue,
                                                        name: `Agregar nuevo invitado "${params.inputValue}"`,
                                                        lastname: '',
                                                        email: '',
                                                    });
                                                }

                                                return filtered;
                                            }}
                                            noOptionsText={"Escriba para buscar"}
                                            loadingText={"Buscando..."}
                                            selectOnFocus
                                            // clearOnBlur
                                            margin="normal"
                                            handleHomeEndKeys
                                            id="idUsuario"
                                            options={users.allIds.map(option => {
                                                let user = users.byId.users[option];
                                                return user ? user : "";
                                            })}
                                            getOptionLabel={(option) => {
                                                // Value selected with enter, right from the input
                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                // Regular option
                                                return option.name + ' ' + option.lastname;
                                            }}
                                            // renderOption={(option) => option.nombre + ' ' + option.apellido + ' ' + option.email}
                                            disabled={false}
                                            renderOption={(option) => {
                                                let facePhoto = option && option.face_photo && option.face_photo.length > 0 ? facePhotos.byId.facePhotos[option.face_photo[0]] : null;
                                                return (
                                                    <Card variant="outlined" style={{width: "100%"}}>
                                                        <CardHeader
                                                            avatar={
                                                                <Avatar aria-label="recipe" className={classes.avatar}
                                                                        src={facePhoto ? c.API_HOST + facePhoto.path : ""}>
                                                                    {option.lastname ? option.lastname.charAt(0) :
                                                                        <AddIcon/>}
                                                                </Avatar>
                                                            }
                                                            title={option.name + " " + option.lastname}
                                                            subheader={(option.email ? option.email : "") + (option.dni ? " - DNI: " + option.dni : "")}
                                                        />
                                                    </Card>
                                                )
                                            }}
                                            fullWidth={false}
                                            style={{width: "100%"}}
                                            // freeSolo
                                            loading={users.byId.isFetching}
                                            onInputChange={handleChangeSolicitanteInput}
                                            inputValue={desSolicitante ? desSolicitante : ""}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Buscar por Apellido/Nombre/DNI/Email"
                                                           variant="outlined"
                                                           margin="normal"
                                                           onBlur={validar}
                                                           error={!validador.isValidById("idUser")}
                                                           helperText={validador.getHelperTextById("idUser")}
                                                           InputProps={{
                                                               ...params.InputProps,
                                                               endAdornment: (
                                                                   <React.Fragment>
                                                                       {users.byId.isFetching ?
                                                                           <CircularProgress color="primary"
                                                                                             size={20}/> : null}
                                                                       {params.InputProps.endAdornment}
                                                                   </React.Fragment>
                                                               ),
                                                               autoComplete: "off"
                                                           }}/>
                                            )}

                                        />
                                    </Grid>
                                }
                                {
                                    invitation && !!invitation.idUser &&
                                    <React.Fragment>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item md={6} sm={12} xs={12}>
                                                    <FacePhoto files={files} setFiles={setFiles}
                                                               changeFacePhoto={changeFacePhoto}
                                                               facePhoto={user && user.face_photo && user.face_photo.length > 0 ? facePhotos.byId.facePhotos[user.face_photo[0]] : null}
                                                               file={user.path} disabled={disabled}/>
                                                </Grid>
                                                <Grid item md={6} sm={12} xs={12}>
                                                    <TextField
                                                        id="name"
                                                        placeholder="Nombre"
                                                        variant="outlined"
                                                        margin={"normal"}
                                                        label={"Nombre *"}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            disabled: disabled,
                                                            type: "text"
                                                        }}
                                                        disabled={disabled}
                                                        fullWidth
                                                        value={user && user.name ? user.name : ""}
                                                        onChange={handleChangeUser}
                                                        onBlur={validar}
                                                        error={!validador.isValidById("name")}
                                                        helperText={validador.getHelperTextById("name")}
                                                    />
                                                    <TextField
                                                        id="lastname"
                                                        placeholder="Apellido"
                                                        variant="outlined"
                                                        label={"Apellido *"}
                                                        margin={"normal"}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            disabled: disabled,
                                                            type: "text"
                                                        }}
                                                        disabled={disabled}
                                                        fullWidth
                                                        value={user && user.lastname ? user.lastname : ""}
                                                        onChange={handleChangeUser}
                                                        onBlur={validar}
                                                        error={!validador.isValidById("lastname")}
                                                        helperText={validador.getHelperTextById("lastname")}
                                                    />
                                                </Grid>

                                                <Grid container>

                                                    <TextField
                                                        id="dni"
                                                        placeholder="DNI"
                                                        variant="outlined"
                                                        margin={"normal"}
                                                        label={"DNI *"}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            disabled: disabled,
                                                            type: "number",
                                                        }}
                                                        disabled={true}
                                                        fullWidth
                                                        value={user && user.dni ? user.dni : ""}
                                                        onChange={(e) => handleChangeUser(e)}
                                                        onBlur={validar}
                                                        error={!validador.isValidById("dni")}
                                                        helperText={validador.getHelperTextById("dni")}
                                                    />

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <TextField
                                                id="email"
                                                placeholder="Email"
                                                variant="outlined"
                                                margin={"normal"}
                                                label={"Email *"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    disabled: disabled,
                                                    type: "text",
                                                }}
                                                disabled={true}
                                                fullWidth
                                                value={user && user.email ? user.email : ""}
                                                onChange={(e) => handleChangeUser(e)}
                                                onBlur={validar}
                                                error={!validador.isValidById("email")}
                                                helperText={validador.getHelperTextById("email")}
                                            />
                                            <TextField
                                                id="mobilePhone"
                                                placeholder="Celular"
                                                variant="outlined"
                                                margin={"normal"}
                                                label={"Celular"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    disabled: disabled,
                                                    type: "number",
                                                }}
                                                disabled={true}
                                                fullWidth
                                                value={user && user.mobilePhone ? user.mobilePhone : ""}
                                                onChange={(e) => handleChangeUser(e)}
                                                onBlur={validar}
                                                error={!validador.isValidById("mobilePhone")}
                                                helperText={validador.getHelperTextById("mobilePhone")}
                                            />
                                            <TextField
                                                id="enterprise"
                                                placeholder="Empresa"
                                                variant="outlined"
                                                margin={"normal"}
                                                label={"Empresa"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    disabled: disabled,
                                                    type: "text",
                                                }}
                                                disabled={true}
                                                fullWidth
                                                value={user && user.enterprise ? user.enterprise : ""}
                                                onChange={(e) => handleChangeUser(e)}
                                                onBlur={validar}
                                                error={!validador.isValidById("enterprise")}
                                                helperText={validador.getHelperTextById("enterprise")}
                                            />
                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12}
                                              style={{visibility: user.id === -1 ? "hidden" : "inherit"}}
                                        >
                                            <Box display={"flex"} justifyContent={"flex-end"}>
                                                {
                                                    user.status === 1 &&
                                                    <Chip
                                                        icon={<DoneIcon/>}
                                                        label="Activo"
                                                        color="primary"
                                                        variant="outlined"
                                                    />
                                                }
                                                {
                                                    user.status === 0 &&
                                                    <Chip
                                                        icon={<DoneIcon/>}
                                                        label="Inactivo"
                                                        color="secondary"
                                                        variant="outlined"
                                                    />
                                                }
                                            </Box>
                                        </Grid>
                                    </React.Fragment>
                                }
                                {
                                    invitation && idSolicitante !== null &&
                                    <Grid item md={12} sm={12} xs={12}>
                                        <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
                                            <Button variant="outlined" onClick={() => handleButtonAvoidCompleteUser()}
                                                    startIcon={<ArrowBackIosIcon/>}>
                                                Volver a opciones
                                            </Button>
                                        </Box>
                                    </Grid>
                                }
                                {
                                    invitation && idSolicitante === null &&
                                    <Grid item md={12} sm={12} xs={12}>
                                        <Box display={"flex"} justifyContent={"center"} width={"100%"}
                                             flexWrap={"wrap"} gridGap={"12px"}>
                                            <div onClick={() => handleButtonAvoidCompleteUser()}>
                                                <Box display={"flex"} justifyContent={"center"} width={"100%"}
                                                     className={clsx(style.btnOptionUser, {
                                                         [style.selected]: invitation && !invitation.idUser,
                                                     })}>
                                                    <Box display={"flex"} justifyContent={"center"}
                                                         alignContent={"center"} flexWrap={"wrap"}
                                                         className={style.icon}>
                                                        <PersonAddDisabledIcon fontSize="large"/>
                                                    </Box>
                                                    <Box display={"flex"} justifyContent={"center"} width={"100%"}
                                                         flexWrap={"wrap"}>
                                                        <Typography variant={"h6"} component={"h5"}>
                                                            Solicitar al invitado que complete sus datos
                                                        </Typography>
                                                        <Typography variant={"caption"} component={"span"}>
                                                            Se le mostrará un link que puede ser enviado al invitado
                                                            para
                                                            que complete sus datos.
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </div>
                                            <div onClick={() => handleButtonCompleteUser()}>
                                                <Box display={"flex"} justifyContent={"center"} width={"100%"}
                                                     className={style.btnOptionUser}
                                                     className={clsx(style.btnOptionUser, {
                                                         [style.selected]: invitation && invitation.idUser !== null && invitation.idUser !== undefined,
                                                     })}>
                                                    <Box display={"flex"} justifyContent={"center"}
                                                         alignContent={"center"} flexWrap={"wrap"}
                                                         className={style.icon}>
                                                        <PersonAddIcon fontSize="large"/>
                                                    </Box>
                                                    <Box display={"flex"} justifyContent={"center"} width={"100%"}
                                                         flexWrap={"wrap"}>
                                                        <Typography variant={"h6"} component={"h5"}>
                                                            Completar datos del invitado
                                                        </Typography>
                                                        <Typography variant={"caption"} component={"span"}>
                                                            En el caso de que el invitado ya esté registrado los datos
                                                            se autocompletarán.
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </div>
                                        </Box>
                                    </Grid>
                                }
                            </PaperBody>
                        </CustomPaper>
                    </Grid>
                </Grid>

                <ContainerBtn>
                    <BtnCancel
                        onClick={() => props.location.pathname === "/invitations/new" ? props.history.push("/invitations") : props.history.goBack()}/>
                    <BtnSave loading={guardandoLoading} disabled={disabled} error={error}/>
                </ContainerBtn>
            </form>

        </Container>
    );
}
