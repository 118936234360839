//api
import invitationsApi from "../api/invitationsApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeInvitations";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//INVITATIONS
export const REQUEST_INVITATIONS = 'REQUEST_INVITATIONS';
export const RECEIVE_INVITATIONS = 'RECEIVE_INVITATIONS';
export const INVALIDATE_INVITATIONS = 'INVALIDATE_INVITATIONS';
export const ERROR_INVITATIONS = "ERROR_INVITATIONS";
export const RESET_INVITATIONS = "RESET_INVITATIONS";


export function invalidateInvitations() {
    return {
        type: INVALIDATE_INVITATIONS
    }
}

function requestInvitations() {
    return {
        type: REQUEST_INVITATIONS,
    }
}

function receiveInvitations(json) {
    return {
        type: RECEIVE_INVITATIONS,
    invitations: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorInvitations(error) {
    return {
        type: ERROR_INVITATIONS,
        error: error,
    }
}

export function resetInvitations() {
    return {
        type: RESET_INVITATIONS
    }
}

export function fetchInvitations(filtros) {
    return dispatch => {
        dispatch(requestInvitations());
        return invitationsApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveInvitations(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorInvitations(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorInvitations(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchInvitations(state) {
    const invitations = state.invitations.byId;
    if (!invitations) {
        return true
    } else if (invitations.isFetching) {
        return false
    } else {
        return invitations.didInvalidate;
    }
}

export function fetchInvitationsIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchInvitations(getState())) {
            return dispatch(fetchInvitations(filtros))
        }
    }
}


//MODEL
export const REQUEST_INVITATION = 'REQUEST_INVITATION';
export const RECEIVE_INVITATION = 'RECEIVE_INVITATION';
export const INVALIDATE_INVITATION = 'INVALIDATE_INVITATION';
export const ERROR_INVITATION = "ERROR_INVITATION";
export const RESET_INVITATION = "RESET_INVITATION";


export function invalidateInvitation() {
    return {
        type: INVALIDATE_INVITATION
    }
}

function requestInvitation() {
    return {
        type: REQUEST_INVITATION,
    }
}

export function receiveInvitation(json) {
    return {
        type: RECEIVE_INVITATION,
    invitation: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorInvitation(error) {
    return {
        type: ERROR_INVITATION,
        error: error,
    }
}

export function fetchInvitation(idInvitation) {
    return dispatch => {
        dispatch(requestInvitation());
        return invitationsApi.getOne(idInvitation)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveInvitation(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorInvitation(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorInvitation(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_INVITATION = 'RECEIVE_FILE_INVITATION';

function receiveFileInvitation(file) {
    return {
        type: RECEIVE_FILE_INVITATION,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileInvitation(idInvitation, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return invitationsApi.getFile(idInvitation, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileInvitation(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorInvitation(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorInvitation(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_INVITATION = 'UPDATE_INVITATION';
export const REQUEST_UPDATE_INVITATION = "REQUEST_UPDATE_INVITATION";
export const SUCCESS_UPDATE_INVITATION = "SUCCESS_UPDATE_INVITATION";
export const ERROR_UPDATE_INVITATION = "ERROR_UPDATE_INVITATION";
export const RESET_UPDATE_INVITATION = "RESET_UPDATE_INVITATION";
export const DELETE_UPDATE_INVITATION = "DELETE_UPDATE_INVITATION";

function requestUpdateInvitation() {
    return {
        type: REQUEST_UPDATE_INVITATION,
    }
}

function receiveUpdateInvitation(invitation) {
    return {
        type: SUCCESS_UPDATE_INVITATION,
        receivedAt: Date.now(),
        invitation: normalizeDato(invitation)
    }
}

function errorUpdateInvitation(error) {
    return {
        type: ERROR_UPDATE_INVITATION,
        error: error,
    }
}

export function resetUpdateInvitation() {
    return {
        type: RESET_UPDATE_INVITATION,
    }
}

export function updateInvitation(invitation) {
    return {
        type: UPDATE_INVITATION,
        invitation
    }
}

export function saveUpdateInvitation(files) {
    return (dispatch, getState) => {
        dispatch(requestUpdateInvitation());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let invitation = denormalizeDato(getState().invitations.update.activo, store);

        return invitationsApi.saveUpdate(invitation, files)
            .then(function (data, textStatus, response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    dispatch(receiveUpdateInvitation(data));
                }
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateInvitation(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                try{
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateInvitations(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateInvitations(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateInvitation(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                    dispatch(receiveUpdateInvitation(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateInvitation(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                                    }catch{
                                        dispatch(errorUpdateInvitation(errorMessages.GENERAL_ERROR));
                                    };
                            }
                        });
    }
}

export function deleteUpdateInvitation(invitation) {
    return {
        type: DELETE_UPDATE_INVITATION,
        invitation
    }
}

//UPDATE INVITATIONS
export const REQUEST_UPDATE_INVITATIONS = "REQUEST_UPDATE_INVITATIONS";
export const SUCCESS_UPDATE_INVITATIONS = "SUCCESS_UPDATE_INVITATIONS";
export const ERROR_UPDATE_INVITATIONS = "ERROR_UPDATE_INVITATIONS";
export const RESET_UPDATE_INVITATIONS = "RESET_UPDATE_INVITATIONS";

function requestUpdateInvitations() {
    return {
        type: REQUEST_UPDATE_INVITATIONS,
}
}

function receiveUpdateInvitations(invitations) {
    return {
        type: SUCCESS_UPDATE_INVITATIONS,
    receivedAt: Date.now(),
        invitations: normalizeDatos(invitations)
}
}

function errorUpdateInvitations(error) {
    return {
        type: ERROR_UPDATE_INVITATIONS,
    error: error,
}
}

export function resetUpdateInvitations() {
    return {
        type: RESET_UPDATE_INVITATIONS,
}
}

export function saveUpdateInvitations() {
    return (dispatch, getState) => {
        dispatch(requestUpdateInvitations());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let invitations = getState().invitations.update.activos.map((idInvitation) => {
            return denormalizeDato(getState().invitations.update.activo[idInvitation], store);
        });

        return invitationsApi.saveUpdateInvitations(invitations)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateInvitations(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateInvitations(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateInvitations(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateInvitations(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateInvitations(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateInvitations(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA INVITATION
export const CREATE_INVITATION = 'CREATE_INVITATION';
export const REQUEST_CREATE_INVITATION = "REQUEST_CREATE_INVITATION";
export const SUCCESS_CREATE_INVITATION = "SUCCESS_CREATE_INVITATION";
export const ERROR_CREATE_INVITATION = "ERROR_CREATE_INVITATION";
export const RESET_CREATE_INVITATION = "RESET_CREATE_INVITATION";
export const DELETE_CREATE_INVITATION = "DELETE_CREATE_INVITATION";


//ALTA INVITATION
function requestCreateInvitation() {
    return {
        type: REQUEST_CREATE_INVITATION,
    }
}

function receiveCreateInvitation(invitation) {
    return {
        type: SUCCESS_CREATE_INVITATION,
        receivedAt: Date.now(),
        invitation: normalizeDato(invitation)
    }
}

export function errorCreateInvitation(error) {
    return {
        type: ERROR_CREATE_INVITATION,
        error: error,
    }
}

export function resetCreateInvitation() {
    return {
        type: RESET_CREATE_INVITATION
    }
}

export function createInvitation(invitation) {
    return {
        type: CREATE_INVITATION,
        invitation
    }
}

export function deleteCreateInvitation(invitation) {
    return {
        type: DELETE_CREATE_INVITATION,
        invitation
    }
}

export function saveCreateInvitation(files) {
    return (dispatch, getState) => {
        dispatch(requestCreateInvitation());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let invitation = denormalizeDato(getState().invitations.create.nuevo, store);

        return invitationsApi.saveCreate(invitation, files)
            .then(function (data, textStatus, response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    dispatch(receiveCreateInvitation(data));
                }
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateInvitation(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                 try
                                 {
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateInvitations(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateInvitations(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateInvitation(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateInvitation(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateInvitation(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                                    }catch{
                                        dispatch(errorCreateInvitation(errorMessages.GENERAL_ERROR));
                                    };
                            }
                        });
    }
}

//CREATE INVITATIONS
export const REQUEST_CREATE_INVITATIONS = "REQUEST_CREATE_INVITATIONS";
export const SUCCESS_CREATE_INVITATIONS = "SUCCESS_CREATE_INVITATIONS";
export const ERROR_CREATE_INVITATIONS = "ERROR_CREATE_INVITATIONS";
export const RESET_CREATE_INVITATIONS = "RESET_CREATE_INVITATIONS";

function requestCreateInvitations() {
    return {
        type: REQUEST_CREATE_INVITATIONS,
}
}

function receiveCreateInvitations(invitations) {
    return {
        type: SUCCESS_CREATE_INVITATIONS,
    receivedAt: Date.now(),
        invitations: normalizeDatos(invitations)
}
}

function errorCreateInvitations(error) {
    return {
        type: ERROR_CREATE_INVITATIONS,
    error: error,
}
}

export function resetCreateInvitations() {
    return {
        type: RESET_CREATE_INVITATIONS,
}
}

export function saveCreateInvitations() {
    return (dispatch, getState) => {
        dispatch(requestCreateInvitations());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let invitations = getState().invitations.create.nuevos.map((idInvitation) => {
            return denormalizeDato(getState().invitations.create.nuevo[idInvitation], store);
        });

        return invitationsApi.saveCreateInvitations(invitations)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateInvitations(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateInvitations(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateInvitations(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateInvitations(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateInvitations(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateInvitations(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE INVITATION
export const DELETE_INVITATION = 'DELETE_INVITATION';
export const REQUEST_DELETE_INVITATION = "REQUEST_DELETE_INVITATION";
export const SUCCESS_DELETE_INVITATION = "SUCCESS_DELETE_INVITATION";
export const ERROR_DELETE_INVITATION = "ERROR_DELETE_INVITATION";
export const RESET_DELETE_INVITATION = "RESET_DELETE_INVITATION";

function requestDeleteInvitation() {
    return {
        type: REQUEST_DELETE_INVITATION,
    }
}

function receiveDeleteInvitation(invitation) {
    return {
        type: SUCCESS_DELETE_INVITATION,
        receivedAt: Date.now(),
        invitation: normalizeDato(invitation)
    }
}

function errorDeleteInvitation(error) {
    return {
        type: ERROR_DELETE_INVITATION,
        error: error,
    }
}

export function resetDeleteInvitation(error) {
    return {
        type: RESET_DELETE_INVITATION,
        error: error,
    }
}

export function deleteInvitation(invitation) {
    return {
        type: DELETE_INVITATION,
        invitation
    }
}

export function saveDeleteInvitation(invitation) {
    return dispatch => {
        dispatch(requestDeleteInvitation());
        return invitationsApi.saveDelete(invitation)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteInvitation());
                            dispatch(receiveDeleteInvitation(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteInvitation(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteInvitation(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteInvitation(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteInvitation(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteInvitation(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT INVITATION
export const REQUEST_PRINT_INVITATION = "REQUEST_PRINT_INVITATION";
export const SUCCESS_PRINT_INVITATION = "SUCCESS_PRINT_INVITATION";
export const ERROR_PRINT_INVITATION = "ERROR_PRINT_INVITATION";
export const RESET_PRINT_INVITATION = "RESET_PRINT_INVITATION";

function requestPrintInvitation() {
    return {
        type: REQUEST_PRINT_INVITATION,
    }
}

function receivePrintInvitation(turnos) {
    return {
        type: SUCCESS_PRINT_INVITATION,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintInvitation(error) {
    return {
        type: ERROR_PRINT_INVITATION,
        error: error,
    }
}

export function resetPrintInvitation() {
    return {
        type: RESET_PRINT_INVITATION,
    }
}

export function printInvitation(idInvitation) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintInvitation());
        return invitationsApi.printInvitation(idInvitation)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintInvitation(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintInvitation(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintInvitation(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintInvitation(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintInvitation(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}