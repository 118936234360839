import {combineReducers} from 'redux'
import auth from "./authReducer";
import users from "./users";
import roles from "./roles";
import invitations from "./invitations";
import facePhotos from "./facePhotos";
import invitationsStatus from "./invitationsStatus";
import notifications from "./notifications";
import events from "./events";
import devices from "./devices";

const appReducers = combineReducers({
    auth,
    devices,
    events,
    facePhotos,
    invitations,
    invitationsStatus,
    notifications,
    roles,
    users
});

export default appReducers;