import {resetInvitations, resetCreateInvitation, resetUpdateInvitation, resetDeleteInvitation} from "../actions/InvitationActions";
import {resetInvitationsSchedules, resetCreateInvitationSchedule, resetUpdateInvitationSchedule, resetDeleteInvitationSchedule} from "../actions/InvitationScheduleActions";import {resetInvitationsStatus, resetCreateInvitationStatus, resetUpdateInvitationStatus, resetDeleteInvitationStatus} from "../actions/InvitationStatusActions";

var resetInvitation = {
    resetAll(dispatch) {
        dispatch(resetInvitations());
        dispatch(resetCreateInvitation());
        dispatch(resetUpdateInvitation());
        dispatch(resetDeleteInvitation());
       dispatch(resetInvitationsSchedules());
dispatch(resetCreateInvitationSchedule());
dispatch(resetUpdateInvitationSchedule());
dispatch(resetDeleteInvitationSchedule());dispatch(resetInvitationsStatus());
dispatch(resetCreateInvitationStatus());
dispatch(resetUpdateInvitationStatus());
dispatch(resetDeleteInvitationStatus());
    },
};

export default resetInvitation;