//api
import notificationsApi from "../api/notificationsApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeNotifications";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//NOTIFICATIONS
export const REQUEST_NOTIFICATIONS = 'REQUEST_NOTIFICATIONS';
export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS';
export const INVALIDATE_NOTIFICATIONS = 'INVALIDATE_NOTIFICATIONS';
export const ERROR_NOTIFICATIONS = "ERROR_NOTIFICATIONS";
export const RESET_NOTIFICATIONS = "RESET_NOTIFICATIONS";


export function invalidateNotifications() {
    return {
        type: INVALIDATE_NOTIFICATIONS
    }
}

function requestNotifications() {
    return {
        type: REQUEST_NOTIFICATIONS,
    }
}

function receiveNotifications(json) {
    return {
        type: RECEIVE_NOTIFICATIONS,
    notifications: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorNotifications(error) {
    return {
        type: ERROR_NOTIFICATIONS,
        error: error,
    }
}

export function resetNotifications() {
    return {
        type: RESET_NOTIFICATIONS
    }
}

export function fetchNotifications(filtros) {
    return dispatch => {
        dispatch(requestNotifications());
        return notificationsApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveNotifications(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorNotifications(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorNotifications(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchNotifications(state) {
    const notifications = state.notifications.byId;
    if (!notifications) {
        return true
    } else if (notifications.isFetching) {
        return false
    } else {
        return notifications.didInvalidate;
    }
}

export function fetchNotificationsIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchNotifications(getState())) {
            return dispatch(fetchNotifications(filtros))
        }
    }
}


//MODEL
export const REQUEST_NOTIFICATION = 'REQUEST_NOTIFICATION';
export const RECEIVE_NOTIFICATION = 'RECEIVE_NOTIFICATION';
export const INVALIDATE_NOTIFICATION = 'INVALIDATE_NOTIFICATION';
export const ERROR_NOTIFICATION = "ERROR_NOTIFICATION";
export const RESET_NOTIFICATION = "RESET_NOTIFICATION";


export function invalidateNotification() {
    return {
        type: INVALIDATE_NOTIFICATION
    }
}

function requestNotification() {
    return {
        type: REQUEST_NOTIFICATION,
    }
}

export function receiveNotification(json) {
    return {
        type: RECEIVE_NOTIFICATION,
    notification: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorNotification(error) {
    return {
        type: ERROR_NOTIFICATION,
        error: error,
    }
}

export function fetchNotification(idNotification) {
    return dispatch => {
        dispatch(requestNotification());
        return notificationsApi.getOne(idNotification)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveNotification(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorNotification(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorNotification(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_NOTIFICATION = 'RECEIVE_FILE_NOTIFICATION';

function receiveFileNotification(file) {
    return {
        type: RECEIVE_FILE_NOTIFICATION,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileNotification(idNotification, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return notificationsApi.getFile(idNotification, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileNotification(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorNotification(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorNotification(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const REQUEST_UPDATE_NOTIFICATION = "REQUEST_UPDATE_NOTIFICATION";
export const SUCCESS_UPDATE_NOTIFICATION = "SUCCESS_UPDATE_NOTIFICATION";
export const ERROR_UPDATE_NOTIFICATION = "ERROR_UPDATE_NOTIFICATION";
export const RESET_UPDATE_NOTIFICATION = "RESET_UPDATE_NOTIFICATION";
export const DELETE_UPDATE_NOTIFICATION = "DELETE_UPDATE_NOTIFICATION";

function requestUpdateNotification() {
    return {
        type: REQUEST_UPDATE_NOTIFICATION,
    }
}

function receiveUpdateNotification(notification) {
    return {
        type: SUCCESS_UPDATE_NOTIFICATION,
        receivedAt: Date.now(),
        notification: normalizeDato(notification)
    }
}

function errorUpdateNotification(error) {
    return {
        type: ERROR_UPDATE_NOTIFICATION,
        error: error,
    }
}

export function resetUpdateNotification() {
    return {
        type: RESET_UPDATE_NOTIFICATION,
    }
}

export function updateNotification(notification) {
    return {
        type: UPDATE_NOTIFICATION,
        notification
    }
}

export function saveUpdateNotification() {
    return (dispatch, getState) => {
        dispatch(requestUpdateNotification());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let notification = denormalizeDato(getState().notifications.update.activo, store);

        return notificationsApi.saveUpdate(notification)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateNotification(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateNotification(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                try{
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateNotifications(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateNotifications(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateNotification(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateNotification(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateNotification(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                                    } catch{
                                            dispatch(errorUpdateNotification(errorMessages.GENERAL_ERROR));
                                        };
                            }
                        });
    }
}

export function deleteUpdateNotification(notification) {
    return {
        type: DELETE_UPDATE_NOTIFICATION,
        notification
    }
}

//UPDATE NOTIFICATIONS
export const REQUEST_UPDATE_NOTIFICATIONS = "REQUEST_UPDATE_NOTIFICATIONS";
export const SUCCESS_UPDATE_NOTIFICATIONS = "SUCCESS_UPDATE_NOTIFICATIONS";
export const ERROR_UPDATE_NOTIFICATIONS = "ERROR_UPDATE_NOTIFICATIONS";
export const RESET_UPDATE_NOTIFICATIONS = "RESET_UPDATE_NOTIFICATIONS";

function requestUpdateNotifications() {
    return {
        type: REQUEST_UPDATE_NOTIFICATIONS,
}
}

function receiveUpdateNotifications(notifications) {
    return {
        type: SUCCESS_UPDATE_NOTIFICATIONS,
    receivedAt: Date.now(),
        notifications: normalizeDatos(notifications)
}
}

function errorUpdateNotifications(error) {
    return {
        type: ERROR_UPDATE_NOTIFICATIONS,
    error: error,
}
}

export function resetUpdateNotifications() {
    return {
        type: RESET_UPDATE_NOTIFICATIONS,
}
}

export function saveUpdateNotifications() {
    return (dispatch, getState) => {
        dispatch(requestUpdateNotifications());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let notifications = getState().notifications.update.activos.map((idNotification) => {
            return denormalizeDato(getState().notifications.update.activo[idNotification], store);
        });

        return notificationsApi.saveUpdateNotifications(notifications)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateNotifications(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateNotifications(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateNotifications(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateNotifications(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateNotifications(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateNotifications(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA NOTIFICATION
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const REQUEST_CREATE_NOTIFICATION = "REQUEST_CREATE_NOTIFICATION";
export const SUCCESS_CREATE_NOTIFICATION = "SUCCESS_CREATE_NOTIFICATION";
export const ERROR_CREATE_NOTIFICATION = "ERROR_CREATE_NOTIFICATION";
export const RESET_CREATE_NOTIFICATION = "RESET_CREATE_NOTIFICATION";
export const DELETE_CREATE_NOTIFICATION = "DELETE_CREATE_NOTIFICATION";


//ALTA NOTIFICATION
function requestCreateNotification() {
    return {
        type: REQUEST_CREATE_NOTIFICATION,
    }
}

function receiveCreateNotification(notification) {
    return {
        type: SUCCESS_CREATE_NOTIFICATION,
        receivedAt: Date.now(),
        notification: normalizeDato(notification)
    }
}

export function errorCreateNotification(error) {
    return {
        type: ERROR_CREATE_NOTIFICATION,
        error: error,
    }
}

export function resetCreateNotification() {
    return {
        type: RESET_CREATE_NOTIFICATION
    }
}

export function createNotification(notification) {
    return {
        type: CREATE_NOTIFICATION,
        notification
    }
}

export function deleteCreateNotification(notification) {
    return {
        type: DELETE_CREATE_NOTIFICATION,
        notification
    }
}

export function saveCreateNotification() {
    return (dispatch, getState) => {
        dispatch(requestCreateNotification());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let notification = denormalizeDato(getState().notifications.create.nuevo, store);

        return notificationsApi.saveCreate(notification)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateNotification(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateNotification(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                try{
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateNotifications(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateNotifications(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateNotification(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateNotification(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateNotification(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                                    }catch {
                                        dispatch(errorCreateNotification(errorMessages.GENERAL_ERROR));
                                    };
                            }
                        });
    }
}

//CREATE NOTIFICATIONS
export const REQUEST_CREATE_NOTIFICATIONS = "REQUEST_CREATE_NOTIFICATIONS";
export const SUCCESS_CREATE_NOTIFICATIONS = "SUCCESS_CREATE_NOTIFICATIONS";
export const ERROR_CREATE_NOTIFICATIONS = "ERROR_CREATE_NOTIFICATIONS";
export const RESET_CREATE_NOTIFICATIONS = "RESET_CREATE_NOTIFICATIONS";

function requestCreateNotifications() {
    return {
        type: REQUEST_CREATE_NOTIFICATIONS,
}
}

function receiveCreateNotifications(notifications) {
    return {
        type: SUCCESS_CREATE_NOTIFICATIONS,
    receivedAt: Date.now(),
        notifications: normalizeDatos(notifications)
}
}

function errorCreateNotifications(error) {
    return {
        type: ERROR_CREATE_NOTIFICATIONS,
    error: error,
}
}

export function resetCreateNotifications() {
    return {
        type: RESET_CREATE_NOTIFICATIONS,
}
}

export function saveCreateNotifications() {
    return (dispatch, getState) => {
        dispatch(requestCreateNotifications());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let notifications = getState().notifications.create.nuevos.map((idNotification) => {
            return denormalizeDato(getState().notifications.create.nuevo[idNotification], store);
        });

        return notificationsApi.saveCreateNotifications(notifications)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateNotifications(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateNotifications(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateNotifications(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateNotifications(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateNotifications(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateNotifications(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE NOTIFICATION
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const REQUEST_DELETE_NOTIFICATION = "REQUEST_DELETE_NOTIFICATION";
export const SUCCESS_DELETE_NOTIFICATION = "SUCCESS_DELETE_NOTIFICATION";
export const ERROR_DELETE_NOTIFICATION = "ERROR_DELETE_NOTIFICATION";
export const RESET_DELETE_NOTIFICATION = "RESET_DELETE_NOTIFICATION";

function requestDeleteNotification() {
    return {
        type: REQUEST_DELETE_NOTIFICATION,
    }
}

function receiveDeleteNotification(notification) {
    return {
        type: SUCCESS_DELETE_NOTIFICATION,
        receivedAt: Date.now(),
        notification: normalizeDato(notification)
    }
}

function errorDeleteNotification(error) {
    return {
        type: ERROR_DELETE_NOTIFICATION,
        error: error,
    }
}

export function resetDeleteNotification(error) {
    return {
        type: RESET_DELETE_NOTIFICATION,
        error: error,
    }
}

export function deleteNotification(notification) {
    return {
        type: DELETE_NOTIFICATION,
        notification
    }
}

export function saveDeleteNotification(notification) {
    return dispatch => {
        dispatch(requestDeleteNotification());
        return notificationsApi.saveDelete(notification)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteNotification());
                            dispatch(receiveDeleteNotification(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteNotification(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteNotification(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteNotification(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteNotification(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteNotification(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT NOTIFICATION
export const REQUEST_PRINT_NOTIFICATION = "REQUEST_PRINT_NOTIFICATION";
export const SUCCESS_PRINT_NOTIFICATION = "SUCCESS_PRINT_NOTIFICATION";
export const ERROR_PRINT_NOTIFICATION = "ERROR_PRINT_NOTIFICATION";
export const RESET_PRINT_NOTIFICATION = "RESET_PRINT_NOTIFICATION";

function requestPrintNotification() {
    return {
        type: REQUEST_PRINT_NOTIFICATION,
    }
}

function receivePrintNotification(turnos) {
    return {
        type: SUCCESS_PRINT_NOTIFICATION,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintNotification(error) {
    return {
        type: ERROR_PRINT_NOTIFICATION,
        error: error,
    }
}

export function resetPrintNotification() {
    return {
        type: RESET_PRINT_NOTIFICATION,
    }
}

export function printNotification(idNotification) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintNotification());
        return notificationsApi.printNotification(idNotification)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintNotification(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintNotification(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintNotification(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintNotification(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintNotification(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}