//api
import invitationsSchedulesApi from "../api/invitationsSchedulesApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeInvitationsSchedules";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//INVITATIONSSCHEDULES
export const REQUEST_INVITATIONSSCHEDULES = 'REQUEST_INVITATIONSSCHEDULES';
export const RECEIVE_INVITATIONSSCHEDULES = 'RECEIVE_INVITATIONSSCHEDULES';
export const INVALIDATE_INVITATIONSSCHEDULES = 'INVALIDATE_INVITATIONSSCHEDULES';
export const ERROR_INVITATIONSSCHEDULES = "ERROR_INVITATIONSSCHEDULES";
export const RESET_INVITATIONSSCHEDULES = "RESET_INVITATIONSSCHEDULES";


export function invalidateInvitationsSchedules() {
    return {
        type: INVALIDATE_INVITATIONSSCHEDULES
    }
}

function requestInvitationsSchedules() {
    return {
        type: REQUEST_INVITATIONSSCHEDULES,
    }
}

function receiveInvitationsSchedules(json) {
    return {
        type: RECEIVE_INVITATIONSSCHEDULES,
    invitationsSchedules: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorInvitationsSchedules(error) {
    return {
        type: ERROR_INVITATIONSSCHEDULES,
        error: error,
    }
}

export function resetInvitationsSchedules() {
    return {
        type: RESET_INVITATIONSSCHEDULES
    }
}

export function fetchInvitationsSchedules(filtros) {
    return dispatch => {
        dispatch(requestInvitationsSchedules());
        return invitationsSchedulesApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveInvitationsSchedules(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorInvitationsSchedules(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorInvitationsSchedules(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchInvitationsSchedules(state) {
    const invitationsSchedules = state.invitationsSchedules.byId;
    if (!invitationsSchedules) {
        return true
    } else if (invitationsSchedules.isFetching) {
        return false
    } else {
        return invitationsSchedules.didInvalidate;
    }
}

export function fetchInvitationsSchedulesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchInvitationsSchedules(getState())) {
            return dispatch(fetchInvitationsSchedules(filtros))
        }
    }
}


//MODEL
export const REQUEST_INVITATIONSCHEDULE = 'REQUEST_INVITATIONSCHEDULE';
export const RECEIVE_INVITATIONSCHEDULE = 'RECEIVE_INVITATIONSCHEDULE';
export const INVALIDATE_INVITATIONSCHEDULE = 'INVALIDATE_INVITATIONSCHEDULE';
export const ERROR_INVITATIONSCHEDULE = "ERROR_INVITATIONSCHEDULE";
export const RESET_INVITATIONSCHEDULE = "RESET_INVITATIONSCHEDULE";


export function invalidateInvitationSchedule() {
    return {
        type: INVALIDATE_INVITATIONSCHEDULE
    }
}

function requestInvitationSchedule() {
    return {
        type: REQUEST_INVITATIONSCHEDULE,
    }
}

export function receiveInvitationSchedule(json) {
    return {
        type: RECEIVE_INVITATIONSCHEDULE,
    invitationSchedule: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorInvitationSchedule(error) {
    return {
        type: ERROR_INVITATIONSCHEDULE,
        error: error,
    }
}

export function fetchInvitationSchedule(idInvitationSchedule) {
    return dispatch => {
        dispatch(requestInvitationSchedule());
        return invitationsSchedulesApi.getOne(idInvitationSchedule)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveInvitationSchedule(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorInvitationSchedule(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorInvitationSchedule(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_INVITATIONSCHEDULE = 'RECEIVE_FILE_INVITATIONSCHEDULE';

function receiveFileInvitationSchedule(file) {
    return {
        type: RECEIVE_FILE_INVITATIONSCHEDULE,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileInvitationSchedule(idInvitationSchedule, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return invitationsSchedulesApi.getFile(idInvitationSchedule, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileInvitationSchedule(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorInvitationSchedule(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorInvitationSchedule(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_INVITATIONSCHEDULE = 'UPDATE_INVITATIONSCHEDULE';
export const REQUEST_UPDATE_INVITATIONSCHEDULE = "REQUEST_UPDATE_INVITATIONSCHEDULE";
export const SUCCESS_UPDATE_INVITATIONSCHEDULE = "SUCCESS_UPDATE_INVITATIONSCHEDULE";
export const ERROR_UPDATE_INVITATIONSCHEDULE = "ERROR_UPDATE_INVITATIONSCHEDULE";
export const RESET_UPDATE_INVITATIONSCHEDULE = "RESET_UPDATE_INVITATIONSCHEDULE";
export const DELETE_UPDATE_INVITATIONSCHEDULE = "DELETE_UPDATE_INVITATIONSCHEDULE";

function requestUpdateInvitationSchedule() {
    return {
        type: REQUEST_UPDATE_INVITATIONSCHEDULE,
    }
}

function receiveUpdateInvitationSchedule(invitationSchedule) {
    return {
        type: SUCCESS_UPDATE_INVITATIONSCHEDULE,
        receivedAt: Date.now(),
        invitationSchedule: normalizeDato(invitationSchedule)
    }
}

function errorUpdateInvitationSchedule(error) {
    return {
        type: ERROR_UPDATE_INVITATIONSCHEDULE,
        error: error,
    }
}

export function resetUpdateInvitationSchedule() {
    return {
        type: RESET_UPDATE_INVITATIONSCHEDULE,
    }
}

export function updateInvitationSchedule(invitationSchedule) {
    return {
        type: UPDATE_INVITATIONSCHEDULE,
        invitationSchedule
    }
}

export function saveUpdateInvitationSchedule() {
    return (dispatch, getState) => {
        dispatch(requestUpdateInvitationSchedule());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let invitationSchedule = denormalizeDato(getState().invitationsSchedules.update.activo, store);

        return invitationsSchedulesApi.saveUpdate(invitationSchedule)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateInvitationSchedule(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateInvitationSchedule(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                try{
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateInvitationsSchedules(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateInvitationsSchedules(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateInvitationSchedule(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateInvitationSchedule(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateInvitationSchedule(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                                    } catch{
                                            dispatch(errorUpdateInvitationSchedule(errorMessages.GENERAL_ERROR));
                                        };
                            }
                        });
    }
}

export function deleteUpdateInvitationSchedule(invitationSchedule) {
    return {
        type: DELETE_UPDATE_INVITATIONSCHEDULE,
        invitationSchedule
    }
}

//UPDATE INVITATIONSSCHEDULES
export const REQUEST_UPDATE_INVITATIONSSCHEDULES = "REQUEST_UPDATE_INVITATIONSSCHEDULES";
export const SUCCESS_UPDATE_INVITATIONSSCHEDULES = "SUCCESS_UPDATE_INVITATIONSSCHEDULES";
export const ERROR_UPDATE_INVITATIONSSCHEDULES = "ERROR_UPDATE_INVITATIONSSCHEDULES";
export const RESET_UPDATE_INVITATIONSSCHEDULES = "RESET_UPDATE_INVITATIONSSCHEDULES";

function requestUpdateInvitationsSchedules() {
    return {
        type: REQUEST_UPDATE_INVITATIONSSCHEDULES,
}
}

function receiveUpdateInvitationsSchedules(invitationsSchedules) {
    return {
        type: SUCCESS_UPDATE_INVITATIONSSCHEDULES,
    receivedAt: Date.now(),
        invitationsSchedules: normalizeDatos(invitationsSchedules)
}
}

function errorUpdateInvitationsSchedules(error) {
    return {
        type: ERROR_UPDATE_INVITATIONSSCHEDULES,
    error: error,
}
}

export function resetUpdateInvitationsSchedules() {
    return {
        type: RESET_UPDATE_INVITATIONSSCHEDULES,
}
}

export function saveUpdateInvitationsSchedules() {
    return (dispatch, getState) => {
        dispatch(requestUpdateInvitationsSchedules());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let invitationsSchedules = getState().invitationsSchedules.update.activos.map((idInvitationSchedule) => {
            return denormalizeDato(getState().invitationsSchedules.update.activo[idInvitationSchedule], store);
        });

        return invitationsSchedulesApi.saveUpdateInvitationsSchedules(invitationsSchedules)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateInvitationsSchedules(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateInvitationsSchedules(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateInvitationsSchedules(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateInvitationsSchedules(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateInvitationsSchedules(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateInvitationsSchedules(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA INVITATIONSCHEDULE
export const CREATE_INVITATIONSCHEDULE = 'CREATE_INVITATIONSCHEDULE';
export const REQUEST_CREATE_INVITATIONSCHEDULE = "REQUEST_CREATE_INVITATIONSCHEDULE";
export const SUCCESS_CREATE_INVITATIONSCHEDULE = "SUCCESS_CREATE_INVITATIONSCHEDULE";
export const ERROR_CREATE_INVITATIONSCHEDULE = "ERROR_CREATE_INVITATIONSCHEDULE";
export const RESET_CREATE_INVITATIONSCHEDULE = "RESET_CREATE_INVITATIONSCHEDULE";
export const DELETE_CREATE_INVITATIONSCHEDULE = "DELETE_CREATE_INVITATIONSCHEDULE";


//ALTA INVITATIONSCHEDULE
function requestCreateInvitationSchedule() {
    return {
        type: REQUEST_CREATE_INVITATIONSCHEDULE,
    }
}

function receiveCreateInvitationSchedule(invitationSchedule) {
    return {
        type: SUCCESS_CREATE_INVITATIONSCHEDULE,
        receivedAt: Date.now(),
        invitationSchedule: normalizeDato(invitationSchedule)
    }
}

export function errorCreateInvitationSchedule(error) {
    return {
        type: ERROR_CREATE_INVITATIONSCHEDULE,
        error: error,
    }
}

export function resetCreateInvitationSchedule() {
    return {
        type: RESET_CREATE_INVITATIONSCHEDULE
    }
}

export function createInvitationSchedule(invitationSchedule) {
    return {
        type: CREATE_INVITATIONSCHEDULE,
        invitationSchedule
    }
}

export function deleteCreateInvitationSchedule(invitationSchedule) {
    return {
        type: DELETE_CREATE_INVITATIONSCHEDULE,
        invitationSchedule
    }
}

export function saveCreateInvitationSchedule() {
    return (dispatch, getState) => {
        dispatch(requestCreateInvitationSchedule());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let invitationSchedule = denormalizeDato(getState().invitationsSchedules.create.nuevo, store);

        return invitationsSchedulesApi.saveCreate(invitationSchedule)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateInvitationSchedule(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateInvitationSchedule(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                try{
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateInvitationsSchedules(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateInvitationsSchedules(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateInvitationSchedule(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateInvitationSchedule(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateInvitationSchedule(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                                    }catch {
                                        dispatch(errorCreateInvitationSchedule(errorMessages.GENERAL_ERROR));
                                    };
                            }
                        });
    }
}

//CREATE INVITATIONSSCHEDULES
export const REQUEST_CREATE_INVITATIONSSCHEDULES = "REQUEST_CREATE_INVITATIONSSCHEDULES";
export const SUCCESS_CREATE_INVITATIONSSCHEDULES = "SUCCESS_CREATE_INVITATIONSSCHEDULES";
export const ERROR_CREATE_INVITATIONSSCHEDULES = "ERROR_CREATE_INVITATIONSSCHEDULES";
export const RESET_CREATE_INVITATIONSSCHEDULES = "RESET_CREATE_INVITATIONSSCHEDULES";

function requestCreateInvitationsSchedules() {
    return {
        type: REQUEST_CREATE_INVITATIONSSCHEDULES,
}
}

function receiveCreateInvitationsSchedules(invitationsSchedules) {
    return {
        type: SUCCESS_CREATE_INVITATIONSSCHEDULES,
    receivedAt: Date.now(),
        invitationsSchedules: normalizeDatos(invitationsSchedules)
}
}

function errorCreateInvitationsSchedules(error) {
    return {
        type: ERROR_CREATE_INVITATIONSSCHEDULES,
    error: error,
}
}

export function resetCreateInvitationsSchedules() {
    return {
        type: RESET_CREATE_INVITATIONSSCHEDULES,
}
}

export function saveCreateInvitationsSchedules() {
    return (dispatch, getState) => {
        dispatch(requestCreateInvitationsSchedules());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let invitationsSchedules = getState().invitationsSchedules.create.nuevos.map((idInvitationSchedule) => {
            return denormalizeDato(getState().invitationsSchedules.create.nuevo[idInvitationSchedule], store);
        });

        return invitationsSchedulesApi.saveCreateInvitationsSchedules(invitationsSchedules)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateInvitationsSchedules(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateInvitationsSchedules(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateInvitationsSchedules(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateInvitationsSchedules(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateInvitationsSchedules(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateInvitationsSchedules(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE INVITATIONSCHEDULE
export const DELETE_INVITATIONSCHEDULE = 'DELETE_INVITATIONSCHEDULE';
export const REQUEST_DELETE_INVITATIONSCHEDULE = "REQUEST_DELETE_INVITATIONSCHEDULE";
export const SUCCESS_DELETE_INVITATIONSCHEDULE = "SUCCESS_DELETE_INVITATIONSCHEDULE";
export const ERROR_DELETE_INVITATIONSCHEDULE = "ERROR_DELETE_INVITATIONSCHEDULE";
export const RESET_DELETE_INVITATIONSCHEDULE = "RESET_DELETE_INVITATIONSCHEDULE";

function requestDeleteInvitationSchedule() {
    return {
        type: REQUEST_DELETE_INVITATIONSCHEDULE,
    }
}

function receiveDeleteInvitationSchedule(invitationSchedule) {
    return {
        type: SUCCESS_DELETE_INVITATIONSCHEDULE,
        receivedAt: Date.now(),
        invitationSchedule: normalizeDato(invitationSchedule)
    }
}

function errorDeleteInvitationSchedule(error) {
    return {
        type: ERROR_DELETE_INVITATIONSCHEDULE,
        error: error,
    }
}

export function resetDeleteInvitationSchedule(error) {
    return {
        type: RESET_DELETE_INVITATIONSCHEDULE,
        error: error,
    }
}

export function deleteInvitationSchedule(invitationSchedule) {
    return {
        type: DELETE_INVITATIONSCHEDULE,
        invitationSchedule
    }
}

export function saveDeleteInvitationSchedule(invitationSchedule) {
    return dispatch => {
        dispatch(requestDeleteInvitationSchedule());
        return invitationsSchedulesApi.saveDelete(invitationSchedule)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteInvitationSchedule());
                            dispatch(receiveDeleteInvitationSchedule(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteInvitationSchedule(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteInvitationSchedule(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteInvitationSchedule(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteInvitationSchedule(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteInvitationSchedule(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT INVITATIONSCHEDULE
export const REQUEST_PRINT_INVITATIONSCHEDULE = "REQUEST_PRINT_INVITATIONSCHEDULE";
export const SUCCESS_PRINT_INVITATIONSCHEDULE = "SUCCESS_PRINT_INVITATIONSCHEDULE";
export const ERROR_PRINT_INVITATIONSCHEDULE = "ERROR_PRINT_INVITATIONSCHEDULE";
export const RESET_PRINT_INVITATIONSCHEDULE = "RESET_PRINT_INVITATIONSCHEDULE";

function requestPrintInvitationSchedule() {
    return {
        type: REQUEST_PRINT_INVITATIONSCHEDULE,
    }
}

function receivePrintInvitationSchedule(turnos) {
    return {
        type: SUCCESS_PRINT_INVITATIONSCHEDULE,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintInvitationSchedule(error) {
    return {
        type: ERROR_PRINT_INVITATIONSCHEDULE,
        error: error,
    }
}

export function resetPrintInvitationSchedule() {
    return {
        type: RESET_PRINT_INVITATIONSCHEDULE,
    }
}

export function printInvitationSchedule(idInvitationSchedule) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintInvitationSchedule());
        return invitationsSchedulesApi.printInvitationSchedule(idInvitationSchedule)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintInvitationSchedule(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintInvitationSchedule(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintInvitationSchedule(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintInvitationSchedule(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintInvitationSchedule(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}