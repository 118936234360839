import React, { useEffect, useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from "@material-ui/core/Typography";
import * as InvitationStatusC from "../../constants/InvitationStatus";
import { PENDIENTE } from "../../constants/InvitationStatus";
import Grid from "@material-ui/core/Grid";
import CustomPaper from "../../library/CustomPaper/CustomPaper";
import PaperHeader from "../../library/CustomPaper/PaperHeader";
import PaperBody from "../../library/CustomPaper/PaperBody";
import Container from "@material-ui/core/Container";
import { Breadcrumbs, TextField, Tooltip, useTheme } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router";
import {
    fetchInvitation,
    fetchInvitations,
    saveUpdateInvitation,
    updateInvitation
} from "../../actions/InvitationActions";
import userReset from "../../reset/userReset";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import invitationReset from "../../reset/invitationReset";
import moment from "moment";
import Fab from "@material-ui/core/Fab";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { ReactComponent as WhatsAppIcon } from "../../assets/img/whatsappIcon.svg";
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import c from "../../constants/Constants";
import { Skeleton } from "@material-ui/lab";
import InvitationStatusChip from "./InvitationStatusChip";
import max from "lodash/max";
import { createInvitationStatus, saveCreateInvitationStatus } from "../../actions/InvitationStatusActions";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import clone from "lodash/clone";
import CachedIcon from "@material-ui/icons/Cached";

require('es6-promise').polyfill();
require('isomorphic-fetch');

const useStyles = makeStyles(theme => ({
        btn: {
            marginTop: "16px",
            marginBottom: "8px",
            marginLeft: "10px",
            paddingLeft: "30px",
            paddingRight: "30px"
        },
        date: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fff",
            boxShadow: "1px 1px 8px rgba(0,0,0,0.1)",
            borderRadius: "8px",
            minWidth: "100px",
            padding: "5px",
            color: theme.palette.primary["main"],
            position: "relative",
            textAlign: "center",
            "& h4": {
                fontWeight: 900
            },
            "& h5": {
                fontWeight: 900
            },
            "& h6": {
                fontWeight: 900
            },
            [theme.breakpoints.down('xs')]: {
                padding: "2px",
                minWidth: "80px",
            },
        },
        itemShare: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            rowGap: "8px",
        },
        fab: {
            backgroundColor: "white",
        },
        dataList: {
            listStyle: "none",
            padding: 0,
            paddingLeft: "5px",
            // marginBottom: 0,
            "& li": {
                margin: "4px 0",
                display: "flex",
                gap: "4px",
                whiteSpace: "nowrap",
                "& p": {
                    margin: 0,
                    maxWidth: "180px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    ['@media (max-width:280px)']: {
                        display: "none",
                    },
                },
                "& b": {
                    maxWidth: "180px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                },
            }
        },
        containerPhoto: {
            width: "200px",
            height: "200px",
            borderRadius: "20px",
            overflow: "hidden",
            boxShadow: "0 0 15px rgba(0,0,0,0.2)",
            [theme.breakpoints.down('xs')]: {
                width: "60px",
                height: "90px",
                borderRadius: "10px",
                position: "absolute",
                right: 0,
            },
        },
        containerNavButtons: {
            display: "flex",
            justifyContent: "center",
            marginTop: "60px",
            gap: "60px",
            [theme.breakpoints.down('xs')]: {
                gap: "5px",
            },
        },
        containerDates: {
            gap: "12px",
            ['@media (max-width:280px)']: {
                gap: 0
            }
        }
    }))
;

export default function ShareInvitation(props) {
    const classes = useStyles();
    const inputToken = useRef();
    let img = useRef();
    const location = window.location.href.split("invitations");
    let {idInvitation} = useParams();
    const dispatch = useDispatch();
    const theme = useTheme();
    const history = useHistory();

    const [err, setErr] = useState("");
    const [openMenu, setOpenMenu] = useState(null);
    const [refOpenMenu, setRefOpenMenu] = useState(null);

    //Store
    const invitations = useSelector(state => state.invitations);
    const invitation = idInvitation ? invitations.update.activo : invitations.create.nuevo;
    const users = useSelector(state => state.users);
    const user = invitation.idUser ? users.byId.users[invitation.idUser] : null;
    const facePhotos = useSelector(state => state.facePhotos);
    const invitationsStatus = useSelector(state => state.invitationsStatus);
    let facePhoto = user && user.face_photo && user.face_photo.length > 0 ? facePhotos.byId.facePhotos[user.face_photo[0]] : null
    let status = invitationsStatus.byId.invitationsStatus[max(invitation.invitation_status)];

    //Effects
    useEffect(() => {
        window.scrollTo(0, 0);
        if (idInvitation) {
            dispatch(fetchInvitation(idInvitation));
        }

        return function cleanup() {
            invitationReset.resetAll(dispatch);
            userReset.resetAll(dispatch);
        }
    }, [idInvitation]);

    const handleChangeStatus = (idInvitation, desStatus) => {
        let cambio = {desInvitationStatus: desStatus, idInvitation};
        dispatch(createInvitationStatus(cambio));
        dispatch(saveCreateInvitationStatus()).then(() => {
            dispatch(fetchInvitation(idInvitation));
        });
    }

    const copiarToken = (e) => {
        navigator.clipboard.writeText(location[0] + "invitation/" + invitation.token);
    };

    const openInNewTab = (href) => {
        Object.assign(document.createElement('a'), {
            target: '_blank',
            rel: 'noopener noreferrer',
            href: href,
        }).click();
    }

    const share = (href) => {
        if (navigator.share) {
            navigator
                .share({
                    title: "Link de invitación a Spraytec",
                    text: "Completa tus datos para ingresar a nuestras oficinas",
                    url: href,
                })
                .then(() => setErr("Successful share"))
                .catch((error) => setErr("Error sharing"));
        } else {
            setErr("Browser doesn't support Web Share API");
        }
    }

    const handleSynchInvitation = (idInvitation) => {
        let invitation = clone(invitations.byId.invitations[idInvitation]);
        invitation.reSynch = true;
        invitation.inProgress = true;
        dispatch(updateInvitation(invitation));
        dispatch(saveUpdateInvitation([])).then(() => {
            dispatch(fetchInvitations({id: idInvitation, with: ["user", "invitationStatus"]}));
        });
        handleCloseMenu();
    }

    const handleCloseMenu = () => {
        setOpenMenu(null);
        setRefOpenMenu(null)
    }


    const isAutocompleteInvitation = user && user.name && user.lastname && user.dni;

    if (invitations.byId.isFetching && !invitation.id)
        return (
            <Container maxWidth={"lg"}>
                <Breadcrumbs aria-label="breadcrumb" separator={"‣"}>
                    <Link color="inherit" to="/">
                        Home
                    </Link>
                    <Link color="inherit" to="/users">
                        Invitaciones
                    </Link>
                </Breadcrumbs>
                <Typography component={"h1"} variant={"h1"}>
                    {"Link de invitación"}
                </Typography>
                <Grid container spacing={1}>
                    <Grid item md={5} sm={12} xs={12}>
                        <CustomPaper style={{height: "100%"}}>
                            <PaperHeader>
                                <Skeleton variant="text" style={{width: "90%"}}/>
                            </PaperHeader>
                            <PaperBody spacing={2}>
                                <Skeleton variant="rect" style={{width: "100%", height: "40px"}}/>
                                <Box component={"div"}
                                     display={"flex"}
                                     width={"100%"}
                                     justifyContent={"center"}
                                     marginTop={"30px"}
                                     marginBottom={"10px"}
                                     style={{gap: "20px"}}
                                >
                                    {
                                        [...Array(3).keys()].map(
                                            (i) => {
                                                return (
                                                    <div key={i} className={classes.itemShare}>
                                                        <Skeleton variant="circle" width={60} height={60}/>
                                                        <Skeleton variant="text" style={{width: "60px"}}/>
                                                    </div>
                                                )
                                            }
                                        )

                                    }
                                </Box>
                            </PaperBody>
                        </CustomPaper>
                    </Grid>
                    <Grid item md={7} sm={12} xs={12}>
                        <CustomPaper style={{height: "100%"}}>
                            <PaperHeader>
                                <Skeleton variant="text" style={{width: "90%"}}/>
                            </PaperHeader>
                            <PaperBody spacing={2} style={{paddingBottom: 0}}>
                                <Box component={"div"} display={"flex"} width={"100%"} position={"relative"}>
                                    <Box component={"div"} display={"flex"} flexDirection={"column"} flexGrow={1}>
                                        <Box component="div" display={"flex"} className={classes.containerDates}>
                                            <Skeleton variant="rect" style={{width: "90px", height: "80px"}}/>
                                            <Skeleton variant="rect" style={{width: "90px", height: "80px"}}/>
                                        </Box>
                                        <Box className={classes.dataList} component={"ul"}>
                                            <Skeleton variant="text" style={{width: "90%"}}/>
                                            <Skeleton variant="text" style={{width: "50%"}}/>
                                            <Skeleton variant="text" style={{width: "60%"}}/>
                                            <Skeleton variant="text" style={{width: "20%"}}/>
                                            <Skeleton variant="text" style={{width: "55%"}}/>
                                        </Box>
                                    </Box>
                                    <Box component="div" className={classes.containerPhoto}>
                                        <Skeleton variant="rect" style={{width: "100%", height: "100%"}}/>
                                    </Box>
                                </Box>
                            </PaperBody>
                        </CustomPaper>
                    </Grid>
                </Grid>
            </Container>
        )

    if (Object.keys(invitation).length === 0) {
        return (
            <Container maxWidth={"lg"}>
                <Breadcrumbs aria-label="breadcrumb" separator={"‣"}>
                    <Link color="inherit" to="/">
                        Home
                    </Link>
                    <Link color="inherit" to="/users">
                        Invitaciones
                    </Link>
                </Breadcrumbs>
                <Typography component={"h1"} variant={"h1"}>
                    {"Link de invitación"}
                </Typography>
                <Typography component={"h6"} variant={"h6"} style={{marginTop: "30px"}} color={"error"}>
                    No existe invitación
                </Typography>
                <Box component={"div"} display={"flex"} justifyContent={"center"}
                     style={{marginTop: "160px", gap: "60px"}}>
                    <Button style={{color: theme.palette.primary["main"], textTransform: "none"}}
                            onClick={() => history.push("/invitations")}
                    >
                        <Typography style={{fontWeight: 800}}>
                            Volver al listado
                        </Typography>
                    </Button>
                    <Button style={{color: theme.palette.primary["main"], textTransform: "none"}}
                            onClick={() => history.push("/invitations/new")}
                    >
                        <Typography style={{fontWeight: 800}}>
                            Nueva invitación
                        </Typography>
                    </Button>
                </Box>
            </Container>
        )
    }
    return (
        <Container maxWidth={"lg"}>
            <Breadcrumbs aria-label="breadcrumb" separator={"‣"}>
                <Link color="inherit" to="/">
                    Home
                </Link>
                <Link color="inherit" to="/users">
                    Invitaciones
                </Link>
            </Breadcrumbs>
            <Box display={"flex"} alignItems={"center"} alignContent={"center"}>
                <Typography component={"h1"} variant={"h1"} style={{marginRight: "10px"}}>
                    {"Link de invitación"}
                </Typography>
                <InvitationStatusChip
                    desInvitationStatus={status ? status.desInvitationStatus : null}
                    id={idInvitation} handleChangeStatus={handleChangeStatus}/>
            </Box>
            {
                status && status.desInvitationStatus === PENDIENTE && invitation.idUser &&
                <Box>
                    <Typography component={'p'} variant={'subtitle1'}>
                        La invitación requiere confirmación
                    </Typography>
                </Box>
            }
            {
                invitation && invitation.isSynchro === 1 && !invitation.errorSynchro && !invitation.inProgress && status && status.desInvitationStatus === InvitationStatusC.CONFIRMADA &&
                <Box display={"flex"}>
                    <Typography component={'p'} variant={'subtitle1'} style={{marginRight: "10px"}}>
                        El dispositivo esta listo para recibir el invitado
                    </Typography>
                    <Tooltip title="El dispositivo esta listo para recibir el invitado">
                        <IconButton aria-label="editar" size={"small"}>
                            <CheckCircleOutlineIcon color="secondary"/>
                        </IconButton>
                    </Tooltip>
                </Box>
            }
            {
                invitation && invitation.inProgress === 1 && status && status.desInvitationStatus === InvitationStatusC.CONFIRMADA &&
                <Box display={"flex"}>
                    <Typography component={'p'} variant={'subtitle1'} style={{marginRight: "10px"}}>
                        Sincronizando con dispositivo externo
                    </Typography>
                    <Tooltip title="Sincronizando con dispositivo">
                        <IconButton aria-label="editar" size={"small"}>
                            <CachedIcon color="secondary"/>
                        </IconButton>
                    </Tooltip>
                </Box>
            }
            {
                invitation && invitation.isSynchro === 0 && !!invitation.errorSynchro && !invitation.inProgress && status && status.desInvitationStatus === InvitationStatusC.CONFIRMADA &&
                <>
                    <Box display={"flex"}>
                        <Typography component={'p'} variant={'subtitle1'} style={{marginRight: "10px"}}>
                            Hubo un problema con el disposivo externo controle la conexión y vuelva a intentar la
                            sincronización o cancele la invitación.
                        </Typography>
                        <Tooltip
                            title="Hubo un problema con el disposivo externo controle la conexión y vuelva a intentarlo.">
                            <IconButton aria-label="editar" size={"small"}
                                        onClick={(event) => {
                                            setRefOpenMenu(event.currentTarget);
                                            setOpenMenu(idInvitation);
                                            console.log(event.currentTarget, idInvitation)
                                        }}>
                                <ErrorOutlineIcon color="secondary" style={{fill: "#db0d0d"}}/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="simple-menu"
                            anchorEl={refOpenMenu}
                            keepMounted
                            open={openMenu === idInvitation}
                            onClose={handleCloseMenu}
                        >
                            <MenuItem onClick={() => handleSynchInvitation(idInvitation)}>Volver a
                                sincronizar</MenuItem>
                        </Menu>
                    </Box>
                </>
            }
            <Grid container spacing={1}>
                <Grid item md={5} sm={12} xs={12}>
                    <CustomPaper style={{height: "100%"}}>
                        <PaperHeader>
                            <Typography variant={"overline"} component={"span"}>
                                Ahora, compartí tu link de invitación como quieras
                            </Typography>
                        </PaperHeader>
                        <PaperBody spacing={2}>
                            <TextField
                                id="name"
                                variant="outlined"
                                margin={"normal"}
                                InputProps={{
                                    disabled: true,
                                    type: "text"
                                }}
                                disabled={true}
                                fullWidth
                                value={invitation ? location[0] + "invitation/" + invitation.token : ""}
                                inputRef={inputToken}
                            />
                            <Box component={"div"}
                                 display={"flex"}
                                 width={"100%"}
                                 justifyContent={"center"}
                                 marginTop={"30px"}
                                 marginBottom={"10px"}
                                 style={{gap: "20px"}}
                            >
                                <div className={classes.itemShare}>
                                    <Fab className={classes.fab} onClick={copiarToken}>
                                        <FileCopyOutlinedIcon style={{color: "#007fce"}}/>
                                    </Fab>
                                    <Typography variant={"caption"} component={"p"}>
                                        Copiar
                                    </Typography>
                                </div>
                                <div className={classes.itemShare}
                                     onClick={() => openInNewTab(`https://api.whatsapp.com/send?text=${invitation ? location[0] + "invitation/" + invitation.token : ""}`)}>
                                    <Fab className={classes.fab}>
                                        <WhatsAppIcon style={{fill: "#00ce47"}}/>
                                    </Fab>
                                    <Typography variant={"caption"} component={"p"}>
                                        WhatsApp
                                    </Typography>
                                </div>
                                {!!navigator.share &&
                                    <div className={classes.itemShare}
                                         onClick={() => share(`${invitation ? location[0] + "invitation/" + invitation.token : ""}`)}>
                                        <Fab className={classes.fab}>
                                            <ShareOutlinedIcon/>
                                        </Fab>
                                        <Typography variant={"caption"} component={"p"}>
                                            Compartir
                                        </Typography>
                                    </div>
                                }
                            </Box>
                        </PaperBody>
                    </CustomPaper>
                </Grid>
                <Grid item md={7} sm={12} xs={12}>
                    <CustomPaper style={{height: "100%"}}>
                        <PaperHeader>
                            <Typography variant={"overline"} component={"span"}>
                                DATOS DE LA INVITACIÓN
                            </Typography>
                        </PaperHeader>
                        <PaperBody spacing={2} style={{paddingBottom: 0}}>
                            <Box component={"div"} display={"flex"} width={"100%"} position={"relative"}>
                                <Box component={"div"} display={"flex"} flexDirection={"column"} flexGrow={1}>
                                    <Box component="div" display={"flex"} className={classes.containerDates}>
                                        <div className={classes.date}>
                                            <Typography variant={"subtitle2"}
                                                        component={"p"}>
                                                {moment(invitation.dateFrom).format("dddd").replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
                                            </Typography>
                                            <Typography variant={"h4"} component={"h4"}>
                                                {moment(invitation.dateFrom).format("D")}
                                            </Typography>
                                            <Typography variant={"subtitle2"}
                                                        component={"p"}>
                                                {moment(invitation.dateFrom).format("MMMM").replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
                                                {!moment().isSame(moment(invitation.dateFrom), "year") ?  " " + moment(invitation.dateFrom).format("YYYY") : ""}
                                            </Typography>
                                        </div>
                                        {
                                            !moment(invitation.dateFrom).isSame(moment(invitation.dateTo)) &&
                                            <div className={classes.date}>
                                                <Typography variant={"subtitle2"}
                                                            component={"p"}>
                                                    {moment(invitation.dateTo).format("dddd").replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
                                                </Typography>
                                                <Typography variant={"h4"} component={"h4"}>
                                                    {moment(invitation.dateTo).format("D")}
                                                </Typography>
                                                <Typography variant={"subtitle2"}
                                                            component={"p"}>
                                                    {moment(invitation.dateTo).format("MMMM").replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
                                                    {!moment().isSame(moment(invitation.dateTo), "year") ?  " " + moment(invitation.dateTo).format("YYYY") : ""}
                                                </Typography>
                                            </div>

                                        }
                                        {
                                            invitation.timeFrom && invitation.timeTo &&
                                            <div className={classes.date}>
                                                <Typography variant={"h6"} component={"h6"}>
                                                    {moment(invitation.timeFrom, "HH:mm:ss").format("HH:mm")}
                                                </Typography>
                                                <Typography variant={"subtitle2"} component={"p"}>
                                                    A
                                                </Typography>
                                                <Typography variant={"h6"} component={"h6"}>
                                                    {moment(invitation.timeTo, "HH:mm:ss").format("HH:mm")}
                                                </Typography>
                                            </div>
                                        }
                                    </Box>
                                    {
                                        !!isAutocompleteInvitation &&
                                        <Box className={classes.dataList} component={"ul"}>
                                            <Typography component={"li"}>
                                                <p>Nombre: </p><b>{user ? `${user.name} ${user.lastname}` : ""}</b>
                                            </Typography>
                                            <Typography component={"li"}>
                                                <p>DNI: </p><b>{user ? user.dni : ""}</b>
                                            </Typography>
                                            {
                                                user && user.mobilePhone &&
                                                <Typography component={"li"}>
                                                    <p>Telefono: </p><b>{user.mobilePhone}</b>
                                                </Typography>
                                            }
                                            <Typography component={"li"}>
                                                <p>E-mail: </p><b>{user ? user.email : ""}</b>
                                            </Typography>
                                            {
                                                user && user.enterprise &&
                                                <Typography component={"li"}>
                                                    <p>Empresa: </p><b>{user.enterprise}</b>
                                                </Typography>
                                            }
                                        </Box>
                                    }
                                    {
                                        !isAutocompleteInvitation &&
                                        <Box className={classes.dataList} component={"div"}>
                                            <p>
                                                El invitado podrá completar sus datos personales o acceder al QR
                                                generado
                                            </p>
                                        </Box>
                                    }
                                </Box>
                                {
                                    facePhoto &&
                                    <Box component="div" className={classes.containerPhoto}>
                                        <img src={facePhoto ? c.API_HOST + facePhoto.pathThumbnail : ""}
                                             alt=""
                                             style={{width: "100%", height: "100%", objectFit: "cover"}}
                                        />
                                    </Box>
                                }
                            </Box>
                        </PaperBody>
                    </CustomPaper>
                </Grid>
            </Grid>
            <Box className={classes.containerNavButtons}>
                <Button style={{color: theme.palette.primary["main"], textTransform: "none"}}
                        onClick={() => history.push("/invitations")}
                >
                    <Typography style={{fontWeight: 800}}>
                        Volver al listado
                    </Typography>
                </Button>
                <Button style={{color: theme.palette.primary["main"], textTransform: "none"}}
                        onClick={() => history.push("/invitations/new")}
                >
                    <Typography style={{fontWeight: 800}}>
                        Nueva invitación
                    </Typography>
                </Button>
            </Box>
        </Container>
    )
}
;


