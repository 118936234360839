import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
//Components
import {Box, Radio, RadioGroup, Typography} from "@material-ui/core";
import Navigation from "../../library/Navigation";
import style from "../../assets/css/Forms.module.css";
import {withStyles} from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import moment from "moment";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {useValidador} from "../../utils/validador";
import {createInvitation, updateInvitation} from "../../actions/InvitationActions";
import FormHelperText from "@material-ui/core/FormHelperText";

const CustomItemsContainer = withStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        overflowX: "auto",
        flexWrap: "nowrap",
        padding: "20px",
        [theme.breakpoints.down('xs')]: {
            padding: "4px",
            paddingLeft: "18px",
        },
        gap: "10px",
        maskImage: "linear-gradient(to right, transparent, white 10px, white 96%, transparent)",
        "&::-webkit-scrollbar": {
            height: "6px",
            [theme.breakpoints.down('xs')]: {
                display: "none"
            },
        },

        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 1px rgba(0, 0, 0, 0.4)",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            borderTop: "2px solid #fff",
            borderBottom: "2px solid #fff",

        },

        "&::-webkit-scrollbar-thumb": {
            boxShadow: "inset 0 0 " + theme.palette.primary["main"],
            backgroundColor: theme.palette.primary["main"]

        }
    },
}))(RadioGroup);

const ItemBox = withStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#fff",
        boxShadow: "1px 1px 8px rgba(0,0,0,0.1)",
        borderRadius: "8px",
        minWidth: "140px",
        padding: "23px",
        color: theme.palette.primary["main"],
        position: "relative",
        textAlign: "center",
        "& h4": {
            fontWeight: 900
        },
        "& h5": {
            fontWeight: 900
        }
    },
}))(FormControlLabel);

const RadioCustom = withStyles((theme) => ({
    root: {
        position: "absolute",
        right: 0,
        top: 0
    },
}))(Radio);

moment.locale("es");

const schedule = [{timeFrom: "08:00:00", timeTo: "12:00:00", name: "De mañana"},
    {timeFrom: "11:00:00", timeTo: "14:00:00", name: "Al mediodía"},
    {timeFrom: "14:00:00", timeTo: "17:00:00", name: "De tarde"}, {timeFrom: "08:00:00", timeTo: "17:00:00", name: "Durante el día"}];

export default function DateDataPicker(props) {
    const dispatch = useDispatch();
    let {idInvitation} = useParams();
    const msgDate = useRef();
    let {goToStep, prevStep} = props;

    const [otherDate, setOtherDate] = useState(false);
    const [otherTime, setOtherTime] = useState(false);

    const invitations = useSelector(state => state.invitations);
    const users = useSelector(state => state.users);
    const invitation = idInvitation ? invitations.update.activo : invitations.create.nuevo;
    let user = invitation && invitation.idUser && users.byId.users[invitation.idUser] ? users.byId.users[invitation.idUser] : {};
    if (users.update.activos.indexOf(invitation.idUser) !== -1) {
        user = users.update.activo[invitation.idUser];
    }
    if (users.create.nuevos.indexOf(invitation.idUser) !== -1) {
        user = users.create.nuevo[invitation.idUser];
    }

    let reglas = {};
    reglas = {
        name: ["sometimes|required"],
        lastname: ["sometimes|required"],
        email: ["sometimes|required"],
        dni: ["sometimes|required"],
    };
    const [validador, validar] = useValidador(reglas);
    let customTime = null;

    //Effects
    useEffect(() => {
        window.scrollTo(0, 0);
        if (idInvitation) {
            setOtherDate(true);
        }

        return function cleanup() {
        }
    }, [idInvitation]);

    const handleChangeDate = (fecha, id) => {
        msgDate.current.style.display = "none";
        let cambio = {};
        if (fecha && fecha.isValid()) {
            cambio[id] = fecha.format("YYYY-MM-DD");
            if (id === "dateFrom")
                cambio["dateTo"] = fecha.format("YYYY-MM-DD");
            changeInvitation(cambio);
        }
    };

    const handleChangeTimeSchedule = (schedule) => {
        msgDate.current.style.display = "none";
        let cambio = {};
        cambio["timeFrom"] = schedule.timeFrom;
        cambio["timeTo"] = schedule.timeTo;
        changeInvitation(cambio);
    }

    const handleChangeTime = (fecha, id) => {
        msgDate.current.style.display = "none";
        let cambio = {};
        if (fecha && fecha.isValid()) {
            cambio[id] = fecha.format("HH:mm:ss");
            changeInvitation(cambio);
        }
    };


    const changeInvitation = (cambio) => {
        if (idInvitation)
            dispatch(updateInvitation(cambio));
        else
            dispatch(createInvitation(cambio));
    };

    const onSubmit = (e) => {
        if (e)
            e.preventDefault()
        if (!invitation.dateFrom || !invitation.timeFrom || !invitation.timeTo) {
            msgDate.current.style.display = "inline";
            msgDate.current.scrollIntoView({
                behavior: "smooth", block: "center"
            });
        } else
            goToStep(user.id === -1 ? "selfie" : "confirmacion");
    }

    return (
        <form onSubmit={onSubmit} className={style.containerForm}>
            {
                invitation && !!invitation.permanentAccess &&
                <Typography variant={"body1"} component={"h1"} color={"primary"}>
                    Se le concederá acceso permanente a nuestra oficina
                </Typography>
            }
            {
                invitation && !invitation.permanentAccess &&
                <>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography variant={"body1"} component={"h1"} color={"primary"}>
                            Seleccione fecha

                        </Typography>
                        <Button variant="outlined" onClick={() => setOtherDate(!otherDate)}>Otra
                            fecha</Button>
                    </Box>
                    {
                        otherDate === false &&
                        <CustomItemsContainer>
                            {
                                [...Array(15).keys()].map((i) => {
                                    let date = moment().add(i, "days");
                                    return (
                                        <ItemBox key={"day" + i} value="female"
                                                 onClick={() => handleChangeDate(date, "dateFrom")}
                                                 control={<RadioCustom
                                                     checked={invitation && invitation.dateFrom ? moment(invitation.dateFrom).isSame(date, "day") : false}/>}
                                                 label={
                                                     <div>
                                                         <Typography variant={"subtitle2"}
                                                                     component={"p"}>
                                                             {date.format("dddd")}
                                                         </Typography>
                                                         <Typography variant={"h4"} component={"h4"}>
                                                             {date.format("D")}
                                                         </Typography>
                                                         <Typography variant={"subtitle2"}
                                                                     component={"p"}>
                                                             {date.format("MMMM")}
                                                         </Typography>
                                                     </div>
                                                 }
                                        />);
                                })
                            }
                        </CustomItemsContainer>
                    }
                    {
                        otherDate === true &&
                        <Box display={"flex"} justifyContent={"flex-start"}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    style={{marginRight: "20px"}}
                                    label="Fecha desde"
                                    format="DD/MM/yyyy"
                                    value={invitation ? invitation.dateFrom : null}
                                    onChange={(e) => handleChangeDate(e, "dateFrom")}
                                    animateYearScrolling
                                />
                                <KeyboardDatePicker
                                    label="Fecha hasta"
                                    format="DD/MM/yyyy"
                                    minDate={invitation ? invitation.dateFrom : null}
                                    value={invitation && invitation.dateTo ? invitation.dateTo : null}
                                    onChange={(e) => handleChangeDate(e, "dateTo")}
                                    animateYearScrolling
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                    }

                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography variant={"body1"} component={"h1"} color={"primary"}>
                            Seleccione hora

                        </Typography>
                        <Button variant="outlined" onClick={() => setOtherTime(!otherTime)}>Otro
                            horario</Button>
                    </Box>
                    {
                        otherTime === false &&
                        <CustomItemsContainer>
                            {
                                schedule.map((item, i) => {
                                    let timeFrom = moment(item.timeFrom, "HH:mm:ss");
                                    let timeTo = moment(item.timeTo, "HH:mm:ss");
                                    if (item && invitation && invitation.timeFrom === item.timeFrom && invitation.timeTo === item.timeTo)
                                        customTime = true;
                                    return (
                                        <ItemBox key={"time" + i} value="female"
                                                 onClick={() => handleChangeTimeSchedule(item)}
                                                 control={<RadioCustom
                                                     checked={item && invitation && invitation.timeFrom === item.timeFrom && invitation.timeTo === item.timeTo}/>}
                                                 label={
                                                     <div>
                                                         <Typography variant={"caption"}
                                                                     component={"h5"}>
                                                             {item ? item.name : ""}
                                                         </Typography>
                                                         <Typography variant={"h5"} component={"h5"}>
                                                             {timeFrom ? timeFrom.format("HH:mm") : ""}
                                                         </Typography>
                                                         <Typography variant={"subtitle2"}
                                                                     component={"p"}>
                                                             A
                                                         </Typography>
                                                         <Typography variant={"h5"} component={"h5"}>
                                                             {timeTo ? timeTo.format("HH:mm") : ""}
                                                         </Typography>
                                                     </div>
                                                 }
                                        />);
                                })
                            }
                            {
                                !customTime && !!idInvitation &&
                                <ItemBox value="female"
                                         onClick={() => setOtherTime(true)}
                                         control={<RadioCustom
                                             checked={true}/>}
                                         label={
                                             <div>
                                                 <Typography variant={"caption"}
                                                             component={"h5"}>
                                                     {"Otro"}
                                                 </Typography>
                                                 <Typography variant={"h5"} component={"h5"}>
                                                     {invitation.timeFrom ? moment(invitation.timeFrom, "HH:mm:ss").format("HH:mm") : ""}
                                                 </Typography>
                                                 <Typography variant={"subtitle2"}
                                                             component={"p"}>
                                                     A
                                                 </Typography>
                                                 <Typography variant={"h5"} component={"h5"}>
                                                     {invitation.timeTo ? moment(invitation.timeTo, "HH:mm:ss").format("HH:mm") : ""}
                                                 </Typography>
                                             </div>
                                         }
                                />
                            }
                        </CustomItemsContainer>
                    }
                    {
                        otherTime === true &&
                        <Box display={"flex"} justifyContent={"flex-start"}>
                            <KeyboardTimePicker
                                style={{marginRight: "20px"}}
                                label="Hora desde"
                                ampm={false}
                                format="HH:mm"
                                mask="__:__"
                                value={invitation && invitation.timeFrom ? moment(invitation.timeFrom, "HH:mm").format("YYYY-MM-DD HH:mm") : null}
                                onChange={date => handleChangeTime(date, "timeFrom")}
                            />
                            <KeyboardTimePicker
                                label="Hora hasta"
                                ampm={false}
                                format="HH:mm"
                                mask="__:__"
                                value={invitation && invitation.timeTo ? moment(invitation.timeTo, "HH:mm").format("YYYY-MM-DD HH:mm") : null}
                                onChange={date => handleChangeTime(date, "timeTo")}
                            />
                        </Box>
                    }
                    <FormHelperText ref={msgDate} style={{display: "none", color: "red"}}>
                        Debe completar la fecha y la hora de la invitación
                    </FormHelperText>
                </>
            }
            <Navigation next={"selfie"} goToStep={goToStep} prevStep={prevStep}/>
        </form>
    );
}

