import {normalize, schema, denormalize} from 'normalizr';

const invitationStatus = new schema.Entity('invitationsStatus', {}, {idAttribute: "id"});

export function normalizeDatos(myData) {

    const mySchema = {invitationStatus: [invitationStatus]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = invitationStatus;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = invitationStatus;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default invitationStatus;
