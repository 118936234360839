import React from 'react';

import {makeStyles} from '@material-ui/core/styles';
import Chip from "@material-ui/core/Chip";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from "@material-ui/core/Typography";
import * as InvitationStatusC from "../../constants/InvitationStatus";
import clx from "clsx";
import {useSelector} from "react-redux";


require('es6-promise').polyfill();
require('isomorphic-fetch');

const useStyles = makeStyles(theme => ({
        danger: {
            backgroundColor: theme.palette.error.main,
            color: "white"
        },
        success: {
            backgroundColor: theme.palette.success.main,
            color: "white"
        },
        warning: {
            backgroundColor: theme.palette.warning.light,
            color: "white"
        },
        columnaEstado: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            "& p": {
                color: "rgba(0,0,0,0.6)",
                fontWeight: 300,
                fontSize: "smaller",
                marginBottom: 0,
                marginTop: "3px",
                padding: 0,
            }
        },
        chipsRoot: {
            height: "30px",
            minWidth: "100px",
            [theme.breakpoints.down('sm')]: {
                minWidth: "85px"
            }
        }

    }))
;

export default function InvitationStatusChip(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const invitations = useSelector(state => state.invitations);
    let invitation = props.id && invitations.byId.invitations[props.id] ? invitations.byId.invitations[props.id] : {};
    if (invitations.update.activos.indexOf(props.id) !== -1) {
        invitation = invitations.update.activo[props.id];
    }
    if (invitations.create.nuevos.indexOf(props.id) !== -1) {
        invitation = invitations.create.nuevo[props.id];
    }

    const menuDesplegable = props.menuDesplegable !== undefined ? props.menuDesplegable : true;
    const size = props.size !== undefined ? props.size : "small";

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickMenu = (desEstadoTurnoNuevo) => {
        props.handleChangeStatus(props.id, desEstadoTurnoNuevo);
        setAnchorEl(null);
    };

    const getChip = () => {
        switch (props.desInvitationStatus) {
            case InvitationStatusC.PENDIENTE:
                return <Chip size={size} className={clx(classes.warning, classes.chipsRoot)}
                             id={props.id + props.desInvitationStatus + "chip"}
                             label={InvitationStatusC.PENDIENTE} onClick={handleClick}/>;
            case InvitationStatusC.CONFIRMADA:
                return <Chip size={size} id={props.id + props.desInvitationStatus + "chip"}
                             className={clx(classes.success, classes.chipsRoot)}
                             label={InvitationStatusC.CONFIRMADA} onClick={handleClick}/>;
            case InvitationStatusC.CANCELADA:
                return <Chip size={size} id={props.id + props.desInvitationStatus + "chip"}
                             className={clx(classes.danger, classes.chipsRoot)}
                             label={InvitationStatusC.CANCELADA} onClick={handleClick}/>;
            case InvitationStatusC.EXPIRADA:
                return <Chip size={size} id={props.id + props.desInvitationStatus + "chip"}
                             className={clx(classes.danger, classes.chipsRoot)}
                             label={InvitationStatusC.EXPIRADA} onClick={handleClick}/>;
            default:
                return <Chip size={size} id={props.id + props.desInvitationStatus + "chip"}
                             label={props.desInvitationStatus} className={classes.chipsRoot}/>;
        }
    }

    const getMenuItems = () => {
        switch (props.desInvitationStatus) {
            case InvitationStatusC.CONFIRMADA:
                return [InvitationStatusC.CANCELADA, InvitationStatusC.PENDIENTE];
            case InvitationStatusC.PENDIENTE:
                return (invitation && invitation.idUser ? [InvitationStatusC.CANCELADA, InvitationStatusC.CONFIRMADA] : [InvitationStatusC.CANCELADA]);
            case InvitationStatusC.CANCELADA:
                return [InvitationStatusC.CONFIRMADA, InvitationStatusC.PENDIENTE];
            case InvitationStatusC.EXPIRADA:
                return [];
            default:
                return [];
        }
    }


    return (
        <div>
            <div className={classes.columnaEstado}>
                {getChip()}
                {/*<Typography variant={"body2"}*/}
                {/*            component={"p"}>{props.excepcion ? props.excepcion.desExcepcion : ""}</Typography>*/}
            </div>
            {
                menuDesplegable &&
                <Menu
                    id={props.id + props.desInvitationStatus}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {
                        getMenuItems().map((desStatus) => {
                            return <MenuItem key={props.id + desStatus}
                                             onClick={() => handleClickMenu(desStatus)}>{desStatus}</MenuItem>
                        })
                    }
                </Menu>
            }
        </div>
    )
};


