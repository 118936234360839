import React, {useEffect, useState} from 'react';
import {Link, Route, Switch, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
//Components
import style from "../../assets/css/Invitation.module.css";
import {Button, CircularProgress, Typography} from "@material-ui/core";
import logo from "../../assets/img/logoWhite.svg";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import clsx from "clsx";
import Dni from "../Forms/Dni";
import PersonalData from "../Forms/PersonalData";
import DateDataPicker from "../Forms/DateDataPicker";
import Selfie from "../Forms/Selfie";
import Confirmation from "../Forms/Confirmation";
import Message from "../Forms/Message";
import {fetchInvitation} from "../../actions/InvitationActions";
import InvitationData from "../Forms/InvitationData";
import max from "lodash/max";
import * as InvitationStatusC from "../../constants/InvitationStatus";

export default function Invitation() {
    const dispatch = useDispatch();
    let {idInvitation} = useParams();
    const [step, setStep] = useState(null);
    const [files, setFiles] = React.useState([]);
    const steps = ["dni", "datosPersonales", "fechaHora", "selfie", "confirmacion", "registroExitoso", "datosInvitacion"];

    const invitations = useSelector(state => state.invitations);
    const invitation = idInvitation ? invitations.update.activo : invitations.create.nuevo;
    const invitationsStatus = useSelector(state => state.invitationsStatus);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (idInvitation) {
            dispatch(fetchInvitation(idInvitation));
        }

        return function cleanup() {
        }
    }, [idInvitation]);

    useEffect(() => {
        if (invitation) {
            let status = invitationsStatus.byId.invitationsStatus[max(invitation.invitation_status)];
            if (invitation.idUser && status && status.desInvitationStatus === InvitationStatusC.CONFIRMADA)
                goToStep("datosInvitacion");
        }
        return function cleanup() {
        }
    }, [invitation]);

    const prevStep = () => {
        let prevStep = steps.indexOf(step) - 1;
        setStep(steps[prevStep]);
    }
    const nextStep = () => {
        let prevStep = steps.indexOf(step) + 1;
        setStep(steps[prevStep]);
    }
    const goToStep = (step) => {
        let prevStep = steps.indexOf(step);
        setStep(steps[prevStep]);
    }
    //Effects
    useEffect(() => {
        return function cleanup() {
        }

    }, []);

    const selectForm = (formSelected) => {
        switch (formSelected) {
            case "dni":
                return <Dni goToStep={goToStep}/>
            case "datosPersonales":
                return <PersonalData goToStep={goToStep} prevStep={prevStep}/>
            case "fechaHora":
                return <DateDataPicker goToStep={goToStep} prevStep={prevStep}/>
            case "selfie":
                return <Selfie goToStep={goToStep} prevStep={prevStep} files={files} setFiles={setFiles}/>
            case "confirmacion":
                return <Confirmation goToStep={goToStep} prevStep={prevStep} files={files}/>
            case "registroExitoso":
                return <Message/>
            case "datosInvitacion":
                return <InvitationData/>
        }
    }

    return (
        <div className={clsx([style.invitationWrapper, {[style.openForm]: !!step}])}>
            <div className={style.invitationContainer}>
                {
                    !step &&
                    <Typography variant={"h1"} component={"h1"}>
                        Te damos la bienvenida a
                    </Typography>
                }
                <img src={logo} alt="" style={{maxWidth: "250px", maxHeight: "250px"}} className={style.logo}/>
                {
                    !step &&
                    <>
                        <Typography variant={"subtitle1"} component={"p"} className={style.invitationInfo}>
                            Gestionaremos tu invitación para acceder a nuestras oficinas
                        </Typography>
                        {
                            (!idInvitation || (invitation && invitation.id && !invitation.idUser)) ?
                                <Button variant={"outlined"}
                                        onClick={() => setStep("dni")}
                                        className={style.btnIn}
                                        size={"large"}
                                        startIcon={<ExitToAppIcon/>}
                                        component={"a"}
                                >
                                    Ingresar
                                </Button>
                                :
                                ((!idInvitation || (invitation && invitation.id && !!invitation.idUser)) ?
                                    <Typography variant={"subtitle1"} component={"p"} className={style.invitationInfo}>
                                        Sus datos ya fueron registrados. Le avisaremos cuando su invitación este confirmada.
                                    </Typography>
                                    :
                                    <CircularProgress style={{color: "white"}}/>)
                        }
                    </>
                }
            </div>

            <div className={style.formsWrapper}>
                <Switch>
                    {selectForm(step)}
                </Switch>
            </div>
        </div>
    );
}

