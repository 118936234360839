import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
//IMG
//Actions
import { useHistory } from "react-router";
import { Badge, Popover, styled, Typography } from "@material-ui/core";
import { fetchDevicesIfNeeded } from "../../actions/DeviceActions";

window.Pusher = require('pusher-js');

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
        "& h2": {
            display: "flex",
            margin: "10px 0"
        }
    },
    paper: {
        padding: theme.spacing(1, 3),
    },
}));

const StyledBadge = styled(Badge)({
    "& .MuiBadge-colorPrimary": {
        color: "red",
        backgroundColor: "#21e600"
    },
});


export default function Status(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const devices = useSelector(state => state.devices);

    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        dispatch(fetchDevicesIfNeeded({status: 1}));
    }, []);

    let error = false;
    let devicesStatus = devices.allIds.map((idDevice) => {
        let device = devices.byId.devices[idDevice];
        if (!!device.error)
            error = true;
        return (
            <>
                <Typography variant={"h2"}>
                    Dispositivo:&nbsp;
                    <Typography variant={"body2"}>
                        {/*Access Controller | DS-K1T671M*/}
                        {`${device.name} | ${device.model}`}
                    </Typography>
                </Typography>
                <Typography variant={"h2"}>
                    Estado:&nbsp;
                    <StyledBadge
                        badgeContent={" "}
                        color={error ? "error" : "primary"}
                        variant="dot"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <Typography variant={"body2"}>
                            {error ? "Desconectado 😔" : "Conectado  🚀"}
                        </Typography>
                    </StyledBadge>
                </Typography>
                <Typography variant={"h2"}>
                    MAC:&nbsp;
                    <Typography variant={"body2"}>
                        {/*24:28:FD:51:F7:8A*/}
                        {device.macAddress}
                    </Typography>
                </Typography>
                <Typography variant={"h2"}>
                    Ult. conexión:&nbsp;
                    <Typography variant={"body2"}>
                        {/*20/06/2022 10:10:23*/}
                        {device.lastConnection_at}
                    </Typography>
                </Typography>
            </>
        )

    })
    return (
        <>

            <IconButton
                id={"syncDevice"}
                edge="end"
                aria-label="Icono de sinc"
                color="primary"
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                <StyledBadge
                    badgeContent={" "}
                    color={error ? "error" : "primary"}
                    variant="dot"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <SettingsEthernetIcon/>
                </StyledBadge>
            </IconButton>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {devicesStatus}
            </Popover>
        </>
    );
}
