import React from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
//Components
import {Box, Typography, useTheme,} from "@material-ui/core";
import style from "../../assets/css/InvitationData.module.css";
import FormHelperText from "@material-ui/core/FormHelperText";
import QRCode from "react-qr-code";
import moment from "moment";

export default function InvitationData(props) {
    let {goToStep, prevStep, files, setFiles} = props;
    const [filename, setFileName] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [openPhotoManager, setOpenPhotoManager] = React.useState(false);
    const theme = useTheme();
    let img = React.useRef();
    let msj = React.useRef();

    const dispatch = useDispatch();
    let {idInvitation} = useParams();

    //Store
    const invitations = useSelector(state => state.invitations);
    const invitation = idInvitation ? invitations.update.activo : invitations.create.nuevo;

    const users = useSelector(state => state.users);
    const user = users ? users.byId.users[invitation.idUser] : null;
    console.log("user", user);
    const facePhotos = useSelector(state => state.facePhotos);

    const onSubmit = (e) => {
        // if (e)
        //     e.preventDefault()
        // if (user && ((user.face_photo && user.face_photo.length === 0) || !user.face_photo)) {
        //     msj.current.style.display = "inline";
        //     msj.current.scrollIntoView({
        //         behavior: "smooth", block: "center"
        //     });
        // } else
        //     goToStep("confirmacion");
    }

    return (
        <form onSubmit={onSubmit} className={style.containerForm}>
            <Typography variant={"h1"} component={"h1"} color={"primary"}>
                Datos de invitación
            </Typography>
            {
                user && user.externalId &&
                <Box component={"div"} className={style.containerQR}>
                    <QRCode value={user ? user.externalId : ""} size={180}/>
                </Box>
            }

            <Box component={"div"} className={style.containerInfo}>
                <Typography variant={"body1"}>
                    Estimado/a&nbsp;
                    <b>{user ? user.name + " " + user.lastname : ""}</b>&nbsp;
                    presentando este código QR en el ingreso de nuestras oficinas podrá acceder automaticamente.
                </Typography>
            </Box>

            <ul className={style.listConfirmation}>
                <li>
                    <Typography component={"a"} variant={"body1"}>
                        Fecha:&nbsp;
                    </Typography>
                    <Typography component={"b"} variant={"body1"}>
                        {invitation && invitation.dateFrom ? moment(invitation.dateFrom).format("dddd DD [de] MMMM [de] YYYY") : ""}
                    </Typography>
                </li>
                {
                    !moment(invitation.dateFrom).isSame(moment(invitation.dateTo)) &&
                    <li>
                        <Typography component={"a"} variant={"body1"}>
                            Fecha hasta:&nbsp;
                        </Typography>
                        <Typography component={"b"} variant={"body1"}>
                            {invitation && invitation.dateTo ? moment(invitation.dateTo).format("dddd DD [de] MMMM [de] YYYY") : ""}
                        </Typography>
                    </li>
                }
                <li>
                    <Typography component={"a"} variant={"body1"}>
                        Horario:&nbsp;
                    </Typography>
                    <Typography component={"b"} variant={"body1"}>
                        {`${invitation && invitation.timeFrom ? moment(invitation.timeFrom, "HH:mm:ss").format("HH:mm") : ""} a ${invitation && invitation.timeTo ? moment(invitation.timeTo, "HH:mm:ss").format("HH:mm") : ""}`}
                    </Typography>
                </li>
                <li>
                    <Typography component={"a"} variant={"body1"}>
                        Dirección&nbsp;
                    </Typography>
                    <Typography component={"b"} variant={"body1"}>
                        <a href="https://goo.gl/maps/Z73w6NxCv9BgH7Ne9" target={"_blank"}>
                            Torre Nordlink Piso 3 Of. 3, Av. Madres Plaza 25 de Mayo 3020, Rosario, Santa Fe, Argentina
                        </a>
                    </Typography>
                </li>
            </ul>
        </form>
    );
}

