//api
import invitationsStatusApi from "../api/invitationsStatusApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeInvitationsStatus";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//INVITATIONSSTATUS
export const REQUEST_INVITATIONSSTATUS = 'REQUEST_INVITATIONSSTATUS';
export const RECEIVE_INVITATIONSSTATUS = 'RECEIVE_INVITATIONSSTATUS';
export const INVALIDATE_INVITATIONSSTATUS = 'INVALIDATE_INVITATIONSSTATUS';
export const ERROR_INVITATIONSSTATUS = "ERROR_INVITATIONSSTATUS";
export const RESET_INVITATIONSSTATUS = "RESET_INVITATIONSSTATUS";


export function invalidateInvitationsStatus() {
    return {
        type: INVALIDATE_INVITATIONSSTATUS
    }
}

function requestInvitationsStatus() {
    return {
        type: REQUEST_INVITATIONSSTATUS,
    }
}

function receiveInvitationsStatus(json) {
    return {
        type: RECEIVE_INVITATIONSSTATUS,
    invitationsStatus: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorInvitationsStatus(error) {
    return {
        type: ERROR_INVITATIONSSTATUS,
        error: error,
    }
}

export function resetInvitationsStatus() {
    return {
        type: RESET_INVITATIONSSTATUS
    }
}

export function fetchInvitationsStatus(filtros) {
    return dispatch => {
        dispatch(requestInvitationsStatus());
        return invitationsStatusApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveInvitationsStatus(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorInvitationsStatus(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorInvitationsStatus(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchInvitationsStatus(state) {
    const invitationsStatus = state.invitationsStatus.byId;
    if (!invitationsStatus) {
        return true
    } else if (invitationsStatus.isFetching) {
        return false
    } else {
        return invitationsStatus.didInvalidate;
    }
}

export function fetchInvitationsStatusIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchInvitationsStatus(getState())) {
            return dispatch(fetchInvitationsStatus(filtros))
        }
    }
}


//MODEL
export const REQUEST_INVITATIONSTATUS = 'REQUEST_INVITATIONSTATUS';
export const RECEIVE_INVITATIONSTATUS = 'RECEIVE_INVITATIONSTATUS';
export const INVALIDATE_INVITATIONSTATUS = 'INVALIDATE_INVITATIONSTATUS';
export const ERROR_INVITATIONSTATUS = "ERROR_INVITATIONSTATUS";
export const RESET_INVITATIONSTATUS = "RESET_INVITATIONSTATUS";


export function invalidateInvitationStatus() {
    return {
        type: INVALIDATE_INVITATIONSTATUS
    }
}

function requestInvitationStatus() {
    return {
        type: REQUEST_INVITATIONSTATUS,
    }
}

export function receiveInvitationStatus(json) {
    return {
        type: RECEIVE_INVITATIONSTATUS,
    invitationStatus: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorInvitationStatus(error) {
    return {
        type: ERROR_INVITATIONSTATUS,
        error: error,
    }
}

export function fetchInvitationStatus(idInvitationStatus) {
    return dispatch => {
        dispatch(requestInvitationStatus());
        return invitationsStatusApi.getOne(idInvitationStatus)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveInvitationStatus(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorInvitationStatus(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorInvitationStatus(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_INVITATIONSTATUS = 'RECEIVE_FILE_INVITATIONSTATUS';

function receiveFileInvitationStatus(file) {
    return {
        type: RECEIVE_FILE_INVITATIONSTATUS,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileInvitationStatus(idInvitationStatus, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return invitationsStatusApi.getFile(idInvitationStatus, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileInvitationStatus(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorInvitationStatus(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorInvitationStatus(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_INVITATIONSTATUS = 'UPDATE_INVITATIONSTATUS';
export const REQUEST_UPDATE_INVITATIONSTATUS = "REQUEST_UPDATE_INVITATIONSTATUS";
export const SUCCESS_UPDATE_INVITATIONSTATUS = "SUCCESS_UPDATE_INVITATIONSTATUS";
export const ERROR_UPDATE_INVITATIONSTATUS = "ERROR_UPDATE_INVITATIONSTATUS";
export const RESET_UPDATE_INVITATIONSTATUS = "RESET_UPDATE_INVITATIONSTATUS";
export const DELETE_UPDATE_INVITATIONSTATUS = "DELETE_UPDATE_INVITATIONSTATUS";

function requestUpdateInvitationStatus() {
    return {
        type: REQUEST_UPDATE_INVITATIONSTATUS,
    }
}

function receiveUpdateInvitationStatus(invitationStatus) {
    return {
        type: SUCCESS_UPDATE_INVITATIONSTATUS,
        receivedAt: Date.now(),
        invitationStatus: normalizeDato(invitationStatus)
    }
}

function errorUpdateInvitationStatus(error) {
    return {
        type: ERROR_UPDATE_INVITATIONSTATUS,
        error: error,
    }
}

export function resetUpdateInvitationStatus() {
    return {
        type: RESET_UPDATE_INVITATIONSTATUS,
    }
}

export function updateInvitationStatus(invitationStatus) {
    return {
        type: UPDATE_INVITATIONSTATUS,
        invitationStatus
    }
}

export function saveUpdateInvitationStatus() {
    return (dispatch, getState) => {
        dispatch(requestUpdateInvitationStatus());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let invitationStatus = denormalizeDato(getState().invitationsStatus.update.activo, store);

        return invitationsStatusApi.saveUpdate(invitationStatus)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateInvitationStatus(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateInvitationStatus(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                try{
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateInvitationsStatus(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateInvitationsStatus(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateInvitationStatus(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateInvitationStatus(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateInvitationStatus(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                                    } catch{
                                            dispatch(errorUpdateInvitationStatus(errorMessages.GENERAL_ERROR));
                                        };
                            }
                        });
    }
}

export function deleteUpdateInvitationStatus(invitationStatus) {
    return {
        type: DELETE_UPDATE_INVITATIONSTATUS,
        invitationStatus
    }
}

//UPDATE INVITATIONSSTATUS
export const REQUEST_UPDATE_INVITATIONSSTATUS = "REQUEST_UPDATE_INVITATIONSSTATUS";
export const SUCCESS_UPDATE_INVITATIONSSTATUS = "SUCCESS_UPDATE_INVITATIONSSTATUS";
export const ERROR_UPDATE_INVITATIONSSTATUS = "ERROR_UPDATE_INVITATIONSSTATUS";
export const RESET_UPDATE_INVITATIONSSTATUS = "RESET_UPDATE_INVITATIONSSTATUS";

function requestUpdateInvitationsStatus() {
    return {
        type: REQUEST_UPDATE_INVITATIONSSTATUS,
}
}

function receiveUpdateInvitationsStatus(invitationsStatus) {
    return {
        type: SUCCESS_UPDATE_INVITATIONSSTATUS,
    receivedAt: Date.now(),
        invitationsStatus: normalizeDatos(invitationsStatus)
}
}

function errorUpdateInvitationsStatus(error) {
    return {
        type: ERROR_UPDATE_INVITATIONSSTATUS,
    error: error,
}
}

export function resetUpdateInvitationsStatus() {
    return {
        type: RESET_UPDATE_INVITATIONSSTATUS,
}
}

export function saveUpdateInvitationsStatus() {
    return (dispatch, getState) => {
        dispatch(requestUpdateInvitationsStatus());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let invitationsStatus = getState().invitationsStatus.update.activos.map((idInvitationStatus) => {
            return denormalizeDato(getState().invitationsStatus.update.activo[idInvitationStatus], store);
        });

        return invitationsStatusApi.saveUpdateInvitationsStatus(invitationsStatus)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateInvitationsStatus(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateInvitationsStatus(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateInvitationsStatus(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateInvitationsStatus(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateInvitationsStatus(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateInvitationsStatus(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA INVITATIONSTATUS
export const CREATE_INVITATIONSTATUS = 'CREATE_INVITATIONSTATUS';
export const REQUEST_CREATE_INVITATIONSTATUS = "REQUEST_CREATE_INVITATIONSTATUS";
export const SUCCESS_CREATE_INVITATIONSTATUS = "SUCCESS_CREATE_INVITATIONSTATUS";
export const ERROR_CREATE_INVITATIONSTATUS = "ERROR_CREATE_INVITATIONSTATUS";
export const RESET_CREATE_INVITATIONSTATUS = "RESET_CREATE_INVITATIONSTATUS";
export const DELETE_CREATE_INVITATIONSTATUS = "DELETE_CREATE_INVITATIONSTATUS";


//ALTA INVITATIONSTATUS
function requestCreateInvitationStatus() {
    return {
        type: REQUEST_CREATE_INVITATIONSTATUS,
    }
}

function receiveCreateInvitationStatus(invitationStatus) {
    return {
        type: SUCCESS_CREATE_INVITATIONSTATUS,
        receivedAt: Date.now(),
        invitationStatus: normalizeDato(invitationStatus)
    }
}

export function errorCreateInvitationStatus(error) {
    return {
        type: ERROR_CREATE_INVITATIONSTATUS,
        error: error,
    }
}

export function resetCreateInvitationStatus() {
    return {
        type: RESET_CREATE_INVITATIONSTATUS
    }
}

export function createInvitationStatus(invitationStatus) {
    return {
        type: CREATE_INVITATIONSTATUS,
        invitationStatus
    }
}

export function deleteCreateInvitationStatus(invitationStatus) {
    return {
        type: DELETE_CREATE_INVITATIONSTATUS,
        invitationStatus
    }
}

export function saveCreateInvitationStatus() {
    return (dispatch, getState) => {
        dispatch(requestCreateInvitationStatus());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let invitationStatus = denormalizeDato(getState().invitationsStatus.create.nuevo, store);

        return invitationsStatusApi.saveCreate(invitationStatus)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateInvitationStatus(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateInvitationStatus(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                try{
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateInvitationsStatus(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateInvitationsStatus(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateInvitationStatus(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateInvitationStatus(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateInvitationStatus(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                                    }catch {
                                        dispatch(errorCreateInvitationStatus(errorMessages.GENERAL_ERROR));
                                    };
                            }
                        });
    }
}

//CREATE INVITATIONSSTATUS
export const REQUEST_CREATE_INVITATIONSSTATUS = "REQUEST_CREATE_INVITATIONSSTATUS";
export const SUCCESS_CREATE_INVITATIONSSTATUS = "SUCCESS_CREATE_INVITATIONSSTATUS";
export const ERROR_CREATE_INVITATIONSSTATUS = "ERROR_CREATE_INVITATIONSSTATUS";
export const RESET_CREATE_INVITATIONSSTATUS = "RESET_CREATE_INVITATIONSSTATUS";

function requestCreateInvitationsStatus() {
    return {
        type: REQUEST_CREATE_INVITATIONSSTATUS,
}
}

function receiveCreateInvitationsStatus(invitationsStatus) {
    return {
        type: SUCCESS_CREATE_INVITATIONSSTATUS,
    receivedAt: Date.now(),
        invitationsStatus: normalizeDatos(invitationsStatus)
}
}

function errorCreateInvitationsStatus(error) {
    return {
        type: ERROR_CREATE_INVITATIONSSTATUS,
    error: error,
}
}

export function resetCreateInvitationsStatus() {
    return {
        type: RESET_CREATE_INVITATIONSSTATUS,
}
}

export function saveCreateInvitationsStatus() {
    return (dispatch, getState) => {
        dispatch(requestCreateInvitationsStatus());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let invitationsStatus = getState().invitationsStatus.create.nuevos.map((idInvitationStatus) => {
            return denormalizeDato(getState().invitationsStatus.create.nuevo[idInvitationStatus], store);
        });

        return invitationsStatusApi.saveCreateInvitationsStatus(invitationsStatus)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateInvitationsStatus(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateInvitationsStatus(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateInvitationsStatus(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateInvitationsStatus(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateInvitationsStatus(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateInvitationsStatus(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE INVITATIONSTATUS
export const DELETE_INVITATIONSTATUS = 'DELETE_INVITATIONSTATUS';
export const REQUEST_DELETE_INVITATIONSTATUS = "REQUEST_DELETE_INVITATIONSTATUS";
export const SUCCESS_DELETE_INVITATIONSTATUS = "SUCCESS_DELETE_INVITATIONSTATUS";
export const ERROR_DELETE_INVITATIONSTATUS = "ERROR_DELETE_INVITATIONSTATUS";
export const RESET_DELETE_INVITATIONSTATUS = "RESET_DELETE_INVITATIONSTATUS";

function requestDeleteInvitationStatus() {
    return {
        type: REQUEST_DELETE_INVITATIONSTATUS,
    }
}

function receiveDeleteInvitationStatus(invitationStatus) {
    return {
        type: SUCCESS_DELETE_INVITATIONSTATUS,
        receivedAt: Date.now(),
        invitationStatus: normalizeDato(invitationStatus)
    }
}

function errorDeleteInvitationStatus(error) {
    return {
        type: ERROR_DELETE_INVITATIONSTATUS,
        error: error,
    }
}

export function resetDeleteInvitationStatus(error) {
    return {
        type: RESET_DELETE_INVITATIONSTATUS,
        error: error,
    }
}

export function deleteInvitationStatus(invitationStatus) {
    return {
        type: DELETE_INVITATIONSTATUS,
        invitationStatus
    }
}

export function saveDeleteInvitationStatus(invitationStatus) {
    return dispatch => {
        dispatch(requestDeleteInvitationStatus());
        return invitationsStatusApi.saveDelete(invitationStatus)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteInvitationStatus());
                            dispatch(receiveDeleteInvitationStatus(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteInvitationStatus(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteInvitationStatus(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteInvitationStatus(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteInvitationStatus(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteInvitationStatus(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT INVITATIONSTATUS
export const REQUEST_PRINT_INVITATIONSTATUS = "REQUEST_PRINT_INVITATIONSTATUS";
export const SUCCESS_PRINT_INVITATIONSTATUS = "SUCCESS_PRINT_INVITATIONSTATUS";
export const ERROR_PRINT_INVITATIONSTATUS = "ERROR_PRINT_INVITATIONSTATUS";
export const RESET_PRINT_INVITATIONSTATUS = "RESET_PRINT_INVITATIONSTATUS";

function requestPrintInvitationStatus() {
    return {
        type: REQUEST_PRINT_INVITATIONSTATUS,
    }
}

function receivePrintInvitationStatus(turnos) {
    return {
        type: SUCCESS_PRINT_INVITATIONSTATUS,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintInvitationStatus(error) {
    return {
        type: ERROR_PRINT_INVITATIONSTATUS,
        error: error,
    }
}

export function resetPrintInvitationStatus() {
    return {
        type: RESET_PRINT_INVITATIONSTATUS,
    }
}

export function printInvitationStatus(idInvitationStatus) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintInvitationStatus());
        return invitationsStatusApi.printInvitationStatus(idInvitationStatus)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintInvitationStatus(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintInvitationStatus(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintInvitationStatus(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintInvitationStatus(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintInvitationStatus(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}