import {resetUsers, resetCreateUser, resetUpdateUser, resetDeleteUser} from "../actions/UserActions";
import {resetInvitations, resetCreateInvitation, resetUpdateInvitation, resetDeleteInvitation} from "../actions/InvitationActions";import {resetEvents, resetCreateEvent, resetUpdateEvent, resetDeleteEvent} from "../actions/EventActions";import {resetCards, resetCreateCard, resetUpdateCard, resetDeleteCard} from "../actions/CardActions";import {resetFacePhotos, resetCreateFacePhoto, resetUpdateFacePhoto, resetDeleteFacePhoto} from "../actions/FacePhotoActions";

var resetUser = {
    resetAll(dispatch) {
        dispatch(resetUsers());
        dispatch(resetCreateUser());
        dispatch(resetUpdateUser());
        dispatch(resetDeleteUser());
       dispatch(resetInvitations());
dispatch(resetCreateInvitation());
dispatch(resetUpdateInvitation());
dispatch(resetDeleteInvitation());dispatch(resetEvents());
dispatch(resetCreateEvent());
dispatch(resetUpdateEvent());
dispatch(resetDeleteEvent());dispatch(resetCards());
dispatch(resetCreateCard());
dispatch(resetUpdateCard());
dispatch(resetDeleteCard());dispatch(resetFacePhotos());
dispatch(resetCreateFacePhoto());
dispatch(resetUpdateFacePhoto());
dispatch(resetDeleteFacePhoto());
    },
};

export default resetUser;