//api
import devicesApi from "../api/devicesApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeDevices";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//DEVICES
export const REQUEST_DEVICES = 'REQUEST_DEVICES';
export const RECEIVE_DEVICES = 'RECEIVE_DEVICES';
export const INVALIDATE_DEVICES = 'INVALIDATE_DEVICES';
export const ERROR_DEVICES = "ERROR_DEVICES";
export const RESET_DEVICES = "RESET_DEVICES";


export function invalidateDevices() {
    return {
        type: INVALIDATE_DEVICES
    }
}

function requestDevices() {
    return {
        type: REQUEST_DEVICES,
    }
}

function receiveDevices(json) {
    return {
        type: RECEIVE_DEVICES,
    devices: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorDevices(error) {
    return {
        type: ERROR_DEVICES,
        error: error,
    }
}

export function resetDevices() {
    return {
        type: RESET_DEVICES
    }
}

export function fetchDevices(filtros) {
    return dispatch => {
        dispatch(requestDevices());
        return devicesApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveDevices(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorDevices(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorDevices(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchDevices(state) {
    const devices = state.devices.byId;
    if (!devices) {
        return true
    } else if (devices.isFetching) {
        return false
    } else {
        return devices.didInvalidate;
    }
}

export function fetchDevicesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchDevices(getState())) {
            return dispatch(fetchDevices(filtros))
        }
    }
}


//MODEL
export const REQUEST_DEVICE = 'REQUEST_DEVICE';
export const RECEIVE_DEVICE = 'RECEIVE_DEVICE';
export const INVALIDATE_DEVICE = 'INVALIDATE_DEVICE';
export const ERROR_DEVICE = "ERROR_DEVICE";
export const RESET_DEVICE = "RESET_DEVICE";


export function invalidateDevice() {
    return {
        type: INVALIDATE_DEVICE
    }
}

function requestDevice() {
    return {
        type: REQUEST_DEVICE,
    }
}

export function receiveDevice(json) {
    return {
        type: RECEIVE_DEVICE,
    device: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorDevice(error) {
    return {
        type: ERROR_DEVICE,
        error: error,
    }
}

export function fetchDevice(idDevice) {
    return dispatch => {
        dispatch(requestDevice());
        return devicesApi.getOne(idDevice)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveDevice(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorDevice(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorDevice(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_DEVICE = 'RECEIVE_FILE_DEVICE';

function receiveFileDevice(file) {
    return {
        type: RECEIVE_FILE_DEVICE,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileDevice(idDevice, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return devicesApi.getFile(idDevice, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileDevice(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorDevice(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorDevice(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//UPDATE MODEL
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const REQUEST_UPDATE_DEVICE = "REQUEST_UPDATE_DEVICE";
export const SUCCESS_UPDATE_DEVICE = "SUCCESS_UPDATE_DEVICE";
export const ERROR_UPDATE_DEVICE = "ERROR_UPDATE_DEVICE";
export const RESET_UPDATE_DEVICE = "RESET_UPDATE_DEVICE";
export const DELETE_UPDATE_DEVICE = "DELETE_UPDATE_DEVICE";

function requestUpdateDevice() {
    return {
        type: REQUEST_UPDATE_DEVICE,
    }
}

function receiveUpdateDevice(device) {
    return {
        type: SUCCESS_UPDATE_DEVICE,
        receivedAt: Date.now(),
        device: normalizeDato(device)
    }
}

function errorUpdateDevice(error) {
    return {
        type: ERROR_UPDATE_DEVICE,
        error: error,
    }
}

export function resetUpdateDevice() {
    return {
        type: RESET_UPDATE_DEVICE,
    }
}

export function updateDevice(device) {
    return {
        type: UPDATE_DEVICE,
        device
    }
}

export function saveUpdateDevice() {
    return (dispatch, getState) => {
        dispatch(requestUpdateDevice());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let device = denormalizeDato(getState().devices.update.activo, store);

        return devicesApi.saveUpdate(device)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateDevice(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateDevice(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                try{
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateDevices(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateDevices(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateDevice(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateDevice(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateDevice(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                                    } catch{
                                            dispatch(errorUpdateDevice(errorMessages.GENERAL_ERROR));
                                        };
                            }
                        });
    }
}

export function deleteUpdateDevice(device) {
    return {
        type: DELETE_UPDATE_DEVICE,
        device
    }
}

//UPDATE DEVICES
export const REQUEST_UPDATE_DEVICES = "REQUEST_UPDATE_DEVICES";
export const SUCCESS_UPDATE_DEVICES = "SUCCESS_UPDATE_DEVICES";
export const ERROR_UPDATE_DEVICES = "ERROR_UPDATE_DEVICES";
export const RESET_UPDATE_DEVICES = "RESET_UPDATE_DEVICES";

function requestUpdateDevices() {
    return {
        type: REQUEST_UPDATE_DEVICES,
}
}

function receiveUpdateDevices(devices) {
    return {
        type: SUCCESS_UPDATE_DEVICES,
    receivedAt: Date.now(),
        devices: normalizeDatos(devices)
}
}

function errorUpdateDevices(error) {
    return {
        type: ERROR_UPDATE_DEVICES,
    error: error,
}
}

export function resetUpdateDevices() {
    return {
        type: RESET_UPDATE_DEVICES,
}
}

export function saveUpdateDevices() {
    return (dispatch, getState) => {
        dispatch(requestUpdateDevices());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let devices = getState().devices.update.activos.map((idDevice) => {
            return denormalizeDato(getState().devices.update.activo[idDevice], store);
        });

        return devicesApi.saveUpdateDevices(devices)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateDevices(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateDevices(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateDevices(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateDevices(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateDevices(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateDevices(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA DEVICE
export const CREATE_DEVICE = 'CREATE_DEVICE';
export const REQUEST_CREATE_DEVICE = "REQUEST_CREATE_DEVICE";
export const SUCCESS_CREATE_DEVICE = "SUCCESS_CREATE_DEVICE";
export const ERROR_CREATE_DEVICE = "ERROR_CREATE_DEVICE";
export const RESET_CREATE_DEVICE = "RESET_CREATE_DEVICE";
export const DELETE_CREATE_DEVICE = "DELETE_CREATE_DEVICE";


//ALTA DEVICE
function requestCreateDevice() {
    return {
        type: REQUEST_CREATE_DEVICE,
    }
}

function receiveCreateDevice(device) {
    return {
        type: SUCCESS_CREATE_DEVICE,
        receivedAt: Date.now(),
        device: normalizeDato(device)
    }
}

export function errorCreateDevice(error) {
    return {
        type: ERROR_CREATE_DEVICE,
        error: error,
    }
}

export function resetCreateDevice() {
    return {
        type: RESET_CREATE_DEVICE
    }
}

export function createDevice(device) {
    return {
        type: CREATE_DEVICE,
        device
    }
}

export function deleteCreateDevice(device) {
    return {
        type: DELETE_CREATE_DEVICE,
        device
    }
}

export function saveCreateDevice() {
    return (dispatch, getState) => {
        dispatch(requestCreateDevice());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let device = denormalizeDato(getState().devices.create.nuevo, store);

        return devicesApi.saveCreate(device)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateDevice(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateDevice(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                try{
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateDevices(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateDevices(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateDevice(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateDevice(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateDevice(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                                    }catch {
                                        dispatch(errorCreateDevice(errorMessages.GENERAL_ERROR));
                                    };
                            }
                        });
    }
}

//CREATE DEVICES
export const REQUEST_CREATE_DEVICES = "REQUEST_CREATE_DEVICES";
export const SUCCESS_CREATE_DEVICES = "SUCCESS_CREATE_DEVICES";
export const ERROR_CREATE_DEVICES = "ERROR_CREATE_DEVICES";
export const RESET_CREATE_DEVICES = "RESET_CREATE_DEVICES";

function requestCreateDevices() {
    return {
        type: REQUEST_CREATE_DEVICES,
}
}

function receiveCreateDevices(devices) {
    return {
        type: SUCCESS_CREATE_DEVICES,
    receivedAt: Date.now(),
        devices: normalizeDatos(devices)
}
}

function errorCreateDevices(error) {
    return {
        type: ERROR_CREATE_DEVICES,
    error: error,
}
}

export function resetCreateDevices() {
    return {
        type: RESET_CREATE_DEVICES,
}
}

export function saveCreateDevices() {
    return (dispatch, getState) => {
        dispatch(requestCreateDevices());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let devices = getState().devices.create.nuevos.map((idDevice) => {
            return denormalizeDato(getState().devices.create.nuevo[idDevice], store);
        });

        return devicesApi.saveCreateDevices(devices)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateDevices(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateDevices(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateDevices(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateDevices(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateDevices(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateDevices(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE DEVICE
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const REQUEST_DELETE_DEVICE = "REQUEST_DELETE_DEVICE";
export const SUCCESS_DELETE_DEVICE = "SUCCESS_DELETE_DEVICE";
export const ERROR_DELETE_DEVICE = "ERROR_DELETE_DEVICE";
export const RESET_DELETE_DEVICE = "RESET_DELETE_DEVICE";

function requestDeleteDevice() {
    return {
        type: REQUEST_DELETE_DEVICE,
    }
}

function receiveDeleteDevice(device) {
    return {
        type: SUCCESS_DELETE_DEVICE,
        receivedAt: Date.now(),
        device: normalizeDato(device)
    }
}

function errorDeleteDevice(error) {
    return {
        type: ERROR_DELETE_DEVICE,
        error: error,
    }
}

export function resetDeleteDevice(error) {
    return {
        type: RESET_DELETE_DEVICE,
        error: error,
    }
}

export function deleteDevice(device) {
    return {
        type: DELETE_DEVICE,
        device
    }
}

export function saveDeleteDevice(device) {
    return dispatch => {
        dispatch(requestDeleteDevice());
        return devicesApi.saveDelete(device)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteDevice());
                            dispatch(receiveDeleteDevice(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteDevice(errorMessages.UNAUTHORIZED_TOKEN));
                                     dispatch(logout());
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteDevice(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteDevice(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteDevice(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteDevice(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT DEVICE
export const REQUEST_PRINT_DEVICE = "REQUEST_PRINT_DEVICE";
export const SUCCESS_PRINT_DEVICE = "SUCCESS_PRINT_DEVICE";
export const ERROR_PRINT_DEVICE = "ERROR_PRINT_DEVICE";
export const RESET_PRINT_DEVICE = "RESET_PRINT_DEVICE";

function requestPrintDevice() {
    return {
        type: REQUEST_PRINT_DEVICE,
    }
}

function receivePrintDevice(turnos) {
    return {
        type: SUCCESS_PRINT_DEVICE,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintDevice(error) {
    return {
        type: ERROR_PRINT_DEVICE,
        error: error,
    }
}

export function resetPrintDevice() {
    return {
        type: RESET_PRINT_DEVICE,
    }
}

export function printDevice(idDevice) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintDevice());
        return devicesApi.printDevice(idDevice)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintDevice(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintDevice(errorMessages.UNAUTHORIZED_TOKEN));
                         dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintDevice(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintDevice(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintDevice(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}