import {resetEvents, resetCreateEvent, resetUpdateEvent, resetDeleteEvent} from "../actions/EventActions";


var resetEvent = {
    resetAll(dispatch) {
        dispatch(resetEvents());
        dispatch(resetCreateEvent());
        dispatch(resetUpdateEvent());
        dispatch(resetDeleteEvent());
       
    },
};

export default resetEvent;