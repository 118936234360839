import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
//Components
import {
    Breadcrumbs,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    ListItem,
    ListItemIcon,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {useDispatch, useSelector} from "react-redux";
import eventReset from "../../reset/eventReset";
import {fetchEvents, invalidateEvents, resetEvents} from "../../actions/EventActions";
import {Skeleton} from "@material-ui/lab";
import CustomPaper from "../../library/CustomPaper/CustomPaper";
import PaperHeader from "../../library/CustomPaper/PaperHeader";
import CropFreeIcon from "@material-ui/icons/Search";
import Grow from "../../library/Grow";
import PaperFooter from "../../library/CustomPaper/PaperFooter";
import Pagination from "@material-ui/lab/Pagination";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import List from "@material-ui/core/List";
import PersonIcon from '@material-ui/icons/Person';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import moment from "moment";
import c from "../../constants/Constants"
import {useHistory} from "react-router";
import Avatar from "@material-ui/core/Avatar";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
//Icons
//Img

const useStyles = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(2, 0)
    },
    root: {
        display: 'flex',
        justifyContent: "space-between"
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
        backgroundSize: "contain"
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    img: {
        width: "100%",
        height: "auto",
        borderRadius: "20px",
        cursor: "pointer"
    }
}));


function EventsList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [open, setOpen] = React.useState(false);
    const [idEvent, setIdEvent] = React.useState(null);

    //Store
    const events = useSelector(state => state.events);
    const users = useSelector(state => state.users);
    let event = idEvent ? events.byId.events[idEvent] : null;
    let user = event && event.idUser ? users.byId.users[event.idUser] : null;

    //State
    const [search, setSearch] = useState("");


    const params = new URLSearchParams(props.location.search);
    const order = params.get("order") ? params.get("order") : "a.id";
    const direction = params.get("direction") ? params.get("direction") : "desc";
    const page = params.get("page") ? params.get("page") : 1;
    const searchPhase = params.get("sp") ? params.get("sp") : "";
    const type = params.get("type") ? params.get("type") : "";
    const idCategorySelected = params.get("idCategory") ? params.get("idCategory") : "";

    const disabled = events.byId.isFetching || events.delete.isDeleting;

    //Hooks
    const timer = useRef();
    const mounted = useRef();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    //Effects
    useEffect(() => {
        window.scrollTo(0, 0);

        if (searchPhase != search) {
            setSearch(searchPhase);
        }

        return function cleanup() {
            eventReset.resetAll(dispatch);
        }
    }, []);

    useEffect(() => {
        let filtros = {
            searchPhase: search ? search : "",
            order: order,
            direction: direction,
            page: page,
            idCategory: idCategorySelected,
            with: ["user"],
            type: type
        };

        dispatch(resetEvents());
        dispatch(invalidateEvents());
        dispatch(fetchEvents(filtros));
    }, [props.location.search]);

    const changeOrder = (or) => {
        params.set("order", or ? or : "");
        let direction = params.get("direction");
        params.set("direction", direction === "asc" ? "desc" : "asc");
        props.history.push("/events?" + params.toString());
    };
    const WAIT_INTERVAL = 500;

    const changeSearch = (e) => {

        clearTimeout(timer.current);
        let valor = e.target.value;
        setSearch(e.target.value);

        timer.current = setTimeout(() => triggerChange(valor), WAIT_INTERVAL);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            clearTimeout(timer.current);
            triggerChange();
        }
    };

    const triggerChange = (searchPhase) => {
        if ((!searchPhase && !search) || (searchPhase === "")) {
            params.delete("sp");
            props.history.push("/events?" + params.toString());
        } else {
            params.delete("page");
            params.set("sp", searchPhase ? searchPhase : search);
            props.history.push("/events?" + params.toString());
        }
    };

    const handleChangePage = (e, page) => {
        let pagina = page;
        let paramsPagina = new URLSearchParams(props.location.search);
        paramsPagina.set("page", pagina ? pagina : "1");
        props.history.push("/events?" + paramsPagina.toString());
    };

    const handleOpenData = (idEvent) => {
        setIdEvent(idEvent);
        setOpen(true);
    }

    const previewFile = (url) => {
        // let fileSubido = file;
        // let reader = new FileReader();
        // reader.onloadend = function () {
        let pdfWindow = window.open("")
        pdfWindow.document.write("<iframe width='100%' height='100%' src='" + c.API_HOST + url + "'></iframe>")
        // };
        // if (fileSubido) {
        //     reader.readAsDataURL(fileSubido);
        // }
    };

    let Events = [];

    if (events.byId.isFetching) {
        Events = [...Array(5).keys()].map((i) => {
            return (<TableRow key={"inv" + i}>
                {[...Array(6).keys()].map((i) =>
                    <TableCell><Skeleton variant="text"/></TableCell>
                )}
            </TableRow>);
        });
    }

    if (!events.byId.isFetching)
        Events = events.allIds.map(idEvent => {
            let event = events.byId.events[idEvent];
            let user = event && event.idUser ? users.byId.users[event.idUser] : null;
            return (
                <TableRow key={event.id}>
                    <TableCell>{event ? event.id : ""}</TableCell>
                    <TableCell>
                        <Avatar aria-label="recipe" className={classes.avatar}
                                src={event ? c.API_HOST + event.pathThumbnail : ""}>
                            {user && user.lastname ? user.lastname.charAt(0) :
                                <AddIcon/>}
                        </Avatar>
                    </TableCell>
                    <TableCell>{user ? user.name + " " + user.lastname : ""}</TableCell>
                    <TableCell>{event ? event.desEvent : ""}</TableCell>
                    <TableCell>{event ? moment(event.date, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY HH:mm") : ""}</TableCell>
                    <TableCell>
                        <Button variant="outlined" color="primary" onClick={() => handleOpenData(idEvent)}>
                            Ver
                        </Button>
                    </TableCell>
                </TableRow>
            )
        });

    if (Events.length === 0)
        Events.push(
            <TableRow key={0}>
                <TableCell align="center" colSpan={11}>No se encontraron registros</TableCell>
            </TableRow>)


    const totalPaginas = Math.ceil(events.totalEvents ? events.totalEvents / 50 : 0);

    return (
        <Container maxWidth={"lg"}>
            <Breadcrumbs aria-label="breadcrumb" separator={"‣"}>
                <Link color="inherit" to="/">
                    Home
                </Link>
            </Breadcrumbs>
            <Typography component={"h1"} variant={"h1"}>
                Eventos
            </Typography>
            <CustomPaper>
                <PaperHeader>
                    <TextField
                        id="search"
                        placeholder="Buscar ..."
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton>
                                        <CropFreeIcon/>
                                    </IconButton>
                                </InputAdornment>,
                            // disabled: disabled
                        }}
                        value={search ? search : ""}
                        onChange={(e) => changeSearch(e)}
                        onKeyDown={(e) => handleKeyDown(e)}
                    />
                    <Grow/>
                </PaperHeader>
                <TableContainer>
                    <Table aria-label="Tabla de contenido">
                        <TableHead>
                            <TableRow>
                                <TableCell width={"10px"}>
                                    <TableSortLabel
                                        active={order === "a.id"}
                                        direction={direction}
                                        onClick={() => changeOrder("a.id")}
                                    >
                                        <b>ID</b>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell width={"50px"}>
                                    <b>Foto</b>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={order === "idUserLastMdf"}
                                        direction={direction}
                                        onClick={() => changeOrder("idUserLastMdf")}
                                    >
                                        <b>Usuario</b>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={order === "genericName"}
                                        direction={direction}
                                        onClick={() => changeOrder("genericName")}
                                    >
                                        <b>Descripción</b>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={order === "created_at"}
                                        direction={direction}
                                        onClick={() => changeOrder("created_at")}
                                    >
                                        <b>Fecha</b>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <b>Datos</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Events
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaperFooter style={{justifyContent: "center"}}>
                    {
                        !!totalPaginas && totalPaginas > 1 &&
                        <Pagination count={totalPaginas} page={page ? parseInt(page) : 1} color="primary"
                                    onChange={handleChangePage} size={"small"}/>
                    }
                </PaperFooter>
            </CustomPaper>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">{event ? event.desEvent : ""}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Los datos del evento
                    </DialogContentText>
                    <Grid container>
                        <Grid item md={6} xs={12}>
                            <List component="nav" aria-label="main mailbox folders">
                                <ListItem>
                                    <ListItemIcon>
                                        <PersonIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={user ? user.name + " " + user.lastname : ""}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <InsertInvitationIcon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={event ? moment(event.date, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY HH:mm") : ""}/>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <img className={classes.img} onClick={() => previewFile(event.path)}
                                 src={event ? c.API_HOST + event.path : ""}/>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>

        </Container>)
}

EventsList.propTypes = {}


export default EventsList;