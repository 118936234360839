import {normalize, schema, denormalize} from 'normalizr';

const notification = new schema.Entity('notifications', {}, {idAttribute: "id"});

export function normalizeDatos(myData) {

    const mySchema = {notifications: [notification]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = notification;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = notification;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default notification;
