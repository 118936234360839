import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
//Components
import {Typography} from "@material-ui/core";
import CustomTextField from "../../library/CustomTextField";
import Navigation from "../../library/Navigation";
import style from "../../assets/css/Forms.module.css";

// Icons
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailIcon from '@material-ui/icons/Email';
import {createUser, updateUser} from "../../actions/UserActions";
import {useValidador} from "../../utils/validador";

export default function PersonalData(props) {
    const dispatch = useDispatch();
    let {idInvitation} = useParams();
    let {goToStep, prevStep} = props;

    //store
    const invitations = useSelector(state => state.invitations);
    const users = useSelector(state => state.users);
    const invitation = idInvitation ? invitations.update.activo : invitations.create.nuevo;
    const idUser = invitation.idUser;
    let user = invitation && invitation.idUser && users.byId.users[invitation.idUser] ? users.byId.users[invitation.idUser] : {};
    if (users.update.activos.indexOf(invitation.idUser) !== -1) {
        user = users.update.activo[invitation.idUser];
    }
    if (users.create.nuevos.indexOf(invitation.idUser) !== -1) {
        user = users.create.nuevo[invitation.idUser];
    }

    //Hooks
    let reglas = {};
    reglas = {
        name: ["required"],
        lastname: ["required"],
        mobilePhone: ["required"],
        email: ["required"],

    };
    const [validador, validar] = useValidador(reglas);

    //Effects
    useEffect(() => {
        return function cleanup() {
        }

    }, []);

    const handleChangeUser = (e) => {
        let cambio = {};
        cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
        changeUser(cambio);
    };

    const changeUser = (cambio) => {
        cambio.id = idUser;
        let cambioUser = {};
        cambioUser[idUser] = cambio;
        if (idUser !== -1)
            dispatch(updateUser(cambioUser));
        else
            dispatch(createUser(cambioUser));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (validador.isValid())
            goToStep("fechaHora");
    }

    return (
        <form onSubmit={onSubmit} className={style.containerForm}>
            <Typography variant={"h1"} component={"h1"} color={"primary"}>
                Datos personales
            </Typography>
            <CustomTextField
                id="name"
                label="Nombre *"
                variant="outlined"
                fullWidth
                InputLabelProps={{shrink: true}}
                InputProps={{
                    startAdornment:
                        <PersonIcon color={"primary"}/>
                }}
                value={user ? user.name : ""}
                onChange={handleChangeUser}
                onBlur={validar}
                error={!validador.isValidById("name")}
                helperText={validador.getHelperTextById("name")}
            />
            <CustomTextField
                id="lastname"
                label="Apellido *"
                variant="outlined"
                fullWidth
                InputLabelProps={{shrink: true}}
                InputProps={{
                    startAdornment:
                        <GroupIcon color={"primary"}/>
                }}
                value={user ? user.lastname : ""}
                onChange={handleChangeUser}
                onBlur={validar}
                error={!validador.isValidById("lastname")}
                helperText={validador.getHelperTextById("lastname")}
            />
            <CustomTextField
                id="mobilePhone"
                label="Celular *"
                variant="outlined"
                fullWidth
                InputLabelProps={{shrink: true}}
                InputProps={{
                    startAdornment:
                        <PhoneIphoneIcon color={"primary"}/>
                }}
                value={user ? user.mobilePhone : ""}
                onChange={handleChangeUser}
                onBlur={validar}
                error={!validador.isValidById("mobilePhone")}
                helperText={validador.getHelperTextById("mobilePhone")}
            />
            <CustomTextField
                id="email"
                label="Email *"
                variant="outlined"
                fullWidth
                InputLabelProps={{shrink: true}}
                InputProps={{
                    startAdornment:
                        <EmailIcon color={"primary"}/>
                }}
                value={user ? user.email : ""}
                onChange={handleChangeUser}
                onBlur={validar}
                error={!validador.isValidById("email")}
                helperText={validador.getHelperTextById("email")}
            />
            <CustomTextField
                id="enterprise"
                label="Empresa"
                variant="outlined"
                fullWidth
                InputLabelProps={{shrink: true}}
                InputProps={{
                    startAdornment:
                        <EmailIcon color={"primary"}/>
                }}
                value={user ? user.enterprise : ""}
                onChange={handleChangeUser}
                onBlur={validar}
                error={!validador.isValidById("enterprise")}
                helperText={validador.getHelperTextById("enterprise")}
            />
            <Navigation next={"fechaHora"} goToStep={goToStep} prevStep={prevStep}/>
        </form>
    );
}

