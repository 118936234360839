import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {useDispatch} from "react-redux";
import EventsList from "./EventsList";
//Components

export default function Logs() {

    const dispatch = useDispatch();
    //Effects
    useEffect(() => {
        return function cleanup() {
        }

    }, []);

    return (
        <Switch>
            <Route path="/events" component={EventsList}/>
        </Switch>

    );
}

